import { AreaDetails, AreaNew } from '../models/dto/Area'
import ApiBase from './ApiBase';

export default class AreaApi extends ApiBase {
    private static instance: AreaApi;

    private constructor() {
        super()
    }
  
    static getInstance(): AreaApi {
      if (!AreaApi.instance) {
        AreaApi.instance = new AreaApi()
      }
  
      return AreaApi.instance
    }

    public async fetch(organizationId: string, installationId: string): Promise<AreaDetails[]> {
        return await this.getItems(`/api/customer/${organizationId}/installations/${installationId}/areas`, AreaDetails)
    }

    public async get(organizationId: string, installationId: string, areaId: string): Promise<AreaDetails> {
        return await this.getItem(`/api/customer/${organizationId}/installations/${installationId}/areas/${areaId}`, AreaDetails)
    }

    public async create(organizationId: string, installationId: string, area: AreaDetails): Promise<AreaDetails> {
        let item = await this.postItem(`/api/customer/${organizationId}/installations/${installationId}/areas`, area, AreaNew, AreaDetails)
        return item!
    }

    public async edit(organizationId: string, installationId: string, area: AreaDetails): Promise<AreaDetails> {
        let item = await this.putItem(`/api/customer/${organizationId}/installations/${installationId}/areas/${area.id}`, area, AreaNew, AreaDetails)
        return item!
    }

    public async delete(organizationId: string, installationId: string, areaId: string): Promise<void> {
        await this.deleteItem(`/api/customer/${organizationId}/installations/${installationId}/areas/${areaId}`)
    }
}