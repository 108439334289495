import React, { Component, Fragment, RefObject } from "react"
import { RouteComponentProps } from "react-router-dom"
import InstallationApi from "../../apis/InstallationApi"
import { Installation } from '../../models/dto/Installation'
import ObjectList from '../objects/ObjectList'
import { ObjectAlertSettingValueTypes, ObjectSettingValueTypes, ObjectTypeDetails } from '../../models/dto/ObjectType'
import ObjectTypeApi from "../../apis/ObjectTypeApi"
import { FloorDetails } from '../../models/dto/Floor'
import FloorApi from "../../apis/FloorApi"
import { FaPlus, FaEdit } from "react-icons/fa"
import { ObjectAlertSetting, ObjectDetails, ObjectSetting } from "../../models/dto/Object"
import ObjectSettingEditor from "../objects/ObjectSettingEditor"
import ObjectApi from "../../apis/ObjectApi"
import Loader from "../common/Loader"
import { Breadcrumb, Button, Modal, Form, Row, Card, Alert, Col } from "react-bootstrap"
import { ResponsiveHeatMap, HeatMapDatum, NodeData } from '@nivo/heatmap'
import ObjectAlertSettingEditor from "../objects/ObjectAlertSettingEditor"

interface InstallationAdminState {
    installation?: Installation
    error?: String
    activeTab: string
    objectTypes: ObjectTypeDetails[]
    objects: ObjectDetails[]
    floors: FloorDetails[]
    showEditModal: boolean
    editingObject?: ObjectDetails
    editingLoading: boolean
    editingError?: string
    workingScheduler?: HeatMapDatum[]
}

interface InstallationAdminMachParams {
    organization: string
    installation: string
}

export default class InstallationAdmin extends Component<RouteComponentProps<InstallationAdminMachParams> | any, InstallationAdminState> {

    objectListRef: RefObject<ObjectList> = React.createRef()
    timezoneRef: RefObject<HTMLInputElement> = React.createRef()
    alertsEnabledRef: RefObject<HTMLInputElement> = React.createRef()

    constructor(props: RouteComponentProps<InstallationAdminMachParams> | any) {
        super(props)
        this.state = {
            activeTab: 'objects',
            objectTypes: [],
            floors: [],
            objects: [],
            showEditModal: false,
            editingLoading: false
        }
    }

    async componentDidMount() {
        document.title = 'AuditKit - Installation (Administration)...'
        try {
            var installation = await InstallationApi.getInstance().get(this.props.match.params.organization, this.props.match.params.installation)
            document.title = `AuditKit - Installation (Administration) du ${installation.willStartDate.toLocaleDateString()} au ${installation.willEndDate.toLocaleDateString()}`
            var objectTypes = await ObjectTypeApi.getInstance().fetch()
            var floors = await FloorApi.getInstance().fetch(this.props.match.params.organization, this.props.match.params.installation)
            var objects = await ObjectApi.getInstance().fetch(this.props.match.params.organization, this.props.match.params.installation)
            this.setState({
                installation: installation,
                objectTypes: objectTypes,
                floors: floors,
                objects: objects,
                workingScheduler: ((installation.workingScheduler !== null && installation.workingScheduler!.length > 0) ? installation.workingScheduler! : new Array(7).fill(new Array(24).fill(false))).map<HeatMapDatum>((_: boolean[], index: number) => {
                    var item: HeatMapDatum = {
                        day: index.toString()
                    }

                    _.forEach((value, idx) => {
                        item[idx] = value ? 1 : 0
                    })

                    return item
                })
            })
        } catch (error) {
            this.setState({
                error: error.message,
            })
        }
    }

    private getDay(index: number): string {
        var day: string = 'Lundi'
        switch (index) {
            case 0:
                day = 'Lundi'
                break;
            case 1:
                day = 'Mardi'
                break;
            case 2:
                day = "Mercredi"
                break;
            case 3:
                day = "Jeudi"
                break;
            case 4:
                day = "Vendredi"
                break;
            case 5:
                day = "Samedi"
                break;
            case 6:
                day = "Dimanche"
                break;
            default:
                break;
        }
        return day
    }

    render() {
        return (
            <>
                <div className='d-flex ml-n2'>
                    <Breadcrumb className='flex-grow-1'>
                        <Breadcrumb.Item href={`./${this.props.match.params.organization}/installations`}>Installations</Breadcrumb.Item>
                        <Breadcrumb.Item href={`./${this.props.match.params.organization}/installations/${this.props.match.params.installation}`}>{this.state.installation ? `du ${this.state.installation.willStartDate.toLocaleDateString()} au ${this.state.installation.willEndDate.toLocaleDateString()}` : '...'}</Breadcrumb.Item>
                        <Breadcrumb.Item active>Administration</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                { this.state.error && <div>{this.state.error}</div>}
                { (!this.state.error && this.state.installation) && this.renderContent()}
            </>
        )
    }

    private async edit(object: ObjectDetails | undefined) {
        let o = object ? object.copy() : new ObjectDetails()
        this.changeObjectType(o, o.objectTypeId === '' ? this.state.objectTypes[0].id : o.objectTypeId)
        this.setState({
            ...this.state,
            editingObject: o,
            showEditModal: true,
            editingError: undefined,
            editingLoading: false
        })
    }

    private changeObjectType(object: ObjectDetails, objectTypeId: string) {
        let objectType = this.state.objectTypes.find(_ => _.id === objectTypeId)!

        if (!objectType) {
            return
        }

        if (object.objectTypeId !== objectTypeId) {
            object.settings = []
            object.alertSettings = []
            object.objectTypeId = objectTypeId
        }

        objectType.settings.forEach(element => {
            var setting = object.settings.find(_ => _.objectTypeSettingKey === element.key)
            if (!setting) {
                setting = new ObjectSetting()
                setting.objectTypeSettingKey = element.key
                if (element.valueType === ObjectSettingValueTypes.boolean) {
                    setting.value = false
                }
                object.settings.push(setting)
            }
        });

        objectType.alertSettings.forEach(element => {
            var setting = object.alertSettings.find(_ => _.objectTypeSettingKey === element.key)
            if (!setting) {
                setting = new ObjectAlertSetting()
                setting.objectTypeSettingKey = element.key
                if (element.valueType === ObjectAlertSettingValueTypes.boolean) {
                    setting.value = false
                }
                object.alertSettings.push(setting)
            }
        });
    }

    private async handleObjectTypeChange(objectTypeId: string) {
        let object = this.state.editingObject!
        this.changeObjectType(object, objectTypeId)

        this.setState({ ...this.state, editingObject: object })
    }

    private async handleSettingChange(index: number, value: any) {
        let object = this.state.editingObject!
        let setting = object.settings[index]
        setting.value = value
        this.setState({ ...this.state, editingObject: object })
    }

    private async handleAlertSettingChange(index: number, value: any) {
        let object = this.state.editingObject!
        let setting = object.alertSettings[index]
        setting.value = value
        this.setState({ ...this.state, editingObject: object })
    }

    private async handleObjectDelete() {
        if (window.confirm(`Supprimer l'objet ${this.state.editingObject!.name} ?`)) {
            try {
                await ObjectApi.getInstance().delete(this.props.match.params.organization, this.props.match.params.installation, this.state.editingObject!.objectId)
                window.location.reload(false)
            } catch (error) {
                this.setState({ ...this.state, editingLoading: false, editingError: error.message })
            }
        }
    }

    private async submitSettings() {
        var installation = this.state.installation!
        installation.timezone = parseInt(this.timezoneRef.current!.value)
        installation.workingScheduler = this.state.workingScheduler!.sort((a, b) => (a.day as number) - (b.day as number)).map((_) => {
            const keys = Object.keys(_).filter(k => k !== 'day').sort((a, b) => parseInt(a) - parseInt(b))
            return keys.map(k => _[k] === 1)
        })
        installation.alertsEnabled = this.alertsEnabledRef.current!.checked;
        const updatedInstallation = await InstallationApi.getInstance().edit(this.props.match.params.organization, installation);
        this.setState({
            ...this.state,
            installation: updatedInstallation
        })
    }

    private async submit(event: React.FormEvent<HTMLElement>) {
        event.preventDefault()

        var object = this.state.editingObject!
        const target: any = event.target
        object.name = target.name.value
        object.installationId = this.props.match.params.installation

        object.settings.forEach(element => {
            if (element.value === undefined) {
                element.value = null;
            }
        });

        object.alertSettings.forEach(element => {
            if (element.value === undefined) {
                element.value = null;
            }
        });

        try {
            this.setState({ ...this.state, editingLoading: true })
            if (object.objectId === '') {
                await ObjectApi.getInstance().create(this.props.match.params.organization, this.props.match.params.installation, object)
            }
            else {
                await ObjectApi.getInstance().edit(this.props.match.params.organization, this.props.match.params.installation, object)
            }
            window.location.reload(false)
        } catch (error) {
            this.setState({ ...this.state, editingObject: object, editingLoading: false, editingError: error.message })
        }
    }

    private renderContent() {
        return <>
            {this.state.editingObject &&
                <Modal show={this.state.showEditModal} size="xl">
                    <Form onSubmit={e => this.submit(e)}>
                        <Modal.Header>
                            <Modal.Title>
                                {this.state.editingObject.objectId === '' ? 'Nouvel objet' : `Modification de l'object ${this.state.editingObject!.name}`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="p-2">
                                {this.state.editingLoading ? <Loader /> : <>
                                    <Form.Group as={Row} controlId='name'>
                                        <Form.Label column sm={2}>Nom</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="text" name="name" defaultValue={this.state.editingObject?.name} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId='objectTypeId'>
                                        <Form.Label column sm={2}>Type d'objet</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control as="select" name="objectTypeId" onChange={(e) => this.handleObjectTypeChange(e.target.value)} value={this.state.editingObject?.objectTypeId}>
                                                {this.state.objectTypes.map(_ => <option key={_.id} value={_.id}>{_.name}</option>)}
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    <Card>
                                        <Card.Header>
                                            Paramètres
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={5}>Activé</Form.Label>
                                                    <Col sm={7}>
                                                        <Form.Check name="enabled" defaultChecked={this.state.editingObject?.enabled} onChange={(e) => {
                                                            let object = this.state.editingObject!
                                                            object.enabled = e.target.checked
                                                            this.setState({ ...this.state, editingObject: object })
                                                        }} />
                                                </Col>
                                            </Form.Group>
                                            {this.state.editingObject?.settings.map((value, index) =>
                                                <ObjectSettingEditor
                                                    key={`setting-${value.objectTypeSettingKey}`}
                                                    organization={this.props.match.params.organization}
                                                    installation={this.props.match.params.installation}
                                                    object={this.state.editingObject!}
                                                    setting={value}
                                                    index={index}
                                                    objectTypeSettings={this.state.objectTypes.find(_ => _.id === this.state.editingObject!.objectTypeId)!.settings}
                                                    onChange={(index, value) => this.handleSettingChange(index, value)}
                                                />
                                            )}
                                        </Card.Body>
                                    </Card>
                                    
                                    <Card className="mt-2">
                                        <Card.Header>
                                            Paramètres d'alertes
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={5}>Alertes activées</Form.Label>
                                                <Col sm={7}>
                                                    <Form.Check id="alertsEnabled" defaultChecked={this.state.editingObject?.alertsEnabled} onChange={(e) => {
                                                        let object = this.state.editingObject!
                                                        object.alertsEnabled = e.target.checked
                                                        this.setState({ ...this.state, editingObject: object })
                                                    }} />
                                                </Col>
                                            </Form.Group>
                                            {this.state.editingObject?.alertSettings.map((value, index) =>
                                                <ObjectAlertSettingEditor
                                                    key={`setting-alert-${value.objectTypeSettingKey}`}
                                                    organization={this.props.match.params.organization}
                                                    installation={this.props.match.params.installation}
                                                    object={this.state.editingObject!}
                                                    setting={value}
                                                    index={index}
                                                    objectTypeAlertSettings={this.state.objectTypes.find(_ => _.id === this.state.editingObject!.objectTypeId)!.alertSettings}
                                                    onChange={(index, value) => this.handleAlertSettingChange(index, value)}
                                                />
                                            )}
                                        </Card.Body>
                                    </Card>
                                    {this.state.editingError && <Alert variant="danger" className="mt-3">{this.state.editingError}</Alert>}
                                </>}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setState({ ...this.state, showEditModal: false })}>Annuler</Button>
                            {this.state.editingObject.objectId !== '' && <Button variant="danger" onClick={() => this.handleObjectDelete()}>Supprimer</Button>}
                            <Button variant="primary" type="submit">Valider</Button>{' '}
                        </Modal.Footer>
                    </Form>
                </Modal>}
            <Card body>
                <h3>
                    Paramètres
                    <Button className="float-right" variant='outline-secondary' size="sm" onClick={() => this.submitSettings()}>Enregistrer</Button>
                </h3>
                <hr />
                <div className='mb-4'>
                    <Form.Group controlId="alertsEnabled">
                        <Form.Label>Alertes</Form.Label>
                        <Form.Switch ref={this.alertsEnabledRef} defaultChecked={this.state.installation?.alertsEnabled} />
                    </Form.Group>
                    <Form.Group controlId="timezone">
                        <Form.Label sm={1}>Timezone</Form.Label>
                        <Form.Control ref={this.timezoneRef} type="number" name="timezone" defaultValue={this.state.installation?.timezone} />
                    </Form.Group>
                    <Form.Group controlId="workingScheduler">
                        <Form.Label sm={1}>Working Scheduler</Form.Label>
                        <div style={{ height: 260 }}>
                            {this.state.workingScheduler &&
                                <ResponsiveHeatMap
                                    margin={{ top: 20, right: 0, bottom: 0, left: 60 }}
                                    data={this.state.workingScheduler!}
                                    indexBy='day'
                                    keys={Object.keys(this.state.workingScheduler[0]).filter(_ => _ !== 'day')}
                                    hoverTarget='cell'
                                    colors={['#dc3545', '#28a745']}
                                    enableLabels={false}
                                    axisLeft={{
                                        format: (value: number | string | Date) => this.getDay(value as number)
                                    }}
                                    axisTop={{
                                        format: (value: number | string | Date) => `${value}h`
                                    }}
                                    cellBorderWidth={1}
                                    cellBorderColor='#eeeeee'
                                    onClick={(datum: NodeData) => this.onWorkingSchedulerClick(datum)}
                                    cellHoverOthersOpacity={0.7}
                                    tooltip={undefined}
                                />}
                        </div>
                    </Form.Group>
                </div>
                <h3>
                    Objets
                    <Button className="float-right" variant='outline-secondary' size="sm" onClick={() => this.edit(undefined)}><FaPlus /></Button>
                </h3>
                <hr />
                <div className='row row-cols-3'>
                    {this.state.objects.map(_ => { 
                        const objectType = this.state.objectTypes.find(ot => ot.id === _.objectTypeId)
                        return <div className="col p-2" key={_.objectId}>
                        <Card style={{ minHeight: 360 }}>
                            <Card.Header className='d-flex'>
                                <div className='w-75'>
                                    <Card.Title className='text-truncate' title={_.name}>
                                        {_.name}
                                    </Card.Title>
                                    <Card.Subtitle>
                                        {_.objectTypeName}
                                    </Card.Subtitle>
                                </div>
                                <div className='' style={{ width: 60 }}>{' '}</div>
                                <div className='d-flex align-content-center flex-wrap mr-n2'>
                                    <Button variant='outline-secondary' onClick={() => this.edit(_)}><FaEdit /></Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <dl className="row">
                                    <dt className="col-sm-6">Activé</dt>
                                    <dd className="col-sm-6"><small>{_.enabled ? "Oui" : "Non"}</small></dd>
                                    <dt className="col-sm-6">Alertes activées</dt>
                                    <dd className="col-sm-6"><small>{_.alertsEnabled ? "Oui" : "Non"}</small></dd>
                                {_.settings.map(setting =>
                                    <Fragment key={setting.objectTypeSettingKey}>
                                    <dt className="col-sm-6">{setting.getSettingType(objectType!.settings)?.name}</dt>
                                    <dd className="col-sm-6"><small>{setting.value?.toString() ?? ''}</small></dd>
                                    </Fragment>
                                )}
                                </dl>
                            </Card.Body>
                        </Card>
                            </div>})}
                </div>
            </Card>
        </>
    }
    private onWorkingSchedulerClick(datum: NodeData): void {
        const y = datum.yKey as number
        const data = this.state.workingScheduler!
        const value = data[y][datum.xKey]
        data[y][datum.xKey] = value === 1 ? 0 : 1
        this.setState({
            ...this.state,
            workingScheduler: data
        })
    }
}