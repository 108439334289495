import React, { Component } from "react";
import { ObjectSetting, ObjectDetails } from'../../models/dto/Object'
import { ObjectTypeSetting, ObjectSettingValueTypes } from "../../models/dto/ObjectType";
import { DeviceSummary } from "../../models/dto/Device";
import ObjectTypeApi from "../../apis/ObjectTypeApi";
import Loader from "../common/Loader";
import ObjectApi from "../../apis/ObjectApi";
import { Form, Row, Dropdown, Col } from "react-bootstrap";

interface ObjectSettingEditorState {
    isDropdownOpen: boolean
    devices?: DeviceSummary[]
    objects?: ObjectDetails[]
}

interface ObjectSettingEditorProps {
    organization: string
    installation: string
    object: ObjectDetails
    setting: ObjectSetting
    objectTypeSettings: ObjectTypeSetting[]
    index: number
    inline?: boolean
    onChange: ((index: number, value: any) => void)
}
export default class ObjectSettingEditor extends Component<ObjectSettingEditorProps, ObjectSettingEditorState> {
    objectTypeSetting: ObjectTypeSetting

    constructor(props: ObjectSettingEditorProps) {
        super(props)
        this.state = {
            isDropdownOpen: false, 
            devices: undefined, 
            objects: undefined
        }
        this.objectTypeSetting = props.objectTypeSettings.find(_ => _.key === props.setting.objectTypeSettingKey)!
    }

    async componentDidMount() {
        if (!this.objectTypeSetting) {
            return
        }
        switch (this.objectTypeSetting.valueType) {
            case ObjectSettingValueTypes.deviceId:
                var devices = await ObjectTypeApi.getInstance().getDevices(this.props.object.objectTypeId)
                this.setState({
                    ...this.state, 
                    devices: devices
                })
                break;
            case ObjectSettingValueTypes.objectId:
                var objects = await ObjectApi.getInstance().fetch(this.props.organization, this.props.installation)
                this.setState({
                    ...this.state, 
                    objects: objects
                })
                break;
        
            default:
                break;
        }
    }

    render() {
        if(!this.objectTypeSetting) {
            return <></>
        }
        if (this.props.inline ?? true) {
            return <div>
                <Form.Group as={Row}>
                    <Form.Label column sm={5}>{this.objectTypeSetting.name} {this.objectTypeSetting.isMandatory === true ? '*' : ''}</Form.Label>
                    <Col sm={7}>
                        {this.renderControl()}
                    </Col>
                </Form.Group>
            </div>
        }
        else {
            return <div>
                <Form.Group>
                    <Form.Label>{this.objectTypeSetting.name}</Form.Label>
                    {this.renderControl()}
                </Form.Group>
            </div>
        }
    }

    renderControl() {
        switch (this.objectTypeSetting.valueType) {
            case ObjectSettingValueTypes.deviceId:
                const device = this.state.devices?.find(_ => _.deviceId === this.props.setting.value)
                return <Dropdown>
                    <Dropdown.Toggle>
                        {this.props.setting.value ? (device ? `${device.deviceId} - ${device.typeName}` : '...') : 'Choisir un appareil'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='scrollable-menu'>
                        {this.state.devices ? this.state.devices.map(_ => <Dropdown.Item key={_.deviceId} onClick={() => this.props.onChange(this.props.index, _.deviceId)}>{_.deviceId}<br /><small>{_.typeName}</small></Dropdown.Item>) : <Loader />}
                    </Dropdown.Menu>
                </Dropdown>
            case ObjectSettingValueTypes.objectId:
                const object = this.state.objects?.find(_ => _.objectId === this.props.setting.value)
                return <Dropdown>
                    <Dropdown.Toggle>
                        {this.props.setting.value ? (object ? `${object.name} - ${object.objectTypeName}` : '...') : 'Choisir un objet'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='scrollable-menu'>
                        {this.state.objects ? this.state.objects.map(_ => <Dropdown.Item key={_.objectId} onClick={() => this.props.onChange(this.props.index, _.objectId)}>{_.name}<br /><small>{_.objectTypeName}</small></Dropdown.Item>) : <Loader />}
                    </Dropdown.Menu>
                </Dropdown>
            case ObjectSettingValueTypes.string:
                return <Form.Control type="text" name={this.props.setting.objectTypeSettingKey} value={this.props.setting.value} onChange={(e) => this.props.onChange(this.props.index, e.target.value)} />
            case ObjectSettingValueTypes.number:
                return <Form.Control type="number" name={this.props.setting.objectTypeSettingKey} value={this.props.setting.value} onChange={(e) => this.props.onChange(this.props.index, Number(e.target.value))} />
            case ObjectSettingValueTypes.boolean:
                return <Form.Check name={this.props.setting.objectTypeSettingKey} checked={this.props.setting.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.onChange(this.props.index, Boolean(e.target.checked))} />
            default:
                return <span>Type de valeur non supporté</span>
        }
    }
}