import { OrganizationPage } from '../models/dto/Organization'
import ApiBase from './ApiBase';

export default class OrganizationApi extends ApiBase {
    private static instance: OrganizationApi;

    private constructor() {
        super("https://auditkit.bluegriot.com/identity_api")
    }
  
    static getInstance(): OrganizationApi {
      if (!OrganizationApi.instance) {
        OrganizationApi.instance = new OrganizationApi()
      }
  
      return OrganizationApi.instance
    }

    public async fetch(): Promise<OrganizationPage> {
        return await this.getItem('/api/Organizations?page=1&pageSize=1000', OrganizationPage)
    }
}