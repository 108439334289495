import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { JsonConvert } from 'json2typescript';
import authService from '../components/api-authorization/AuthorizeService';

axios.defaults.validateStatus = function (status) {
    return true
};

export default abstract class ApiBase {
    static defaultBaseUrl: string = document.getElementsByTagName("base")[0].baseURI
    protected client: AxiosInstance;
    protected jsonConverter: JsonConvert

    constructor(baseUrl: string = ApiBase.defaultBaseUrl) {
        this.client = axios.create({
            baseURL: baseUrl
        })

        this.jsonConverter = new JsonConvert()
    }

    protected async getHeader(allowAnonymous: boolean): Promise<any> {
        var headers = {};
        if (!allowAnonymous) {
            var token = await authService.getAccessToken();
            headers = {
                'Authorization': `Bearer ${token}`
            }
        }
        return headers
    }

    protected async handleResponse(response: AxiosResponse<any>) {
        if (response.status === 401) {
            window.location.reload()
            //const returnUrl = window.location.href
            //const state = { returnUrl }
            // const signInResult = await authService.signIn(state)
            // switch (signInResult.status) {
            //     case AuthenticationResultStatus.Redirect:
            //     case AuthenticationResultStatus.Success:
            //         window.location.replace(returnUrl)
            //         break
            //     case AuthenticationResultStatus.Fail:
            //         throw new Error(`Authentication failed ${signInResult.message}.`)
            //     default:
            //         throw new Error(`Invalid status result ${signInResult.status}.`)
            // }
        }
        else if (response.status >= 300) {
            if (response.data.title) {
                var message = response.data.title
                if (response.data.errors) {
                    message += `\n${JSON.stringify(response.data.errors)}`
                }
                throw new Error(`Invalid response : status code ${response.status} (${response.statusText}).\n${message}`)    
            }
            throw new Error(`Invalid response : status code ${response.status} (${response.statusText}).`)
        }
    }

    protected async getItem<TItem>(url: string, classReference: { new (): TItem }, allowAnonymous: boolean = false): Promise<TItem> {
        const result = await this.client.get(url, {
            headers: await this.getHeader(allowAnonymous)
        })
        await this.handleResponse(result)
        const data: any = result.data;
        return this.jsonConverter.deserializeObject(data, classReference)
    }

    protected async getItems<TItem>(url: string, classReference: { new (): TItem }, allowAnonymous: boolean = false): Promise<TItem[]> {
        const result = await this.client.get(url, {
            headers: await this.getHeader(allowAnonymous)
        })
        await this.handleResponse(result)
        const data: any = result.data;
        return this.jsonConverter.deserializeArray(data, classReference)
    }

    protected async postItem<TBody, TItem>(url: string, item: TBody, classReference: { new (): TBody }, itemClassReference: { new (): TItem }, allowAnonymous: boolean = false): Promise<TItem | undefined> {
        const body = this.jsonConverter.serializeObject(item, classReference)
        const result = await this.client.post(url, body, {
            headers: await this.getHeader(allowAnonymous)
        })
        await this.handleResponse(result)
        const data: any = result.data;
        if (data) {
            return this.jsonConverter.deserializeObject(data, itemClassReference)
        }
        else {
            return undefined
        }
    }

    protected async putItem<TBody, TItem>(url: string, item: TBody, classReference: { new (): TBody }, itemClassReference: { new (): TItem }, allowAnonymous: boolean = false): Promise<TItem | undefined> {
        const body = this.jsonConverter.serializeObject(item, classReference)
        const result = await this.client.put(url, body, {
            headers: await this.getHeader(allowAnonymous)
        })
        await this.handleResponse(result)
        const data: any = result.data;
        if (data) {
            return this.jsonConverter.deserializeObject(data, itemClassReference)
        }
        else {
            return undefined
        }
    }

    protected async putItems<TBody, TItem>(url: string, items: TBody[], classReference: { new (): TBody }, itemClassReference: { new (): TItem }, allowAnonymous: boolean = false): Promise<TItem | undefined> {
        const body = this.jsonConverter.serializeArray(items, classReference)
        const result = await this.client.put(url, body, {
            headers: await this.getHeader(allowAnonymous)
        })
        await this.handleResponse(result)
        const data: any = result.data;
        if (data) {
            return this.jsonConverter.deserializeObject(data, itemClassReference)
        }
        else {
            return undefined
        }
    }

    protected async deleteItem<TItem>(url: string, classReference?: { new (): TItem }, allowAnonymous: boolean = false): Promise<TItem | undefined> {
        const result = await this.client.delete(url, {
            headers: await this.getHeader(allowAnonymous)
        })
        await this.handleResponse(result)
        if (classReference) {
            const data: any = result.data;
            if (data) {
                return this.jsonConverter.deserializeObject(data, classReference)
            }
            else {
                return undefined
            }
        }
    }
}