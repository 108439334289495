import {JsonObject, JsonProperty, JsonConvert, Any} from "json2typescript"
import { ObjectSettingValueTypesConverter, ObjectAlertSettingValueTypesConverter } from '../converters/ObjectSettingValueTypesConverter'

export enum ObjectSettingValueTypes {
    number, 
    string, 
    deviceId, 
    objectId, 
    boolean
}

export const ObjectSettingValueTypesLabel = (value: ObjectSettingValueTypes) => {
    switch (value) {
        case ObjectSettingValueTypes.deviceId:
            return "Référence à un appareil"
        case ObjectSettingValueTypes.number:
            return "Nombre"
        case ObjectSettingValueTypes.string:
            return "Chaîne de caractères"
        case ObjectSettingValueTypes.objectId:
            return "Référence à un objet"
        case ObjectSettingValueTypes.boolean:
            return "Booléen"
        default:
            return "?";
    }
}

export enum ObjectAlertSettingValueTypes {
    number, 
    string, 
    deviceId, 
    objectId, 
    boolean
}

export const ObjectAlertSettingValueTypesLabel = (value: ObjectAlertSettingValueTypes) => {
    switch (value) {
        case ObjectAlertSettingValueTypes.deviceId:
            return "Référence à un appareil"
        case ObjectAlertSettingValueTypes.number:
            return "Nombre"
        case ObjectAlertSettingValueTypes.string:
            return "Chaîne de caractères"
        case ObjectAlertSettingValueTypes.objectId:
            return "Référence à un objet"
        case ObjectAlertSettingValueTypes.boolean:
            return "Booléen"
        default:
            return "?";
    }
}

@JsonObject("ObjectTypeSetting")
export class ObjectTypeSetting {
    @JsonProperty("key", String)
    key: string = ''

    @JsonProperty("name", String)
    name: string = ''

    @JsonProperty("valueType", ObjectSettingValueTypesConverter)
    valueType: ObjectSettingValueTypes = ObjectSettingValueTypes.string

    @JsonProperty("variableName", String, true)
    variableName: string = ''

    @JsonProperty("valueFilters", [Any], true)
    valueFilters?: any[] = undefined

    @JsonProperty("isAdministrationOnly", Boolean)
    isAdministrationOnly: boolean = false

    @JsonProperty("isSharedWithDevice", Boolean)
    isSharedWithDevice: boolean = false

    @JsonProperty("isMandatory", Boolean, true)
    isMandatory: boolean = false
}

@JsonObject("ObjectTypeAlertSetting")
export class ObjectTypeAlertSetting {
    @JsonProperty("key", String)
    key: string = ''

    @JsonProperty("name", String)
    name: string = ''

    @JsonProperty("valueType", ObjectAlertSettingValueTypesConverter)
    valueType: ObjectAlertSettingValueTypes = ObjectAlertSettingValueTypes.string

    @JsonProperty("variableName", String, true)
    variableName: string = ''

    @JsonProperty("valueFilters", [Any], true)
    valueFilters?: any[] = undefined

    @JsonProperty("isAdministrationOnly", Boolean)
    isAdministrationOnly: boolean = false

    @JsonProperty("isMandatory", Boolean, true)
    isMandatory: boolean = false
}

@JsonObject("ObjectTypeDetails")
export class ObjectTypeDetails {
    @JsonProperty("objectTypeId", String)
    id: string = ''

    @JsonProperty("name", String)
    name: string = ''

    @JsonProperty("description", String, true)
    description?: string = undefined

    @JsonProperty("settings", [ObjectTypeSetting])
    settings: ObjectTypeSetting[] = []

    @JsonProperty("alertSettings", [ObjectTypeAlertSetting])
    alertSettings: ObjectTypeAlertSetting[] = []

    @JsonProperty("evaluationScript", String, true)
    evaluationScript: string = ''

    @JsonProperty("alertEvaluationScript", String, true)
    alertEvaluationScript: string = '' 

    copy(): ObjectTypeDetails {
        const converter = new JsonConvert()
        const serialized = converter.serializeObject(this, ObjectTypeDetails)
        return converter.deserializeObject(serialized, ObjectTypeDetails)
    }
}

@JsonObject("ObjectTypeNew")
export class ObjectTypeNew {
    @JsonProperty("name", String)
    name: string = ''

    @JsonProperty("description", String, true)
    description?: string = undefined

    @JsonProperty("settings", [ObjectTypeSetting])
    settings: ObjectTypeSetting[] = []

    @JsonProperty("alertSettings", [ObjectTypeAlertSetting])
    alertSettings: ObjectTypeAlertSetting[] = []

    @JsonProperty("evaluationScript", String)
    evaluationScript: string = ''

    @JsonProperty("alertEvaluationScript", String)
    alertEvaluationScript: string = '' 
}