import React, { Component } from "react";
import { ObjectDetails, ObjectAlertSetting } from'../../models/dto/Object'
import { ObjectTypeAlertSetting, ObjectAlertSettingValueTypes } from "../../models/dto/ObjectType";
import { DeviceSummary } from "../../models/dto/Device";
import ObjectTypeApi from "../../apis/ObjectTypeApi";
import Loader from "../common/Loader";
import ObjectApi from "../../apis/ObjectApi";
import { Form, Row, Dropdown, Col } from "react-bootstrap";

interface ObjectAlertSettingEditorState {
    isDropdownOpen: boolean
    devices?: DeviceSummary[]
    objects?: ObjectDetails[]
}

interface ObjectAlertSettingEditorProps {
    organization: string
    installation: string
    object: ObjectDetails
    setting: ObjectAlertSetting
    objectTypeAlertSettings: ObjectTypeAlertSetting[]
    index: number
    inline?: boolean
    onChange: ((index: number, value: any) => void)
}
export default class ObjectAlertSettingEditor extends Component<ObjectAlertSettingEditorProps, ObjectAlertSettingEditorState> {
    objectTypeAlertSetting: ObjectTypeAlertSetting

    constructor(props: ObjectAlertSettingEditorProps) {
        super(props)
        this.state = {
            isDropdownOpen: false, 
            devices: undefined, 
            objects: undefined
        }
        this.objectTypeAlertSetting = props.objectTypeAlertSettings.find(_ => _.key === props.setting.objectTypeSettingKey)!
    }

    async componentDidMount() {
        if (!this.objectTypeAlertSetting) {
            return
        }
        switch (this.objectTypeAlertSetting.valueType) {
            case ObjectAlertSettingValueTypes.deviceId:
                var devices = await ObjectTypeApi.getInstance().getDevices(this.props.object.objectTypeId)
                this.setState({
                    ...this.state, 
                    devices: devices
                })
                break;
            case ObjectAlertSettingValueTypes.objectId:
                var objects = await ObjectApi.getInstance().fetch(this.props.organization, this.props.installation)
                this.setState({
                    ...this.state, 
                    objects: objects
                })
                break;
        
            default:
                break;
        }
    }

    render() {
        if(!this.objectTypeAlertSetting) {
            return <></>
        }
        if (this.props.inline ?? true) {
            return <div>
                <Form.Group as={Row}>
                    <Form.Label column sm={5}>{this.objectTypeAlertSetting.name}</Form.Label>
                    <Col sm={7}>
                        {this.renderControl()}
                    </Col>
                </Form.Group>
            </div>
        }
        else {
            return <div>
                <Form.Group>
                    <Form.Label>{this.objectTypeAlertSetting.name}</Form.Label>
                    {this.renderControl()}
                </Form.Group>
            </div>
        }
    }

    renderControl() {
        switch (this.objectTypeAlertSetting.valueType) {
            case ObjectAlertSettingValueTypes.deviceId:
                const device = this.state.devices?.find(_ => _.deviceId === this.props.setting.value)
                return <Dropdown>
                    <Dropdown.Toggle>
                        {this.props.setting.value ? (device ? `${device.deviceId} - ${device.typeName}` : '...') : 'Choisir un appareil'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='scrollable-menu'>
                        {this.state.devices ? this.state.devices.map(_ => <Dropdown.Item key={_.deviceId} onClick={() => this.props.onChange(this.props.index, _.deviceId)}>{_.deviceId}<br /><small>{_.typeName}</small></Dropdown.Item>) : <Loader />}
                    </Dropdown.Menu>
                </Dropdown>
            case ObjectAlertSettingValueTypes.objectId:
                const object = this.state.objects?.find(_ => _.objectId === this.props.setting.value)
                return <Dropdown>
                    <Dropdown.Toggle>
                        {this.props.setting.value ? (object ? `${object.name} - ${object.objectTypeName}` : '...') : 'Choisir un objet'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='scrollable-menu'>
                        {this.state.objects ? this.state.objects.map(_ => <Dropdown.Item key={_.objectId} onClick={() => this.props.onChange(this.props.index, _.objectId)}>{_.name}<br /><small>{_.objectTypeName}</small></Dropdown.Item>) : <Loader />}
                    </Dropdown.Menu>
                </Dropdown>
            case ObjectAlertSettingValueTypes.string:
                return <Form.Control type="text" name={this.props.setting.objectTypeSettingKey} value={this.props.setting.value} onChange={(e) => this.props.onChange(this.props.index, e.target.value)} />
            case ObjectAlertSettingValueTypes.number:
                return <Form.Control type="number" name={this.props.setting.objectTypeSettingKey} value={this.props.setting.value} onChange={(e) => this.props.onChange(this.props.index, Number(e.target.value))} />
            case ObjectAlertSettingValueTypes.boolean:
                return <Form.Check name={this.props.setting.objectTypeSettingKey} checked={this.props.setting.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.onChange(this.props.index, Boolean(e.target.checked))} />
            default:
                return <span>Type de valeur non supporté</span>
        }
    }
}