import * as React from 'react';
import ObjectCardContent, { IObjectCardContentProps, IObjectCardContentState } from '../ObjectCardContent'
import { ObjectDetails } from '../../../models/dto/Object';

export interface IEpaperProps extends IObjectCardContentProps {

}

export interface IEpaperState extends IObjectCardContentState {
    
}

export default class Epaper extends ObjectCardContent<IEpaperProps, IEpaperState> {
    static objectTypeId = '982fcdb6-9f3a-4096-bc6c-b821c67eeb8a'

    getInitialState(): IEpaperState {
        return {
            loading: false, 
            updating: false
        }
    }

    async updateState(_: ObjectDetails): Promise<IEpaperState> {
        return this.state
    }

    render() {
        return super.render() ?? this.renderContent()
    }

    renderContent() {
        return <>
            <div style={{height: '100%', textAlign: 'center'}}>
                <h2>{this.props.object.getSettingValue("immat", this.props.objectType.settings)}</h2>
                <h1>{this.props.object.getSettingValue("price", this.props.objectType.settings)}€</h1>
            </div>
        </>
    }
}
