import * as React from 'react';
import Chart from 'react-google-charts';
import TelemetryApi from '../../../apis/TelemetryApi';
import { ObjectDetails } from '../../../models/dto/Object';
import Query from '../../../models/dto/Query';
import Loader from '../../common/Loader';
import ObjectCardContent, { IObjectCardContentProps, IObjectCardContentState } from '../ObjectCardContent';

export interface IPneuProps extends IObjectCardContentProps {

}

export interface IPneuState extends IObjectCardContentState {
  telemetries?: any[]
  level: number
  percent: number
}

export default class Pneu extends ObjectCardContent<IPneuProps, IPneuState> {
  static objectTypeId = 'c3de771c-bc8f-4c48-9db3-e6a84388cfce'

  async updateState(_: ObjectDetails): Promise<IPneuState> {
    var rows = await TelemetryApi.getInstance().query(this.props.organization, new Query().withRange(this.props.range.toQueryRange()).withFilters([
      `r["object"] == "${this.props.object.objectId}"`,
      `r["_measurement"] == "level"`,
      `r["_field"] == "percent" or r["_field"] == "level"`
    ]).withKeepColumns(['_time', 'percent', 'level']).withAggregateWindow('last', this.props.range.interval.duration, true).withPivot(['_field'], ['_time'], '_value'))

    rows = rows.map(row => {
      return {
        ...row,
        time: Date.parse(row._time),
        _time: new Date(row._time),
        percent: row.percent === '' ? null : parseFloat(row.percent)
      }
    }).sort((a: any, b: any) => a.time - b.time)

    var data = rows.map(_ => [_._time, _.percent / 100.0, _.percent === undefined ? `Remplissage: ${_.percent.toFixed(0)}%` : null])
    return {
      ...this.state,
      telemetries: data
    }
  }

  getInitialState(): IPneuState {
    const percent: number = this.props.object.shadow.Percent
    var level = -1
    if (percent < 17) {
      level = -1
    }
    else if (percent < 34) {
      level = 0
    }
    else if (percent < 50) {
      level = 1
    }
    else if (percent < 68) {
      level = 2
    }
    else if (percent < 85) {
      level = 3
    }
    else {
      level = 4
    }
    return {
      loading: true,
      updating: true,
      percent: percent,
      level: level
    }
  }

  private renderState() {
    return <div className='row d-flex'>
      <div className='mx-auto my-n2'>
        <SvgContainerComponent height={140} level={this.state.level} />
      </div>
    </div>
  }

  private renderChart() {
    if (this.state.telemetries!.length === 0) {
      return <div className='d-flex h-100 align-items-center justify-content-center'>Aucune donnée pour cette période</div>
    }
    return <div className='mt-n3'>
      <Chart
        chartLanguage='fr'
        chartType="LineChart"
        height={130}
        width='100%'
        data={[
          [{ type: 'datetime' },
            'Remplissage',
          { role: 'tooltip' }
        ],
          ...this.state.telemetries!
        ]}
        options={{
          colors: ['#8884d8', '#82ca9d'],
          legend: { position: 'none' },
          chartArea: { width: '76%', height: '75%' },
          series: {
            0: { axis: 'Remplissage', targetAxisIndex: 0 }
          },
          vAxes: [
            {
              format: 'percent',
              minValue: 0,
              maxValue: 1
            }
          ],
          hAxis: {
            minValue: this.props.range.start.toDate(),
            maxValue: this.props.range.stop?.toDate() ?? new Date()
          }
        }}
        rootProps={{ object: this.props.object.objectId }}
      />
    </div>
  }

  render() {
    return super.render() ?? this.renderContent()
  }

  private renderContent() {
    if (this.props.object.shadow.Level === undefined || this.props.object.shadow.Percent === undefined) {
      return <div className='d-flex h-100 align-items-center justify-content-center'>Aucune donnée pour cette période</div>
    }
    return <>
      <div>
        {this.renderState()}
      </div>
      <hr />
      <div>
        {this.state.updating ? <Loader color="dark" /> : this.state.telemetries && this.renderChart()}
      </div>
    </>
  }
}

function SvgContainerComponent(props: { level: number, height: number}) {
  return (
    <svg height={props.height} viewBox="0 0 149 145">
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(.76 1.37) translate(0 .775)">
            <path
              d="M50.73.015a.108.108 0 00-.11 0c-1.02.59-2.03 1.17-3.05 1.76-.02.01-.03.02-.04.04-.01.02-.02.03-.02.05v1.2c-14.16 8.18-28.32 16.35-42.49 24.53-.34-.2-.68-.4-1.03-.59 0 0 0 0 0 0-.01-.01-.03-.01-.05-.01h-.02c-.01 0-.02 0-.03.01 0 0-.01 0-.01.01-1.02.59-2.03 1.17-3.05 1.76-.01 0-.02.01-.02.02-.01.01-.02.02-.02.04-.01.01-.01.03-.01.04 0 .02.02.04.03.05v2.68c0 .07.02.13.05.19v49.87c-.01 0-.02 0-.03.01-.01 0-.02.01-.02.02-.01.01-.02.02-.02.04-.01.01-.01.03-.01.04v2.79s0 0 0 0v.02c0 .01.01.02.01.03 0 .01.01.01.01.02l.03.03c1.12.65 2.24 1.29 3.36 1.94 31.01 17.91 62.03 35.81 93.04 53.72 0 0 0 0 0 0 .01.01.03.01.05.01h.02c.01 0 .02 0 .03-.01 0 0 .01 0 .01-.01 1.01-.58 2.01-1.16 3.02-1.74.04-.02.08-.05.12-.07v-1.42c.82-.48 1.65-.95 2.47-1.43.41.24.82.47 1.23.71a112974.37 112974.37 0 0135.75-20.64c.05-.03.11-.06.16-.09v-1.42c.87-.5 1.73-1 2.6-1.5.41.24.82.47 1.23.71.05-.03.11-.06.16-.09 1.02-.59 2.03-1.17 3.05-1.76l.02-.02c.01 0 .01 0 .02-.01s.01-.02.02-.03v-.02-.01-2.79-50.1-2.79-.03c0-.01-.01-.03-.02-.04l-.03-.03c-1.12-.65-2.24-1.29-3.36-1.94-.01-.01-.03-.01-.04-.01h-.04c-.01 0-.02.01-.03.01-.26.15-.53.31-.79.46-29.57-17.07-59.14-34.15-88.72-51.22v-.92c0-.01 0-.01-.01-.02 0-.01-.01-.02-.01-.03 0-.01-.01-.02-.02-.03l-.01-.01h-.01s0 0 0 0c-1.13-.69-2.25-1.34-3.37-1.98z"
              stroke="#D1D1D1"
              strokeWidth={0.9357}
              fill="#002C6F"
              fillRule="nonzero"
            />
            <line
              x1={51.07}
              y1={4.405}
              x2={53.85}
              y2={2.795}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={48.05}
              y1={2.745}
              x2={50.91}
              y2={4.405}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M50.91 4.405c.04.03.11.03.16 0"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g
              stroke="#FFF"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.7271}
            >
              <g transform="translate(0 2.225)">
                <path d="M5.19 80.8c-.05-.03-.11-.01-.15.02" />
                <line x1={0.96} y1={79.54} x2={4.26} y2={81.45} />
                <line x1={97.25} y1={138.05} x2={4.21} y2={84.33} />
                <line x1={53.85} y1={0.57} x2={142.87} y2={51.96} />
                <line x1={5.03} y1={25.36} x2={48.05} y2={0.52} />
                <line x1={143.77} y1={55.98} x2={50.98} y2={2.78} />
                <line x1={50.93} y1={51.19} x2={50.98} y2={2.78} />
                <line x1={50.96} y1={2.77} x2={7.43} y2={27.9} />
                <line x1={143.77} y1={55.98} x2={140.59} y2={54.14} />
                <line x1={140.59} y1={54.14} x2={140.59} y2={53.28} />
                <line x1={140.59} y1={53.28} x2={143.66} y2={51.5} />
                <path d="M144.09 58.19c-.01 0-.02.01-.03.01-.01 0-.03.01-.04 0-.01 0-.03-.01-.04-.01" />
                <line x1={5.03} y1={25.36} x2={7.37} y2={26.71} />
                <line x1={7.42} y1={26.92} x2={7.42} y2={29.59} />
                <line x1={147.13} y1={109.31} x2={144.09} y2={111.07} />
                <line x1={7.42} y1={26.91} x2={7.42} y2={26.92} />
                <path d="M4.24 31.46c-.06 0-.13-.02-.19-.05" />
                <path d="M143.66 51.5c.01 0 .02-.01.03-.01.01 0 .03-.01.04 0 .01 0 .03.01.04.01" />
                <path d="M147.13 53.45l.03.03c.01.01.02.02.02.04v.03" />
                <line x1={143.77} y1={51.5} x2={147.13} y2={53.45} />
                <line x1={143.98} y1={58.19} x2={143.74} y2={58.05} />
                <line x1={147.19} y1={53.54} x2={147.19} y2={56.33} />
                <line x1={4} y1={24.77} x2={5.03} y2={25.36} />
                <path d="M7.37 26.71l.03.03c.01.01.02.02.02.04v.03" />
                <path d="M3.89 24.77s.01 0 .01-.01c.01 0 .02-.01.03-.01h.02c.02 0 .03.01.05.01 0 0 0 0 0 0" />
                <line x1={7.42} y1={26.8} x2={7.42} y2={26.91} />
                <line x1={0.84} y1={26.52} x2={3.89} y2={24.77} />
                <path d="M.79 26.62c0 .02.02.04.03.05" />
                <path d="M.79 26.62c0-.01 0-.03.01-.04.01-.01.01-.03.02-.04l.02-.02" />
                <line x1={53.7} y1={4.35} x2={53.7} y2={4.35} />
                <line x1={48.22} y1={4.35} x2={48.22} y2={4.35} />
                <path d="M.87 79.4c-.01 0-.02 0-.03.01" />
                <path d="M.81 82.36c0-.01-.01-.01-.01-.02s-.01-.02-.01-.03v-.02s0 0 0 0" />
                <line x1={4.21} y1={84.33} x2={0.84} y2={82.39} />
                <path d="M.79 79.5c0-.01 0-.03.01-.04.01-.01.01-.03.02-.04l.02-.02" />
                <path d="M.87 79.41c0 .06.05.1.09.13" />
                <path d="M4.48 31.41c-.06.03-.13.05-.19.05" />
                <line x1={7.43} y1={26.92} x2={7.42} y2={26.92} />
                <line x1={7.43} y1={26.92} x2={7.42} y2={26.91} />
                <path d="M147.17 109.28c0 .01-.01.01-.02.01" />
                <path d="M147.19 109.21c0 .01 0 .01 0 0v.03c0 .01-.01.02-.02.03" />
                <line x1={147.19} y1={106.43} x2={147.19} y2={109.21} />
                <path d="M147.15 109.29c0 .01-.01.02-.02.02" />
                <line x1={4.05} y1={31.41} x2={0.96} y2={29.63} />
                <path d="M4.24 31.46h.06" />
                <line x1={4.48} y1={31.41} x2={7.39} y2={29.73} />
                <path d="M7.39 29.73c.04-.02.03-.08.03-.14" />
                <line x1={7.23} y1={29.82} x2={7.39} y2={29.73} />
                <line x1={0.79} y1={82.29} x2={0.79} y2={79.5} />
                <line x1={144.25} y1={108.06} x2={147.19} y2={106.36} />
                <line x1={147.19} y1={56.33} x2={147.19} y2={106.43} />
                <line x1={50.93} y1={51.19} x2={99.56} y2={79.52} />
                <line x1={0.82} y1={26.67} x2={0.82} y2={29.35} />
                <line x1={0.87} y1={79.41} x2={0.87} y2={29.54} />
                <path d="M.82 29.35c0 .11.05.22.14.27" />
                <line x1={97.3} y1={135.16} x2={4.26} y2={81.45} />
                <line x1={5.98} y1={80.27} x2={5.98} y2={30.54} />
                <line x1={147.19} y1={56.39} x2={144.09} y2={58.19} />
                <line x1={5.98} y1={80.27} x2={4.26} y2={81.26} />
                <line x1={4.26} y1={81.45} x2={4.26} y2={81.26} />
              </g>
              <g transform="translate(0 2.225)">
                <path d="M5.19 80.8c-.05-.03-.11-.01-.15.02" />
                <line x1={0.96} y1={79.54} x2={4.26} y2={81.45} />
                <line x1={97.25} y1={138.05} x2={4.21} y2={84.33} />
                <line x1={53.85} y1={0.57} x2={142.87} y2={51.96} />
                <line x1={5.03} y1={25.36} x2={48.05} y2={0.52} />
                <line x1={143.77} y1={55.98} x2={50.98} y2={2.78} />
                <line x1={50.93} y1={51.19} x2={50.98} y2={2.78} />
                <line x1={50.96} y1={2.77} x2={7.43} y2={27.9} />
                <line x1={143.77} y1={55.98} x2={140.59} y2={54.14} />
                <line x1={140.59} y1={54.14} x2={140.59} y2={53.28} />
                <line x1={140.59} y1={53.28} x2={143.66} y2={51.5} />
                <path d="M144.09 58.19c-.01 0-.02.01-.03.01-.01 0-.03.01-.04 0-.01 0-.03-.01-.04-.01" />
                <line x1={5.03} y1={25.36} x2={7.37} y2={26.71} />
                <line x1={7.42} y1={26.92} x2={7.42} y2={29.59} />
                <line x1={147.13} y1={109.31} x2={144.09} y2={111.07} />
                <line x1={7.42} y1={26.91} x2={7.42} y2={26.92} />
                <path d="M4.24 31.46c-.06 0-.13-.02-.19-.05" />
                <path d="M143.66 51.5c.01 0 .02-.01.03-.01.01 0 .03-.01.04 0 .01 0 .03.01.04.01" />
                <path d="M147.13 53.45l.03.03c.01.01.02.02.02.04v.03" />
                <line x1={143.77} y1={51.5} x2={147.13} y2={53.45} />
                <line x1={143.98} y1={58.19} x2={143.74} y2={58.05} />
                <line x1={147.19} y1={53.54} x2={147.19} y2={56.33} />
                <line x1={4} y1={24.77} x2={5.03} y2={25.36} />
                <path d="M7.37 26.71l.03.03c.01.01.02.02.02.04v.03" />
                <path d="M3.89 24.77s.01 0 .01-.01c.01 0 .02-.01.03-.01h.02c.02 0 .03.01.05.01 0 0 0 0 0 0" />
                <line x1={7.42} y1={26.8} x2={7.42} y2={26.91} />
                <line x1={0.84} y1={26.52} x2={3.89} y2={24.77} />
                <path d="M.79 26.62c0 .02.02.04.03.05" />
                <path d="M.79 26.62c0-.01 0-.03.01-.04.01-.01.01-.03.02-.04l.02-.02" />
                <line x1={53.7} y1={4.35} x2={53.7} y2={4.35} />
                <line x1={48.22} y1={4.35} x2={48.22} y2={4.35} />
                <path d="M.87 79.4c-.01 0-.02 0-.03.01" />
                <path d="M.81 82.36c0-.01-.01-.01-.01-.02s-.01-.02-.01-.03v-.02s0 0 0 0" />
                <line x1={4.21} y1={84.33} x2={0.84} y2={82.39} />
                <path d="M.79 79.5c0-.01 0-.03.01-.04.01-.01.01-.03.02-.04l.02-.02" />
                <path d="M.87 79.41c0 .06.05.1.09.13" />
                <path d="M4.48 31.41c-.06.03-.13.05-.19.05" />
                <line x1={7.43} y1={26.92} x2={7.42} y2={26.92} />
                <line x1={7.43} y1={26.92} x2={7.42} y2={26.91} />
                <path d="M147.17 109.28c0 .01-.01.01-.02.01" />
                <path d="M147.19 109.21c0 .01 0 .01 0 0v.03c0 .01-.01.02-.02.03" />
                <line x1={147.19} y1={106.43} x2={147.19} y2={109.21} />
                <path d="M147.15 109.29c0 .01-.01.02-.02.02" />
                <line x1={4.05} y1={31.41} x2={0.96} y2={29.63} />
                <path d="M4.24 31.46h.06" />
                <line x1={4.48} y1={31.41} x2={7.39} y2={29.73} />
                <path d="M7.39 29.73c.04-.02.03-.08.03-.14" />
                <line x1={7.23} y1={29.82} x2={7.39} y2={29.73} />
                <line x1={0.79} y1={82.29} x2={0.79} y2={79.5} />
                <line x1={144.25} y1={108.06} x2={147.19} y2={106.36} />
                <line x1={147.19} y1={56.33} x2={147.19} y2={106.43} />
                <line x1={50.93} y1={51.19} x2={99.56} y2={79.52} />
                <line x1={0.82} y1={26.67} x2={0.82} y2={29.35} />
                <line x1={0.87} y1={79.41} x2={0.87} y2={29.54} />
                <path d="M.82 29.35c0 .11.05.22.14.27" />
                <line x1={97.3} y1={135.16} x2={4.26} y2={81.45} />
                <line x1={5.98} y1={80.27} x2={5.98} y2={30.54} />
                <line x1={147.19} y1={56.39} x2={144.09} y2={58.19} />
                <line x1={5.98} y1={80.27} x2={4.26} y2={81.26} />
                <line x1={4.26} y1={81.45} x2={4.26} y2={81.26} />
              </g>
            </g>
            <line
              x1={5.98}
              y1={79.355}
              x2={50.6}
              y2={53.255}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M50.73.015a.108.108 0 00-.11 0c-1.02.59-2.03 1.17-3.05 1.76-.02.01-.03.02-.04.04-.01.02-.02.03-.02.05v1.2c-14.16 8.18-28.32 16.35-42.49 24.53-.34-.2-.68-.4-1.03-.59 0 0 0 0 0 0-.01-.01-.03-.01-.05-.01h-.02c-.01 0-.02 0-.03.01 0 0-.01 0-.01.01-1.02.59-2.03 1.17-3.05 1.76-.01 0-.02.01-.02.02-.01.01-.02.02-.02.04-.01.01-.01.03-.01.04 0 .02.02.04.03.05v2.68c0 .07.02.13.05.19v49.87c-.01 0-.02 0-.03.01-.01 0-.02.01-.02.02-.01.01-.02.02-.02.04-.01.01-.01.03-.01.04v2.79s0 0 0 0v.02c0 .01.01.02.01.03 0 .01.01.01.01.02l.03.03c1.12.65 2.24 1.29 3.36 1.94 31.01 17.91 62.03 35.81 93.04 53.72 0 0 0 0 0 0 .01.01.03.01.05.01h.02c.01 0 .02 0 .03-.01 0 0 .01 0 .01-.01 1.01-.58 2.01-1.16 3.02-1.74.04-.02.08-.05.12-.07v-1.42c.82-.48 1.65-.95 2.47-1.43.41.24.82.47 1.23.71a112974.37 112974.37 0 0135.75-20.64c.05-.03.11-.06.16-.09v-1.42c.87-.5 1.73-1 2.6-1.5.41.24.82.47 1.23.71.05-.03.11-.06.16-.09 1.02-.59 2.03-1.17 3.05-1.76l.02-.02c.01 0 .01 0 .02-.01s.01-.02.02-.03v-.02-.01-2.79-50.1-2.79-.03c0-.01-.01-.03-.02-.04l-.03-.03c-1.12-.65-2.24-1.29-3.36-1.94-.01-.01-.03-.01-.04-.01h-.04c-.01 0-.02.01-.03.01-.26.15-.53.31-.79.46-29.57-17.07-59.14-34.15-88.72-51.22v-.92c0-.01 0-.01-.01-.02 0-.01-.01-.02-.01-.03 0-.01-.01-.02-.02-.03l-.01-.01h-.01s0 0 0 0c-1.13-.69-2.25-1.34-3.37-1.98z"
              stroke="#FFF"
              strokeWidth={1.1634}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g name="XMLID_1_" visibility={props.level >= 0 ? 'visible' : 'hidden'}>
            <g fillRule="nonzero">
              <path
                d="M52.72 44.44v2.69c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.92h.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M52.72 38.34v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14a3.712 3.712 0 01.05.65z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M52.72 33.15v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33a.585.585 0 01-.02-.13c.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.12.01.23.01.34z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M52.72 27.96v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 005.05.13 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.23.01.34z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M52.72 22.76v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.12.01.23.01.34z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M52.72 17.57v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33V21.7c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M52.72 12.38v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13a3.712 3.712 0 01.05.66z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M52.72 7.18v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33a3.88 3.88 0 00-.17-.66.185.185 0 01-.02-.07c-.07-.2-.15-.39-.25-.58-.11-.22-.24-.42-.38-.63a7.126 7.126 0 00-.76-.88c-.09-.09-.19-.18-.3-.27-.1-.09-.21-.17-.32-.26-.11-.08-.22-.17-.34-.25-.33-.23-.7-.45-1.08-.65-.58-.3-1.2-.56-1.87-.78-.01 0-.02-.01-.03-.01V7.2c0-.04.01-.17.01-.21 0-.07.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43c.68-.04 1.36-.04 2.04.01.33.02.66.05.99.09.64.08 1.26.19 1.86.33.38.09.75.19 1.11.31.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.31.27.58.55.83.85a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07.04.11.07.22.1.33a3.712 3.712 0 01.12.97zm-3.85-.07V7c0-.58-.22-1.1-.56-1.55-.34-.45-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.14-1.09-1.1-.17-2.24-.18-3.34-.03-1.08.15-2.21.48-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.62 1.01-.62 1.62v.12c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.24.23.5.44.78.61.03.02.05.03.08.05.04.02.07.04.11.07.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.48 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.54-.38.78-.61.04-.04.08-.08.11-.12.08-.08.15-.16.22-.25.37-.43.6-.94.63-1.51 0 .01 0 .01 0 0z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M48.87 7.11c0 .01 0 .01 0 0-.03.56-.27 1.07-.61 1.51-.07.09-.14.17-.22.25-.64-.68-1.54-1.14-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.81.17-.91.17-1.84.47-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43V7.1c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07.95-.55 2.07-.87 3.14-1.02 1.09-.16 2.22-.15 3.31.01 1.07.16 2.19.5 3.13 1.06.47.28.92.63 1.26 1.06.35.44.59.95.62 1.51z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M48.04 8.87c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.51-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.81-.17.93.06 1.88.23 2.77.54.85.3 1.75.77 2.39 1.45z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M47.93 8.98c-.24.23-.5.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.46-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.28 1.74.72 2.4 1.36z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M47.15 9.6c-.06.04-.13.08-.19.12-.71-.42-1.51-.7-2.3-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26s-1.6.46-2.31.87c-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.06-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.79.2 1.61.52 2.32.97z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M46.96 9.71c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.25.14-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.01-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.26 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.31.88z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M37.17 52.84v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33a3.88 3.88 0 01-.17.66c-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M37.17 47.64v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13a3.712 3.712 0 01.05.66v.32z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M37.17 42.45v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M37.17 37.26v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33 0-.04.01-.08.01-.12 0-.07.01-.14.01-.21v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.3h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M37.17 32.06v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.04.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14a3.712 3.712 0 01.05.66v.31z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M37.17 26.87v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14a3.712 3.712 0 01.05.66v.31z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M37.17 21.68v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M35.53 12.68c.19.18.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07a3.88 3.88 0 01.17.66c.02.11.03.22.04.33.01.11.01.22.01.33V19.76c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.02-.11-.04-.22-.07-.33-.03-.11-.06-.22-.1-.33a.185.185 0 01-.02-.07c-.07-.2-.15-.39-.25-.58-.11-.22-.24-.42-.38-.63a7.126 7.126 0 00-.76-.88c-.09-.09-.19-.18-.3-.27-.1-.09-.21-.18-.32-.26-.11-.08-.22-.17-.34-.25-.33-.23-.7-.45-1.08-.65-.58-.3-1.2-.56-1.87-.78-.01 0-.02-.01-.03-.01v-3.28c0-.04.01-.16.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.79 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43 17.356 17.356 0 013.03.1 15.682 15.682 0 012.94.63c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.08.22.17.32.26.11.09.21.18.3.27zm-13.91 5.9c.03.02.05.03.08.05.04.02.07.04.11.07.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.47 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.55-.38.78-.61.04-.04.08-.08.11-.12.45-.48.79-1.07.83-1.75v-.01-.11c0-.57-.22-1.1-.56-1.55-.34-.45-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.15-1.09-1.09-.17-2.24-.18-3.34-.03-1.08.15-2.21.47-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.61 1.01-.61 1.62v.11c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.26.24.53.44.8.62z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M33.32 16.09s0 .01 0 0c-.03.69-.38 1.28-.83 1.76-.64-.68-1.54-1.15-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.82.17-.91.17-1.84.48-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43.03-.58.29-1.11.65-1.54.36-.44.83-.8 1.31-1.08.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.36.44.6.95.63 1.51z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M32.49 17.85c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.5-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.84.29 1.74.76 2.38 1.44z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M32.38 17.97c-.24.23-.5.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.46-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.23 2.77-.18.92.05 1.86.21 2.73.5.85.28 1.73.73 2.4 1.37z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M31.59 18.58c-.06.04-.13.08-.19.12-.71-.42-1.51-.7-2.3-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26-.79.18-1.6.46-2.31.87-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.79.2 1.61.52 2.32.97z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M31.4 18.69c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.01-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.8.18 1.6.47 2.31.88z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M21.61 61.82v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a16.638 16.638 0 01-2.99-.43c-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24l-.06-.3c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.17.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.63-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M21.61 56.62v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.03-.16-.04-.31-.05-.47 0-.07-.01-.13-.01-.2v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.17.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.63-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.32h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M21.61 51.43v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M21.61 46.24v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M21.61 41.05v3.28c0 .07 0 .14-.01.21 0 .04 0 .08-.01.12-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M21.61 35.85v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M21.61 30.66v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 005.05.13c.63-.08 1.26-.18 1.86-.33.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.32h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M19.98 21.66c.19.19.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07a4.143 4.143 0 01.17.66c.02.11.03.22.04.33.01.11.01.22.01.33V28.74c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.17.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3.32-.08.64-.15.97-.2a17.356 17.356 0 016.91.2c.37.09.73.19 1.08.3.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.09.1.19.19.28.28zM6.26 27.67c.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.47 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.55-.38.78-.61.04-.04.08-.08.11-.12.08-.08.15-.16.22-.25.34-.43.58-.94.61-1.5v-.11c0-.58-.22-1.1-.56-1.55-.34-.45-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.15-1.09-1.09-.17-2.24-.18-3.34-.03-1.08.15-2.21.47-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.61 1.01-.61 1.62v.12c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.24.23.5.44.78.61.03.02.05.03.08.05.05.02.09.04.13.06z"
                fill="#717170"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M17.76 25.08c-.03.56-.27 1.07-.61 1.51-.07.09-.14.17-.22.25-.64-.68-1.54-1.14-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.82.17-.91.17-1.84.47-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.32-.42-.53-.9-.56-1.43v-.01c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.36.43.6.94.63 1.51z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M16.94 26.83c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.51-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.84.3 1.73.77 2.38 1.45z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M16.82 26.95c-.24.23-.51.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.47-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.27 1.74.72 2.4 1.36z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M16.04 27.56c-.06.04-.13.08-.19.12-.71-.42-1.51-.71-2.31-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26-.79.18-1.6.46-2.31.87-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.8.2 1.62.52 2.33.97z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
              <path
                d="M15.85 27.68c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.02-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.79.17 1.6.46 2.31.88z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(13 18) translate(1)"
              />
            </g>
            <g
              stroke="#444"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.8725}
              transform="translate(.76 1.37) translate(13 18)"
            >
              <path d="M4.76 33.44a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M20.31 11.47c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43 17.356 17.356 0 013.03.1 15.682 15.682 0 012.94.63" />
              <path d="M17.05 16.16c0-.04.01-.16.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.79 1.42-1.43 2.27-1.93" />
              <path d="M20.9 16.09c.03-.58.29-1.11.65-1.54.36-.44.83-.8 1.31-1.08" />
              <path d="M21.84 17.97c.3-.3.65-.55 1.02-.76" />
              <path d="M21.73 17.85c.33-.35.72-.64 1.13-.87" />
              <line x1={53.72} y1={27.96} x2={53.72} y2={31.56} />
              <path d="M53.72 31.56c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <line x1={53.72} y1={22.76} x2={53.72} y2={26.36} />
              <path d="M53.72 26.36c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M53.64 27.16c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={36.4} y1={23.38} x2={36.41} y2={23.39} />
              <line x1={53.72} y1={43.53} x2={53.72} y2={44.44} />
              <path d="M53.72 47.14c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <line x1={38.17} y1={52.52} x2={38.17} y2={52.84} />
              <path d="M38.17 56.12c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33a3.88 3.88 0 01-.17.66c-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M38.17 50.92c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <line x1={53.72} y1={38.34} x2={53.72} y2={41.94} />
              <path d="M53.72 41.94c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M53.64 42.74c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M38.09 51.72c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={53.72} y1={44.44} x2={53.72} y2={47.14} />
              <line x1={38.17} y1={52.84} x2={38.17} y2={56.12} />
              <path d="M53.64 42.74c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <line x1={53.72} y1={17.57} x2={53.72} y2={21.17} />
              <path d="M53.72 21.17c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M1.49 35.53c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.49} y1={39.13} x2={1.49} y2={35.53} />
              <path d="M22.61 39.13c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <line x1={53.72} y1={33.15} x2={53.72} y2={36.75} />
              <path d="M53.72 36.75c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M1.49 45.92c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.49} y1={49.52} x2={1.49} y2={45.92} />
              <path d="M4.76 54.21A8.22 8.22 0 013.13 53c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M38.41 9.69c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.26 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.3.89" />
              <path d="M38.41 9.45c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96" />
              <line x1={53.72} y1={12.38} x2={53.72} y2={15.98} />
              <path d="M53.72 15.98c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M53.64 16.77c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M53.64 21.97c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={20.84} y1={42.74} x2={20.85} y2={42.75} />
              <path d="M53.64 37.55c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M53.64 37.55c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M53.64 32.35c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.86 16.98c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.86.3 1.75.77 2.4 1.45" />
              <path d="M22.86 13.47c.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M22.86 13.24c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.15 3.34.03 1.07.17 2.21.51 3.15 1.09.47.29.91.65 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M53.64 32.35c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M53.64 27.16c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M38.09 51.72c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M53.64 21.97c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M1.49 51.11c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.49} y1={54.71} x2={1.49} y2={51.11} />
              <path d="M22.61 54.71c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M38.17 45.73c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M1.49 61.5c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.49} y1={65.1} x2={1.49} y2={61.5} />
              <path d="M22.61 65.1c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a16.638 16.638 0 01-2.99-.43c-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.76 69.79a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24l-.06-.3c-.04-.22-.06-.45-.06-.67" />
              <line x1={1.49} y1={59.9} x2={1.49} y2={56.3} />
              <path d="M22.61 59.9c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13" />
              <path d="M4.76 64.6a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M22.54 60.7c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.57 60.7a.585.585 0 01-.02-.13c-.03-.16-.04-.31-.05-.47" />
              <path d="M22.54 55.51c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M7.31 27.65c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.31.89h0" />
              <path d="M7.31 27.41c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96" />
              <path d="M1.49 30.34c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.49} y1={33.94} x2={1.49} y2={30.34} />
              <path d="M22.61 33.94c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M22.86 18.67c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.3.89" />
              <path d="M22.86 18.43c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96 0 0 0 0 0 0" />
              <path d="M38.17 24.96c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <line x1={20.84} y1={37.55} x2={20.85} y2={37.56} />
              <path d="M38.17 35.35c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M1.49 40.72c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.49} y1={44.33} x2={1.49} y2={40.72} />
              <path d="M22.61 44.33c0 .07 0 .14-.01.21" />
              <path d="M38.41 7.99c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.81-.17.93.06 1.88.23 2.77.54.86.3 1.76.77 2.4 1.45" />
              <path d="M38.41 4.49c.95-.55 2.07-.87 3.14-1.02 1.09-.16 2.22-.15 3.31.01 1.07.16 2.19.5 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M38.41 8.23c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.29 1.74.73 2.4 1.37" />
              <path d="M38.41 4.26c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.14 3.34.03 1.07.17 2.2.51 3.14 1.09.47.29.92.66 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M36.45 7c0-.61.25-1.16.62-1.62.37-.46.85-.83 1.35-1.12" />
              <path d="M35.87 2.49c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43c.68-.04 1.36-.04 2.04.01.33.02.66.05.99.09.64.08 1.26.19 1.86.33.38.09.75.19 1.11.31.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.31.27.58.55.83.85a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07.04.11.07.22.1.33a3.712 3.712 0 01.12.99" />
              <path d="M32.6 7.18c0-.04.01-.17.01-.21 0-.07.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93" />
              <line x1={53.72} y1={7.18} x2={53.72} y2={10.79} />
              <path d="M53.72 10.79c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M36.45 7.11c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07" />
              <path d="M37.4 8.98c.3-.3.65-.55 1.02-.76" />
              <path d="M37.28 8.87c.33-.35.72-.64 1.13-.87" />
              <path d="M5.34 24.96c0-.61.25-1.16.61-1.62.37-.46.85-.83 1.35-1.12" />
              <path d="M4.76 20.45c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3.32-.08.64-.15.97-.2a17.356 17.356 0 016.91.2c.37.09.73.19 1.08.3" />
              <path d="M1.49 25.15c0-.04.01-.17.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93" />
              <line x1={1.49} y1={28.75} x2={1.49} y2={25.15} />
              <path d="M5.34 25.07c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07" />
              <path d="M6.29 26.95c.3-.3.65-.55 1.02-.76" />
              <path d="M6.17 26.83c.33-.35.72-.64 1.13-.87" />
              <path d="M7.07 27.56c.08-.05.16-.1.24-.14" />
              <path d="M7.26 27.68s0 0 0 0c.02-.01.03-.02.05-.03" />
              <path d="M22.54 29.54c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M20.98 21.66c-.01.01-.02.01-.03.02" />
              <path d="M1.57 29.55a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <line x1={17.05} y1={19.44} x2={17.05} y2={16.16} />
              <path d="M38.09 36.14c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.54 34.74c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.57 50.32a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67" />
              <path d="M38.18 9.6c.08-.05.16-.1.24-.14" />
              <path d="M53.64 11.58c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M38.37 9.71c.01-.01.03-.02.05-.03" />
              <path d="M1.57 39.93a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67" />
              <path d="M38.09 46.53c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M38.09 25.75c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.54 45.12c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M36.53 12.68c-.01.01-.02.01-.03.02" />
              <path d="M38.09 46.53c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M1.5 60.1c0-.07-.01-.13-.01-.2" />
              <path d="M22.62 18.58h0c.08-.05.16-.1.24-.14" />
              <path d="M38.09 20.56c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.81 18.69c.01-.01.03-.02.05-.03" />
              <line x1={32.6} y1={10.46} x2={32.6} y2={7.18} />
              <path d="M53.64 11.58c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M22.54 50.31c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.57 34.74a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <path d="M38.09 41.33c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.57 45.12a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <path d="M38.09 30.95c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.54 39.93c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.86 17.21c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.23 2.77-.18.92.05 1.86.21 2.73.5.85.29 1.74.73 2.4 1.37" />
              <path d="M32.35 18.72c-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43 0 0 0 0 0 0v-.11" />
              <path d="M34.32 15.98v.12c-.03.68-.38 1.28-.83 1.75-.04.04-.08.08-.11.12" />
              <path d="M33.38 17.97c-.24.23-.5.43-.78.61-.06.04-.13.08-.19.12h0c-.02.01-.03.02-.05.03" />
              <path d="M32.6 10.46c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.08.22.17.32.26l.3.27c.19.18.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07a4.143 4.143 0 01.17.66c.02.11.03.22.04.33.01.11.01.22.01.33" />
              <polyline points="38.17 16.16 38.17 16.49 38.17 19.77" />
              <path d="M38.17 19.77c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="38.17 47.32 38.17 47.64 38.17 50.92" />
              <polyline points="38.17 26.55 38.17 26.87 38.17 30.15" />
              <path d="M38.17 30.15c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.61 35.53 22.61 35.85 22.61 39.13" />
              <path d="M22.54 39.93c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.76 43.83a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <polyline points="38.17 36.94 38.17 37.26 38.17 40.54" />
              <path d="M38.17 40.54c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.61 45.92 22.61 46.24 22.61 49.52" />
              <path d="M22.61 49.52c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M22.54 50.31c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M53.64 16.77c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.61 51.11 22.61 51.43 22.61 54.71" />
              <path d="M22.54 55.51c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.76 59.41a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M1.57 55.51a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <polyline points="38.17 42.13 38.17 42.45 38.17 45.73" />
              <polyline points="22.61 61.5 22.61 61.82 22.61 65.1" />
              <path d="M1.49 56.3c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <polyline points="22.61 56.3 22.61 56.62 22.61 59.9" />
              <path d="M22.54 60.7c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <polyline points="22.61 30.34 22.61 30.66 22.61 33.94" />
              <path d="M22.54 34.74c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.76 38.63a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <polyline points="38.17 21.36 38.17 21.68 38.17 24.96" />
              <path d="M38.09 25.75c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="38.17 31.74 38.17 32.06 38.17 35.35" />
              <path d="M38.09 36.14c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.61 40.72 22.61 41.05 22.61 44.33" />
              <path d="M22.61 44.53c0 .04 0 .08-.01.12-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13 0 0 0 0 0 0-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.76 49.02a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M47.91 9.74c-.96.55-2.09.88-3.17 1.03-1.1.15-2.25.14-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.06-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43V7.1v-.11" />
              <path d="M49.88 7v.11s0 0 0 0c-.03.56-.27 1.07-.61 1.5-.07.09-.14.17-.22.25-.04.04-.08.08-.11.12-.24.23-.5.43-.78.61-.06.04-.13.08-.19.12h0c-.02.01-.03.02-.05.03" />
              <path d="M7.31 25.96c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.86.3 1.75.77 2.4 1.45" />
              <path d="M7.31 22.45c.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M7.31 26.19c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.29 1.74.73 2.4 1.37" />
              <path d="M7.31 22.22c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.14 3.34.03 1.07.17 2.21.51 3.15 1.09.47.29.91.65 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M16.8 27.7c-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.32-.42-.53-.9-.56-1.43v-.01-.11" />
              <path d="M18.77 24.96v.11s0 0 0 0c-.03.56-.27 1.07-.61 1.5-.07.09-.14.17-.22.25-.04.04-.08.08-.11.12-.24.23-.51.43-.78.61-.06.04-.13.08-.19.12-.02.01-.03.02-.05.03" />
              <path d="M17.05 19.44c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26l.3.27a7.126 7.126 0 01.76.88 5.016 5.016 0 01.63 1.21c.01.02.02.05.02.07a3.88 3.88 0 01.21.99c.01.11.01.22.01.33" />
              <polyline points="22.61 25.15 22.61 25.47 22.61 28.75" />
              <path d="M22.61 28.75c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M20.89 15.98c0-.61.25-1.16.61-1.62.37-.46.85-.83 1.35-1.12" />
            </g>
          </g>
          <g name="XMLID_2_" visibility={props.level >= 1 ? 'visible' : 'hidden'}>
            <g fillRule="nonzero">
              <path
                d="M53.17 44.36v2.69c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33a3.88 3.88 0 01-.17.66c-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.92h.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M53.17 38.26v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33 0 .1.01.21.01.32z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M53.17 33.07v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33a.585.585 0 01-.02-.13c.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33 0 .12.01.22.01.34z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M53.17 27.87v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 005.05.13 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33 0 .12.01.23.01.34z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M53.17 22.68v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33V26.8c.33.11.68.21 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33 0 .12.01.23.01.34z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M53.17 17.49v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33 0 .11.01.22.01.33z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M53.17 12.29v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33 0 .11.01.22.01.33z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M53.17 7.1v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.02-.11-.04-.22-.07-.33-.03-.11-.06-.22-.1-.33a.185.185 0 01-.02-.07c-.07-.2-.15-.39-.25-.58-.11-.22-.24-.42-.38-.63a7.126 7.126 0 00-.76-.88c-.09-.09-.19-.18-.3-.27-.1-.09-.21-.17-.32-.26-.11-.08-.22-.17-.34-.25-.33-.23-.7-.45-1.08-.65-.58-.3-1.2-.56-1.87-.78-.01 0-.02-.01-.03-.01V7.12c0-.04.01-.17.01-.21 0-.07.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43c.68-.04 1.36-.04 2.04.01.33.02.66.05.99.09.64.08 1.26.19 1.86.33.38.09.75.19 1.11.31.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.31.27.58.55.83.85a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07a4.143 4.143 0 01.17.66c.02.11.03.22.04.33 0 .09.01.2.01.31zm-3.86-.07v-.11c0-.58-.22-1.1-.56-1.55-.34-.45-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.14-1.09-1.1-.17-2.24-.18-3.34-.03-1.08.15-2.21.48-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.62 1.01-.62 1.62v.12c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.24.23.5.44.78.61.03.02.05.03.08.05.04.02.07.04.11.07.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.48 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.54-.38.78-.61.04-.04.08-.08.11-.12.08-.08.15-.16.22-.25.37-.44.61-.95.63-1.51z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M49.31 7.03c-.03.56-.27 1.07-.61 1.51-.07.09-.14.17-.22.25-.64-.68-1.54-1.14-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.81.17-.91.17-1.84.47-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43v-.01c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07.95-.55 2.07-.87 3.14-1.02 1.09-.16 2.22-.15 3.31.01 1.07.16 2.19.5 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M48.49 8.78c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.51-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.81-.17.93.06 1.88.23 2.77.54.85.31 1.74.78 2.39 1.45z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M48.37 8.9c-.24.23-.5.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.46-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.86.28 1.74.72 2.4 1.36z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M47.59 9.51c-.06.04-.13.08-.19.12-.71-.42-1.51-.7-2.3-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26s-1.6.46-2.31.87c-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.06-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.79.21 1.61.52 2.32.97z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M47.4 9.63c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.25.14-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.01-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.26 2.46-.26.82 0 1.66.09 2.46.28.8.18 1.6.46 2.31.88z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M37.61 52.75v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M37.61 47.56v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M37.61 42.37v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M37.61 37.17v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33 0-.04.01-.08.01-.12 0-.07.01-.14.01-.21v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.3h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M37.61 31.98v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a16.638 16.638 0 01-2.99-.43c-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.04.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M37.61 26.79v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M37.61 21.6v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M35.98 12.6c.19.18.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07.04.11.07.22.1.33a3.712 3.712 0 01.11.66c.01.11.01.22.01.33V19.68c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.02-.11-.04-.22-.07-.33-.03-.11-.06-.22-.1-.33a.185.185 0 01-.02-.07c-.07-.2-.15-.39-.25-.58-.11-.22-.24-.42-.38-.63a7.126 7.126 0 00-.76-.88c-.09-.09-.19-.18-.3-.27-.1-.09-.21-.18-.32-.26-.11-.08-.22-.17-.34-.25-.33-.23-.7-.45-1.08-.65-.58-.3-1.2-.56-1.87-.78-.01 0-.02-.01-.03-.01v-3.28c0-.04.01-.16.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.79 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43 17.356 17.356 0 013.03.1 15.682 15.682 0 012.94.63c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.08.22.17.32.26.1.08.2.17.3.27zm-13.91 5.89c.03.02.05.03.08.05.04.02.07.04.11.07.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.47 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.55-.38.78-.61.04-.04.08-.08.11-.12.45-.48.79-1.07.83-1.75V16v-.11c0-.57-.22-1.1-.56-1.55-.34-.45-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.15-1.09-1.09-.17-2.24-.18-3.34-.03-1.08.15-2.21.47-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.61 1.01-.61 1.62V16c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.25.24.52.45.8.62z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M33.76 16.01c-.03.69-.38 1.28-.83 1.76-.64-.68-1.54-1.15-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.82.17-.91.17-1.84.48-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43.03-.58.29-1.11.65-1.54.36-.44.83-.8 1.31-1.08.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.36.43.6.94.63 1.51z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M32.93 17.77c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.5-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.84.29 1.74.76 2.38 1.44z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M32.82 17.88c-.24.23-.5.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.46-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.23 2.77-.18.92.05 1.86.21 2.73.5.85.29 1.74.73 2.4 1.37z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M32.03 18.49c-.06.04-.13.08-.19.12-.71-.42-1.51-.7-2.3-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26-.79.18-1.6.46-2.31.87-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.8.21 1.62.52 2.32.97z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M31.84 18.61c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.01-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.8.18 1.61.46 2.31.88z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M22.06 61.73v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24l-.06-.3c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.17.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.63-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M22.06 56.54v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.03-.16-.04-.31-.05-.47 0-.07-.01-.13-.01-.2v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.17.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.63-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.32h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M22.06 51.35v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M22.06 46.15v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M22.06 40.96v3.28c0 .07 0 .14-.01.21 0 .04 0 .08-.01.12-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M22.06 35.77v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M22.06 30.58v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 005.05.13c.63-.08 1.26-.18 1.86-.33.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.32h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M20.42 21.58a7.126 7.126 0 01.76.88 5.016 5.016 0 01.63 1.21c.01.02.02.05.02.07a3.88 3.88 0 01.21.99c.01.11.01.22.01.33V28.66c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.17.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3.32-.08.64-.15.97-.2a17.356 17.356 0 016.91.2c.37.09.73.19 1.08.3.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.08.22.17.32.26.09.1.19.19.28.28zM6.7 27.59c.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.47 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.55-.38.78-.61.04-.04.08-.08.11-.12.08-.08.15-.16.22-.25.34-.43.58-.94.61-1.5v-.11c0-.58-.22-1.1-.56-1.55-.34-.45-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.15-1.09-1.09-.17-2.24-.18-3.34-.03-1.08.15-2.21.47-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.61 1.01-.61 1.62V25c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.24.23.5.44.78.61.03.02.05.03.08.05.06.02.09.04.13.06z"
                fill="#717170"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M18.21 24.99c-.03.56-.27 1.07-.61 1.51-.07.09-.14.17-.22.25-.64-.68-1.54-1.14-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.82.17-.91.17-1.84.47-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.32-.42-.53-.9-.56-1.43v-.01c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.36.43.6.94.63 1.51z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M17.38 26.74c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.51-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.84.31 1.73.78 2.38 1.45z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M17.26 26.86c-.24.23-.51.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.47-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.86.28 1.74.72 2.4 1.36z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M16.48 27.47c-.06.04-.13.08-.19.12-.71-.42-1.51-.71-2.31-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26-.79.18-1.6.46-2.31.87-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.8.21 1.62.52 2.33.97z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
              <path
                d="M16.29 27.59c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.02-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.8.18 1.6.46 2.31.88z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(29 27)"
              />
            </g>
            <g
              stroke="#444"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.8725}
              transform="translate(.76 1.37) translate(29 27)"
            >
              <path d="M4.2 33.36a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M19.76 11.38c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43 17.356 17.356 0 013.03.1 15.682 15.682 0 012.94.63" />
              <path d="M16.49 16.08c0-.04.01-.16.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.79 1.42-1.43 2.27-1.93" />
              <path d="M20.34 16.01c.03-.58.29-1.11.65-1.54.36-.44.83-.8 1.31-1.08" />
              <path d="M21.28 17.88c.3-.3.65-.55 1.02-.76" />
              <path d="M21.17 17.77c.33-.35.72-.64 1.13-.87" />
              <line x1={53.17} y1={27.87} x2={53.17} y2={31.47} />
              <path d="M53.17 31.47c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <line x1={53.17} y1={22.68} x2={53.17} y2={26.28} />
              <path d="M53.17 26.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M53.09 27.08c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={35.84} y1={23.29} x2={35.85} y2={23.3} />
              <line x1={53.17} y1={43.45} x2={53.17} y2={44.36} />
              <path d="M53.17 47.05c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33a3.88 3.88 0 01-.17.66c-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <line x1={37.61} y1={52.43} x2={37.61} y2={52.75} />
              <path d="M37.61 56.03c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M37.61 50.84c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <line x1={53.17} y1={38.26} x2={53.17} y2={41.86} />
              <path d="M53.17 41.86c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M53.09 42.65c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M37.53 51.64c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={53.17} y1={44.36} x2={53.17} y2={47.05} />
              <line x1={37.61} y1={52.75} x2={37.61} y2={56.03} />
              <path d="M53.09 42.65c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <line x1={53.17} y1={17.49} x2={53.17} y2={21.09} />
              <path d="M53.17 21.09c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M.93 35.45c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={0.93} y1={39.05} x2={0.93} y2={35.45} />
              <path d="M22.06 39.05c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <line x1={53.17} y1={33.07} x2={53.17} y2={36.67} />
              <path d="M53.17 36.67c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M.93 45.83c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={0.93} y1={49.43} x2={0.93} y2={45.83} />
              <path d="M4.2 54.13a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M37.86 9.6c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.26 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.3.89" />
              <path d="M37.86 9.37c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96" />
              <line x1={53.17} y1={12.29} x2={53.17} y2={15.89} />
              <path d="M53.17 15.89c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M53.09 16.69c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M53.09 21.88c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={20.29} y1={42.66} x2={20.3} y2={42.67} />
              <path d="M53.09 37.46c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M53.09 37.46c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M53.09 32.27c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.3 16.89c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.86.3 1.75.77 2.4 1.45" />
              <path d="M22.3 13.39c.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M22.3 13.16c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.15 3.34.03 1.07.17 2.21.51 3.15 1.09.47.29.91.65 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M53.09 32.27c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M53.09 27.08c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M37.53 51.64c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M53.09 21.88c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M.93 51.02c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={0.93} y1={54.63} x2={0.93} y2={51.02} />
              <path d="M22.06 54.63c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M37.61 45.65c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M.93 61.41c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={0.93} y1={65.01} x2={0.93} y2={61.41} />
              <path d="M22.06 65.01c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.2 69.71a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24l-.06-.3c-.04-.22-.06-.45-.06-.67" />
              <line x1={0.93} y1={59.82} x2={0.93} y2={56.22} />
              <path d="M22.06 59.82c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13" />
              <path d="M4.2 64.52a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M21.98 60.62c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.01 60.62a.585.585 0 01-.02-.13c-.03-.16-.04-.31-.05-.47" />
              <path d="M21.98 55.42c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M6.75 27.56c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.31.89h0" />
              <path d="M6.75 27.33c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96" />
              <path d="M.93 30.25c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={0.93} y1={33.85} x2={0.93} y2={30.25} />
              <path d="M22.06 33.85c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M22.3 18.58c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.3.89" />
              <path d="M22.3 18.35c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96 0 0 0 0 0 0" />
              <path d="M37.61 24.87c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <line x1={20.29} y1={37.47} x2={20.3} y2={37.48} />
              <path d="M37.61 35.26c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M.93 40.64c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={0.93} y1={44.24} x2={0.93} y2={40.64} />
              <path d="M22.06 44.24c0 .07 0 .14-.01.21" />
              <path d="M37.86 7.91c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.81-.17.93.06 1.88.23 2.77.54.86.3 1.76.77 2.4 1.45" />
              <path d="M37.86 4.41c.95-.55 2.07-.87 3.14-1.02 1.09-.16 2.22-.15 3.31.01 1.07.16 2.19.5 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M37.86 8.14c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.29 1.74.73 2.4 1.37" />
              <path d="M37.86 4.18c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.14 3.34.03 1.07.17 2.2.51 3.14 1.09.47.29.92.66 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M35.89 6.92c0-.61.25-1.16.62-1.62.37-.46.85-.83 1.35-1.12" />
              <path d="M35.31 2.4c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43c.68-.04 1.36-.04 2.04.01.33.02.66.05.99.09.64.08 1.26.19 1.86.33.38.09.75.19 1.11.31.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.31.27.58.55.83.85a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07a4.143 4.143 0 01.17.66c.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M32.04 7.1c0-.04.01-.17.01-.21 0-.07.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93" />
              <line x1={53.17} y1={7.1} x2={53.17} y2={10.7} />
              <path d="M53.17 10.7c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M35.89 7.02c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07" />
              <path d="M36.84 8.9c.3-.3.65-.55 1.02-.76" />
              <path d="M36.72 8.78c.33-.35.72-.64 1.13-.87" />
              <path d="M4.78 24.88c0-.61.25-1.16.61-1.62.37-.46.85-.83 1.35-1.12" />
              <path d="M4.2 20.37c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3.32-.08.64-.15.97-.2a17.356 17.356 0 016.91.2c.37.09.73.19 1.08.3" />
              <path d="M.93 25.06c0-.04.01-.17.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93" />
              <line x1={0.93} y1={28.66} x2={0.93} y2={25.06} />
              <path d="M4.78 24.99c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07" />
              <path d="M5.73 26.86c.3-.3.65-.55 1.02-.76" />
              <path d="M5.61 26.74c.33-.35.72-.64 1.13-.87" />
              <path d="M6.51 27.47c.08-.05.16-.1.24-.14" />
              <path d="M6.7 27.59s0 0 0 0c.02-.01.03-.02.05-.03" />
              <path d="M21.98 29.46c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M20.42 21.58c-.01.01-.02.01-.03.02" />
              <path d="M1.01 29.46a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <line x1={16.49} y1={19.36} x2={16.49} y2={16.08} />
              <path d="M37.53 36.06c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M21.98 34.65c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.01 50.23a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <path d="M37.62 9.51c.08-.05.16-.1.24-.14" />
              <path d="M53.09 11.5c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M37.81 9.63c.01-.01.03-.02.05-.03" />
              <path d="M1.01 39.85a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <path d="M37.53 46.44c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M37.53 25.67c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M21.98 45.04c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M35.98 12.6c-.01.01-.02.01-.03.02" />
              <path d="M37.53 46.44c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M.94 60.02c0-.07-.01-.13-.01-.2" />
              <path d="M22.07 18.49h0c.08-.05.16-.1.24-.14" />
              <path d="M37.53 20.48c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.26 18.61c.01-.01.03-.02.05-.03" />
              <line x1={32.04} y1={10.38} x2={32.04} y2={7.1} />
              <path d="M53.09 11.5c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M21.98 50.23c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.01 34.65a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <path d="M37.53 41.25c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.01 45.04a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <path d="M37.53 30.86c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M21.98 39.84c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.3 17.12c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.23 2.77-.18.92.05 1.86.21 2.73.5.85.29 1.74.73 2.4 1.37" />
              <path d="M31.8 18.64c-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43 0 0 0 0 0 0v-.11" />
              <path d="M33.76 15.9v.12c-.03.68-.38 1.28-.83 1.75-.04.04-.08.08-.11.12" />
              <path d="M32.82 17.88c-.24.23-.5.43-.78.61-.06.04-.13.08-.19.12h0c-.02.01-.03.02-.05.03" />
              <path d="M32.04 10.38c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.08.22.17.32.26l.3.27c.19.18.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07.04.11.07.22.1.33a3.712 3.712 0 01.11.66c.01.11.01.22.01.33" />
              <polyline points="37.61 16.08 37.61 16.4 37.61 19.68" />
              <path d="M37.61 19.68c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="37.61 47.24 37.61 47.56 37.61 50.84" />
              <polyline points="37.61 26.47 37.61 26.79 37.61 30.07" />
              <path d="M37.61 30.07c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.06 35.45 22.06 35.77 22.06 39.05" />
              <path d="M21.98 39.84c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.2 43.74a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <polyline points="37.61 36.85 37.61 37.17 37.61 40.45" />
              <path d="M37.61 40.45c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.06 45.83 22.06 46.15 22.06 49.43" />
              <path d="M22.06 49.43c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M21.98 50.23c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M53.09 16.69c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.06 51.02 22.06 51.35 22.06 54.63" />
              <path d="M21.98 55.42c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09 16.638 16.638 0 01-2.99-.43c-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.2 59.32a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M1.01 55.43a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <polyline points="37.61 42.04 37.61 42.37 37.61 45.65" />
              <polyline points="22.06 61.41 22.06 61.73 22.06 65.01" />
              <path d="M.93 56.22c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <polyline points="22.06 56.22 22.06 56.54 22.06 59.82" />
              <path d="M21.98 60.62c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09 16.638 16.638 0 01-2.99-.43c-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <polyline points="22.06 30.25 22.06 30.58 22.06 33.85" />
              <path d="M21.98 34.65c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.2 38.55a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <polyline points="37.61 21.27 37.61 21.6 37.61 24.87" />
              <path d="M37.53 25.67c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="37.61 31.66 37.61 31.98 37.61 35.26" />
              <path d="M37.53 36.06c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a16.638 16.638 0 01-2.99-.43c-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.06 40.64 22.06 40.96 22.06 44.24" />
              <path d="M22.05 44.45c0 .04 0 .08-.01.12-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13 0 0 0 0 0 0-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.2 48.94a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M47.35 9.66c-.96.55-2.09.88-3.17 1.03-1.1.15-2.25.14-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.06-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43v-.01-.11" />
              <path d="M49.32 6.92v.11s0 0 0 0c-.03.56-.27 1.07-.61 1.5-.07.09-.14.17-.22.25-.04.04-.08.08-.11.12-.24.23-.5.43-.78.61-.06.04-.13.08-.19.12h0c-.02.01-.03.02-.05.03" />
              <path d="M6.75 25.87c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.86.3 1.75.77 2.4 1.45" />
              <path d="M6.75 22.37c.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M6.75 26.11c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.29 1.74.73 2.4 1.37" />
              <path d="M6.75 22.14c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.14 3.34.03 1.07.17 2.21.51 3.15 1.09.47.29.91.65 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M16.24 27.62c-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.32-.42-.53-.9-.56-1.43v-.01-.11" />
              <path d="M18.21 24.88v.11s0 0 0 0c-.03.56-.27 1.07-.61 1.5-.07.09-.14.17-.22.25-.04.04-.08.08-.11.12-.24.23-.51.43-.78.61-.06.04-.13.08-.19.12-.02.01-.03.02-.05.03" />
              <path d="M16.49 19.36c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.08.22.17.32.26l.3.27c.19.19.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07.04.11.07.22.1.33.03.11.05.22.07.33.02.11.03.22.04.33.01.11.01.22.01.33" />
              <polyline points="22.06 25.06 22.06 25.38 22.06 28.66" />
              <path d="M22.06 28.66c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M20.34 15.9c0-.61.25-1.16.61-1.62.37-.46.85-.83 1.35-1.12" />
            </g>
          </g>
          <g name="XMLID_3_" visibility={props.level >= 2 ? 'visible' : 'hidden'}>
            <g fillRule="nonzero">
              <path
                d="M52.61 44.27v2.69c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.92h.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M52.61 38.17v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33 0 .1.01.21.01.32z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M52.61 32.98v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33a.585.585 0 01-.02-.13c.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33 0 .12.01.23.01.34z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M52.61 27.79v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33V31.9c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 005.05.13 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33 0 .12.01.23.01.34z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M52.61 22.59v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33 0 .13.01.23.01.34z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M52.61 17.4V21c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33 0 .11.01.22.01.33z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M52.61 12.21v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33 0 .11.01.22.01.33z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M52.61 7.02v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a16.638 16.638 0 01-2.99-.43c-.35-.09-.7-.18-1.03-.29V16c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33a3.88 3.88 0 00-.07-.33c-.03-.11-.06-.22-.1-.33a.185.185 0 01-.02-.07c-.07-.2-.15-.39-.25-.58-.11-.22-.24-.42-.38-.63a7.126 7.126 0 00-.76-.88c-.09-.09-.19-.18-.3-.27-.1-.09-.21-.17-.32-.26-.11-.09-.22-.17-.34-.25-.33-.23-.7-.45-1.08-.65-.58-.3-1.2-.56-1.87-.78-.01 0-.02-.01-.03-.01V7.02c0-.04.01-.17.01-.21 0-.07.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43c.68-.04 1.36-.04 2.04.01.33.02.66.05.99.09.64.08 1.26.19 1.86.33.38.09.75.19 1.11.31.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.31.27.58.55.83.85a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07.04.11.07.22.1.33a3.712 3.712 0 01.11.66c0 .11.01.22.01.33zm-3.85-.07v-.11c0-.58-.22-1.1-.56-1.55-.34-.44-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.14-1.09-1.1-.17-2.24-.18-3.34-.03-1.08.15-2.21.48-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.62 1.01-.62 1.62v.12c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.24.23.5.44.78.61.03.02.05.03.08.05.04.02.07.04.11.07.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.48 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.54-.38.78-.61.04-.04.08-.08.11-.12.08-.08.15-.16.22-.25.36-.44.6-.95.63-1.51z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M48.76 6.95c-.03.56-.27 1.07-.61 1.51-.07.09-.14.17-.22.25-.64-.68-1.54-1.14-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.81.17-.91.17-1.84.47-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43v-.01c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07.95-.55 2.07-.87 3.14-1.02 1.09-.16 2.22-.15 3.31.01 1.07.16 2.19.5 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M47.93 8.7c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.51-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.81-.17.93.06 1.88.23 2.77.54.85.31 1.75.77 2.39 1.45z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M47.82 8.82c-.24.23-.5.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.46-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.27 1.73.72 2.4 1.36z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M47.03 9.43c-.06.04-.13.08-.19.12-.71-.42-1.51-.7-2.3-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26s-1.6.46-2.31.87c-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.06-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.79.2 1.62.52 2.32.97z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M46.84 9.55c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.25.14-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.01-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.26 2.46-.26.82 0 1.66.09 2.46.28.8.17 1.6.46 2.31.88z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M37.05 52.67v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M37.05 47.48v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M37.05 42.28v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M37.05 37.09v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33 0-.04.01-.08.01-.12 0-.07.01-.14.01-.21v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.3h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M37.05 31.9v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.04.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M37.05 26.7v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33V30.5c.33.11.68.2 1.03.29.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M37.05 21.51v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33V25.3c.33.11.68.2 1.03.29.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M35.42 12.51c.19.18.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07.04.11.07.22.1.33.03.11.05.22.07.33.02.11.03.22.04.33.01.11.01.22.01.33V19.59c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.02-.11-.04-.22-.07-.33-.03-.11-.06-.22-.1-.33a.185.185 0 01-.02-.07c-.07-.2-.15-.39-.25-.58-.11-.22-.24-.42-.38-.63a7.126 7.126 0 00-.76-.88c-.09-.09-.19-.18-.3-.27-.1-.09-.21-.18-.32-.26-.11-.08-.22-.17-.34-.25-.33-.23-.7-.45-1.08-.65-.58-.3-1.2-.56-1.87-.78-.01 0-.02-.01-.03-.01V16c0-.04.01-.16.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.79 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43 17.356 17.356 0 013.03.1 15.682 15.682 0 012.94.63c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.11.08.2.17.3.26zm-13.91 5.9c.03.02.05.03.08.05.04.02.07.04.11.07.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.47 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.55-.38.78-.61.04-.04.08-.08.11-.12.45-.48.79-1.07.83-1.75v-.01-.11c0-.57-.22-1.1-.56-1.55-.34-.44-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.15-1.09-1.09-.17-2.24-.18-3.34-.03-1.08.15-2.21.47-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.61 1.01-.61 1.62v.11c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.26.24.52.44.8.62z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M33.2 15.92c0 .01 0 .01 0 0-.03.69-.38 1.28-.83 1.76-.64-.68-1.54-1.15-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.82.17-.91.17-1.84.48-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43.03-.58.29-1.11.65-1.54.36-.44.83-.8 1.31-1.08.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.36.44.6.95.63 1.51z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M32.38 17.68c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.5-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.84.29 1.73.76 2.38 1.44z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M32.26 17.8c-.24.23-.5.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.46-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.23 2.77-.18.92.05 1.86.21 2.73.5.85.28 1.74.73 2.4 1.37z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M31.48 18.41c-.06.04-.13.08-.19.12-.71-.42-1.51-.7-2.3-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26-.79.18-1.6.46-2.31.87-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.79.2 1.61.52 2.32.97z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M31.29 18.53c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.01-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.79.17 1.6.46 2.31.88z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M21.5 61.65v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a16.638 16.638 0 01-2.99-.43c-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24l-.06-.3a3.71 3.71 0 01-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.17.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.63-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M21.5 56.46v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.03-.16-.04-.31-.05-.47 0-.07-.01-.13-.01-.2v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.17.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.63-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.32h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M21.5 51.26v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09 16.638 16.638 0 01-2.99-.43c-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M21.5 46.07v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M21.5 40.88v3.28c0 .07 0 .14-.01.21 0 .04 0 .08-.01.12-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M21.5 35.68v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M21.5 30.49v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 005.05.13c.63-.08 1.26-.18 1.86-.33.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.32h-.01z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M19.86 21.49c.19.19.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07a4.143 4.143 0 01.17.66c.02.11.03.22.04.33.01.11.01.22.01.33V28.57c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.17.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3.32-.08.64-.15.97-.2a17.356 17.356 0 016.91.2c.37.09.73.19 1.08.3.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.09.1.19.19.28.28zM6.14 27.51c.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.47 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.55-.38.78-.61.04-.04.08-.08.11-.12.08-.08.15-.16.22-.25.34-.43.58-.94.61-1.5v-.11c0-.58-.22-1.1-.56-1.55-.34-.45-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.15-1.09-1.09-.17-2.24-.18-3.34-.03-1.08.15-2.21.47-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.61 1.01-.61 1.62v.12c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.24.23.5.44.78.61.03.02.05.03.08.05.06.01.1.04.13.06z"
                fill="#717170"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M17.65 24.91c-.03.56-.27 1.07-.61 1.51-.07.09-.14.17-.22.25-.64-.68-1.54-1.14-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.82.17-.91.17-1.84.47-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.32-.42-.53-.9-.56-1.43v-.01c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.36.43.6.94.63 1.51z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M16.82 26.66c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.51-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.84.31 1.74.77 2.38 1.45z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M16.71 26.78c-.24.23-.51.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.47-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.27 1.73.72 2.4 1.36z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M15.92 27.39c-.06.04-.13.08-.19.12-.71-.42-1.51-.71-2.31-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26-.79.18-1.6.46-2.31.87-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.52 2.33.97z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
              <path
                d="M15.73 27.51c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.02-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.8.17 1.6.46 2.31.88z"
                fill="#3A3938"
                transform="translate(.76 1.37) translate(44 36) translate(1)"
              />
            </g>
            <g
              stroke="#444"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.8725}
              transform="translate(.76 1.37) translate(44 36)"
            >
              <path d="M4.64 33.27a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M20.2 11.3c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43 17.356 17.356 0 013.03.1 15.682 15.682 0 012.94.63" />
              <path d="M16.93 16c0-.04.01-.16.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.79 1.42-1.43 2.27-1.93" />
              <path d="M20.78 15.93c.03-.58.29-1.11.65-1.54.36-.44.83-.8 1.31-1.08" />
              <path d="M21.73 17.8c.3-.3.65-.55 1.02-.76" />
              <path d="M21.61 17.68c.33-.35.72-.64 1.13-.87" />
              <line x1={53.61} y1={27.79} x2={53.61} y2={31.39} />
              <path d="M53.61 31.39c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <line x1={53.61} y1={22.59} x2={53.61} y2={26.2} />
              <path d="M53.61 26.2c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M53.53 26.99c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={36.28} y1={23.21} x2={36.29} y2={23.22} />
              <line x1={53.61} y1={43.37} x2={53.61} y2={44.27} />
              <path d="M53.61 46.97c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <line x1={38.05} y1={52.35} x2={38.05} y2={52.67} />
              <path d="M38.05 55.95c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M38.05 50.76c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <line x1={53.61} y1={38.17} x2={53.61} y2={41.77} />
              <path d="M53.61 41.77c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M53.53 42.57c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M37.98 51.55c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={53.61} y1={44.27} x2={53.61} y2={46.97} />
              <line x1={38.05} y1={52.67} x2={38.05} y2={55.95} />
              <path d="M53.53 42.57c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <line x1={53.61} y1={17.4} x2={53.61} y2={21} />
              <path d="M53.61 21c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M1.38 35.36c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.38} y1={38.96} x2={1.38} y2={35.36} />
              <path d="M22.5 38.96c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <line x1={53.61} y1={32.98} x2={53.61} y2={36.58} />
              <path d="M53.61 36.58c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M1.38 45.75c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.38} y1={49.35} x2={1.38} y2={45.75} />
              <path d="M4.64 54.05a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M38.3 9.52c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.26 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.3.89" />
              <path d="M38.3 9.28c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96" />
              <line x1={53.61} y1={12.21} x2={53.61} y2={15.81} />
              <path d="M53.61 15.81c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M53.53 16.61c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M53.53 21.8c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={20.73} y1={42.58} x2={20.74} y2={42.59} />
              <path d="M53.53 37.38c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M53.53 37.38c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M53.53 32.19c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.74 16.81c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.86.3 1.75.77 2.4 1.45" />
              <path d="M22.74 13.31c.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M22.74 13.07c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.15 3.34.03 1.07.17 2.21.51 3.15 1.09.47.29.91.65 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M53.53 32.19c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M53.53 26.99c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M37.98 51.55c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M53.53 21.8c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M1.38 50.94c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.38} y1={54.54} x2={1.38} y2={50.94} />
              <path d="M22.5 54.54c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M38.05 45.56c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M1.38 61.33c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.38} y1={64.93} x2={1.38} y2={61.33} />
              <path d="M22.5 64.93c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a16.638 16.638 0 01-2.99-.43c-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.64 69.62a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24l-.06-.3a3.71 3.71 0 01-.06-.67" />
              <line x1={1.38} y1={59.74} x2={1.38} y2={56.13} />
              <path d="M22.5 59.74c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13" />
              <path d="M4.64 64.43a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M22.42 60.53c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.46 60.54a.585.585 0 01-.02-.13c-.03-.16-.04-.31-.05-.47" />
              <path d="M22.42 55.34c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M7.19 27.48c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.31.89h0" />
              <path d="M7.19 27.25c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96" />
              <path d="M1.38 30.17c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.38} y1={33.77} x2={1.38} y2={30.17} />
              <path d="M22.5 33.77c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M22.74 18.5c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.3.89" />
              <path d="M22.74 18.27c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96 0 0 0 0 0 0" />
              <path d="M38.05 24.79c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <line x1={20.73} y1={37.38} x2={20.74} y2={37.39} />
              <path d="M38.05 35.18c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M1.38 40.56c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.38} y1={44.16} x2={1.38} y2={40.56} />
              <path d="M22.5 44.16c0 .07 0 .14-.01.21" />
              <path d="M38.3 7.83c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.81-.17.93.06 1.88.23 2.77.54.86.3 1.76.77 2.4 1.45" />
              <path d="M38.3 4.33c.95-.55 2.07-.87 3.14-1.02 1.09-.16 2.22-.15 3.31.01 1.07.16 2.19.5 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M38.3 8.06c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.29 1.74.73 2.4 1.37" />
              <path d="M38.3 4.09c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.14 3.34.03 1.07.17 2.2.51 3.14 1.09.47.29.92.66 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M36.33 6.83c0-.61.25-1.16.62-1.62.37-.46.85-.83 1.35-1.12" />
              <path d="M35.75 2.32c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43c.68-.04 1.36-.04 2.04.01.33.02.66.05.99.09.64.08 1.26.19 1.86.33.38.09.75.19 1.11.31.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.31.27.58.55.83.85a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07a4.143 4.143 0 01.17.66c.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M32.49 7.02c0-.04.01-.17.01-.21 0-.07.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93" />
              <line x1={53.61} y1={7.02} x2={53.61} y2={10.62} />
              <path d="M53.61 10.62c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M36.34 6.94c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07" />
              <path d="M37.28 8.82c.3-.3.65-.55 1.02-.76" />
              <path d="M37.17 8.7c.33-.35.72-.64 1.13-.87" />
              <path d="M5.22 24.79c0-.61.25-1.16.61-1.62.37-.46.85-.83 1.35-1.12" />
              <path d="M4.64 20.28c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3.32-.08.64-.15.97-.2a17.356 17.356 0 016.91.2c.37.09.73.19 1.08.3" />
              <path d="M1.38 24.98c0-.04.01-.17.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93" />
              <line x1={1.38} y1={28.58} x2={1.38} y2={24.98} />
              <path d="M5.23 24.9c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07" />
              <path d="M6.17 26.78c.3-.3.65-.55 1.02-.76" />
              <path d="M6.06 26.66c.33-.35.72-.64 1.13-.87" />
              <path d="M6.95 27.39c.08-.05.16-.1.24-.14" />
              <path d="M7.14 27.51s0 0 0 0c.02-.01.03-.02.05-.03" />
              <path d="M22.42 29.37c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M20.86 21.49c-.01.01-.02.01-.03.02" />
              <path d="M1.46 29.38a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <line x1={16.93} y1={19.28} x2={16.93} y2={16} />
              <path d="M37.98 35.97c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.42 34.57c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.46 50.15a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <path d="M38.06 9.43c.08-.05.16-.1.24-.14" />
              <path d="M53.53 11.41c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M38.25 9.55c.01-.01.03-.02.05-.03" />
              <path d="M1.46 39.76a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <path d="M37.98 46.36c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M37.98 25.59c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.42 44.95c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M36.42 12.51c-.01.01-.02.01-.03.02" />
              <path d="M37.98 46.36c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M1.38 59.93c0-.07-.01-.13-.01-.2" />
              <path d="M22.51 18.41h0c.08-.05.16-.1.24-.14" />
              <path d="M37.98 20.39c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.7 18.53c.01-.01.03-.02.05-.03" />
              <line x1={32.49} y1={10.3} x2={32.49} y2={7.02} />
              <path d="M53.53 11.41c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M22.42 50.15c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.46 34.57a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67" />
              <path d="M37.98 41.17c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.46 44.96a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67" />
              <path d="M37.98 30.78c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.42 39.76c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.74 17.04c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.23 2.77-.18.92.05 1.86.21 2.73.5.85.29 1.74.73 2.4 1.37" />
              <path d="M32.24 18.55c-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43 0 0 0 0 0 0v-.11" />
              <path d="M34.21 15.81v.12c-.03.68-.38 1.28-.83 1.75-.04.04-.08.08-.11.12" />
              <path d="M33.26 17.8c-.24.23-.5.43-.78.61-.06.04-.13.08-.19.12h0c-.02.01-.03.02-.05.03" />
              <path d="M32.49 10.3c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26l.3.27c.19.18.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07a3.88 3.88 0 01.17.66c.02.11.03.22.04.33.01.11.01.22.01.33" />
              <polyline points="38.05 16 38.05 16.32 38.05 19.6" />
              <path d="M38.05 19.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="38.05 47.15 38.05 47.48 38.05 50.76" />
              <polyline points="38.05 26.38 38.05 26.7 38.05 29.98" />
              <path d="M38.05 29.98c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.5 35.36 22.5 35.68 22.5 38.96" />
              <path d="M22.42 39.76c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.64 43.66a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <polyline points="38.05 36.77 38.05 37.09 38.05 40.37" />
              <path d="M38.05 40.37c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.5 45.75 22.5 46.07 22.5 49.35" />
              <path d="M22.5 49.35c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M22.42 50.15c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M53.53 16.61c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.5 50.94 22.5 51.26 22.5 54.54" />
              <path d="M22.42 55.34c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09 16.638 16.638 0 01-2.99-.43c-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.64 59.24a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M1.46 55.34a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67" />
              <polyline points="38.05 41.96 38.05 42.28 38.05 45.56" />
              <polyline points="22.5 61.33 22.5 61.65 22.5 64.93" />
              <path d="M1.38 56.13c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <polyline points="22.5 56.13 22.5 56.46 22.5 59.74" />
              <path d="M22.42 60.53c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <polyline points="22.5 30.17 22.5 30.49 22.5 33.77" />
              <path d="M22.42 34.57c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.64 38.47a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <polyline points="38.05 21.19 38.05 21.51 38.05 24.79" />
              <path d="M37.98 25.59c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="38.05 31.57 38.05 31.9 38.05 35.18" />
              <path d="M37.98 35.97c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.5 40.56 22.5 40.88 22.5 44.16" />
              <path d="M22.49 44.36c0 .04 0 .08-.01.12-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13 0 0 0 0 0 0-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.64 48.85a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M47.79 9.57c-.96.55-2.09.88-3.17 1.03-1.1.15-2.25.14-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.06-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43v-.01-.11" />
              <path d="M49.76 6.83v.11s0 0 0 0c-.03.56-.27 1.07-.61 1.5-.07.09-.14.17-.22.25-.04.04-.08.08-.11.12-.24.23-.5.43-.78.61-.06.04-.13.08-.19.12h0c-.02.01-.03.02-.05.03" />
              <path d="M7.19 25.79c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.86.3 1.75.77 2.4 1.45" />
              <path d="M7.19 22.29c.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M7.19 26.02c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.29 1.74.73 2.4 1.37" />
              <path d="M7.19 22.05c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.14 3.34.03 1.07.17 2.21.51 3.15 1.09.47.29.91.65 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M16.69 27.53c-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.32-.42-.53-.9-.56-1.43v-.01-.11" />
              <path d="M18.65 24.79v.11s0 0 0 0c-.03.56-.27 1.07-.61 1.5-.07.09-.14.17-.22.25-.04.04-.08.08-.11.12-.24.23-.51.43-.78.61-.06.04-.13.08-.19.12-.02.01-.03.02-.05.03" />
              <path d="M16.93 19.28c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26l.3.27a7.126 7.126 0 01.76.88 5.016 5.016 0 01.63 1.21c.01.02.02.05.02.07a4.143 4.143 0 01.17.66c.02.11.03.22.04.33.01.11.01.22.01.33" />
              <polyline points="22.5 24.98 22.5 25.3 22.5 28.58" />
              <path d="M22.5 28.58c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M20.78 15.81c0-.61.25-1.16.61-1.62.37-.46.85-.83 1.35-1.12" />
            </g>
          </g>
          <g name="XMLID_4_" visibility={props.level >= 3 ? 'visible' : 'hidden'}>
            <g fillRule="nonzero">
              <path
                d="M53.05 44.19v2.69c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.92h.01z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M53.05 38.09v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14a3.712 3.712 0 01.05.65z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M53.05 32.9v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33a.585.585 0 01-.02-.13c.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33V37c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.13.01.24.01.35z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M53.05 27.7v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 005.05.13 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.12.01.23.01.34z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M53.05 22.51v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.12.01.23.01.34z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M53.05 17.32v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M53.05 12.12v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M53.05 6.93v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.02-.11-.04-.22-.07-.33-.03-.11-.06-.22-.1-.33a.185.185 0 01-.02-.07c-.07-.2-.15-.39-.25-.58-.11-.22-.24-.42-.38-.63a7.126 7.126 0 00-.76-.88c-.09-.09-.19-.18-.3-.27-.1-.09-.21-.17-.32-.26-.11-.08-.22-.17-.34-.25-.33-.23-.7-.45-1.08-.65-.58-.3-1.2-.56-1.87-.78-.01 0-.02-.01-.03-.01V6.95c0-.04.01-.17.01-.21 0-.07.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43c.68-.04 1.36-.04 2.04.01.33.02.66.05.99.09.64.08 1.26.19 1.86.33.38.09.75.19 1.11.31.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.31.27.58.55.83.85a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07.04.11.07.22.1.33a3.712 3.712 0 01.12.97zm-3.85-.07v-.11c0-.58-.22-1.1-.56-1.55-.34-.45-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.14-1.09-1.1-.17-2.24-.18-3.34-.03-1.08.15-2.21.48-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.62 1.01-.62 1.62v.12c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.24.23.5.44.78.61.03.02.05.03.08.05.04.02.07.04.11.07.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.48 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.54-.38.78-.61.04-.04.08-.08.11-.12.08-.08.15-.16.22-.25.36-.44.6-.94.63-1.51z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M49.2 6.86c-.03.56-.27 1.07-.61 1.51-.07.09-.14.17-.22.25-.64-.68-1.54-1.14-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.81.17-.91.17-1.84.47-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43v-.01c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07.95-.55 2.07-.87 3.14-1.02 1.09-.16 2.22-.15 3.31.01 1.07.16 2.19.5 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.95.62 1.51z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M48.37 8.62c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.51-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.81-.17.93.06 1.88.23 2.77.54.85.3 1.75.77 2.39 1.45z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M48.26 8.73c-.24.23-.5.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.46-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.28 1.74.72 2.4 1.36z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M47.48 9.34c-.06.04-.13.08-.19.12-.71-.42-1.51-.7-2.3-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26s-1.6.46-2.31.87c-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.06-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.79.21 1.61.52 2.32.97z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M47.28 9.46c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.25.14-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.01-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.26 2.46-.26.82 0 1.66.09 2.46.28.8.18 1.61.46 2.31.88z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M37.5 52.58v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h.01z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M37.5 47.39v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33V51.2c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M37.5 42.2v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M37.5 37v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33 0-.04.01-.08.01-.12 0-.07.01-.14.01-.21v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.3h-.01z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M37.5 31.81v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.04.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M37.5 26.62v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M37.5 21.43v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M35.86 12.43c.19.18.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07a4.143 4.143 0 01.17.66c.02.11.03.22.04.33.01.11.01.22.01.33V19.51c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.02-.11-.04-.22-.07-.33-.03-.11-.06-.22-.1-.33a.185.185 0 01-.02-.07c-.07-.2-.15-.39-.25-.58-.11-.22-.24-.42-.38-.63a7.126 7.126 0 00-.76-.88c-.09-.09-.19-.18-.3-.27-.1-.09-.21-.18-.32-.26-.11-.08-.22-.17-.34-.25-.33-.23-.7-.45-1.08-.65-.58-.3-1.2-.56-1.87-.78-.01 0-.02-.01-.03-.01v-3.28c0-.04.01-.16.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.79 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43 17.356 17.356 0 013.03.1 15.682 15.682 0 012.94.63c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.08.22.17.32.26.11.09.21.18.3.27zm-13.91 5.9c.03.02.05.03.08.05.04.02.07.04.11.07.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.47 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.55-.38.78-.61.04-.04.08-.08.11-.12.45-.48.79-1.07.83-1.75v-.01-.11c0-.57-.22-1.1-.56-1.55-.34-.44-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.15-1.09-1.09-.17-2.24-.18-3.34-.03-1.08.15-2.21.47-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.61 1.01-.61 1.62v.11c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.26.24.52.44.8.62z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M33.65 15.84c-.03.69-.38 1.28-.83 1.76-.64-.68-1.54-1.15-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.82.17-.91.17-1.84.48-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43.03-.58.29-1.11.65-1.54.36-.44.83-.8 1.31-1.08.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.35.44.59.94.63 1.51z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M32.82 17.6c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.5-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.84.29 1.73.76 2.38 1.44z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M32.7 17.71c-.24.23-.5.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.46-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.23 2.77-.18.92.05 1.86.21 2.73.5.86.29 1.74.73 2.4 1.37z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M31.92 18.32c-.06.04-.13.08-.19.12-.71-.42-1.51-.7-2.3-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26-.79.18-1.6.46-2.31.87-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.79.21 1.61.52 2.32.97z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M31.73 18.44c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.01-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.8.18 1.6.47 2.31.88z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M21.94 61.56v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24l-.06-.3c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.17.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.63-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M21.94 56.37v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.03-.16-.04-.31-.05-.47 0-.07-.01-.13-.01-.2v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.17.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.63-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.32h-.01z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M21.94 51.18v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M21.94 45.99v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M21.94 40.79v3.28c0 .07 0 .14-.01.21 0 .04 0 .08-.01.12-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M21.94 35.6v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M21.94 30.41v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 005.05.13c.63-.08 1.26-.18 1.86-.33.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.32h-.01z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M20.31 21.41a7.126 7.126 0 01.76.88 5.016 5.016 0 01.63 1.21c.01.02.02.05.02.07a3.88 3.88 0 01.21.99c.01.11.01.22.01.33V28.49c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.17.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3.32-.08.64-.15.97-.2a17.356 17.356 0 016.91.2c.37.09.73.19 1.08.3.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.08.1.18.19.28.28zM6.59 27.42c.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.47 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.55-.38.78-.61.04-.04.08-.08.11-.12.08-.08.15-.16.22-.25.34-.43.58-.94.61-1.5v-.11c0-.58-.22-1.1-.56-1.55-.34-.45-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.15-1.09-1.09-.17-2.24-.18-3.34-.03-1.08.15-2.21.47-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.61 1.01-.61 1.62v.12c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.24.23.5.44.78.61.03.02.05.03.08.05.05.02.09.04.13.06z"
                fill="#EA651C"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M18.09 24.82c-.03.56-.27 1.07-.61 1.51-.07.09-.14.17-.22.25-.64-.68-1.54-1.14-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.82.17-.91.17-1.84.47-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.32-.42-.53-.9-.56-1.43v-.01c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.36.44.6.95.63 1.51z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M17.26 26.58c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.51-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.84.3 1.74.77 2.38 1.45z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M17.15 26.69c-.24.23-.51.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.47-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.28 1.74.72 2.4 1.36z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M16.36 27.31c-.06.04-.13.08-.19.12-.71-.42-1.51-.71-2.31-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26-.79.18-1.6.46-2.31.87-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05A8.068 8.068 0 019 26.28c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.97z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
              <path
                d="M16.17 27.42c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.02-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.8.18 1.61.47 2.31.88z"
                fill="#AB4A15"
                transform="translate(.76 1.37) translate(60 45)"
              />
            </g>
            <g
              stroke="#444"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.8725}
              transform="translate(.76 1.37) translate(60 45)"
            >
              <path d="M4.09 33.19a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M19.64 11.22c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43 17.356 17.356 0 013.03.1 15.682 15.682 0 012.94.63" />
              <path d="M16.37 15.91c0-.04.01-.16.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.79 1.42-1.43 2.27-1.93" />
              <path d="M20.22 15.84c.03-.58.29-1.11.65-1.54.36-.44.83-.8 1.31-1.08" />
              <path d="M21.17 17.71c.3-.3.65-.55 1.02-.76" />
              <path d="M21.05 17.6c.33-.35.72-.64 1.13-.87" />
              <line x1={53.05} y1={27.7} x2={53.05} y2={31.3} />
              <path d="M53.05 31.3c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <line x1={53.05} y1={22.51} x2={53.05} y2={26.11} />
              <path d="M53.05 26.11c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M52.97 26.91c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={35.73} y1={23.13} x2={35.74} y2={23.14} />
              <line x1={53.05} y1={43.28} x2={53.05} y2={44.19} />
              <path d="M53.05 46.88c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <line x1={37.5} y1={52.26} x2={37.5} y2={52.58} />
              <path d="M37.5 55.86c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M37.5 50.67c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <line x1={53.05} y1={38.09} x2={53.05} y2={41.69} />
              <path d="M53.05 41.69c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M52.97 42.49c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M37.42 51.47c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={53.05} y1={44.19} x2={53.05} y2={46.88} />
              <line x1={37.5} y1={52.58} x2={37.5} y2={55.86} />
              <path d="M52.97 42.49c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <line x1={53.05} y1={17.32} x2={53.05} y2={20.92} />
              <path d="M53.05 20.92c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M.82 35.28c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={0.82} y1={38.88} x2={0.82} y2={35.28} />
              <path d="M21.94 38.88c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <line x1={53.05} y1={32.9} x2={53.05} y2={36.5} />
              <path d="M53.05 36.5c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M.82 45.66c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={0.82} y1={49.27} x2={0.82} y2={45.66} />
              <path d="M4.09 53.96a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M37.74 9.43c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.26 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.3.89" />
              <path d="M37.74 9.2c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96" />
              <line x1={53.05} y1={12.12} x2={53.05} y2={15.73} />
              <path d="M53.05 15.73c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M52.97 16.52c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M52.97 21.72c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={20.17} y1={42.49} x2={20.18} y2={42.5} />
              <path d="M52.97 37.29c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M52.97 37.29c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M52.97 32.1c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.19 16.72c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.86.3 1.75.77 2.4 1.45" />
              <path d="M22.19 13.22c.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M22.19 12.99c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.15 3.34.03 1.07.17 2.21.51 3.15 1.09.47.29.91.65 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M52.97 32.1c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M52.97 26.91c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M37.42 51.47c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M52.97 21.72c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M.82 50.86c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={0.82} y1={54.46} x2={0.82} y2={50.86} />
              <path d="M21.94 54.46c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M37.5 45.48c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M.82 61.24c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={0.82} y1={64.84} x2={0.82} y2={61.24} />
              <path d="M21.94 64.84c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.09 69.54a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24l-.06-.3c-.04-.22-.06-.45-.06-.67" />
              <line x1={0.82} y1={59.65} x2={0.82} y2={56.05} />
              <path d="M21.94 59.65c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13" />
              <path d="M4.09 64.35a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M21.86 60.45c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M.9 60.45a.585.585 0 01-.02-.13c-.03-.16-.04-.31-.05-.47" />
              <path d="M21.86 55.25c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M6.63 27.39c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.31.89h0" />
              <path d="M6.63 27.16c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96" />
              <path d="M.82 30.09c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={0.82} y1={33.69} x2={0.82} y2={30.09} />
              <path d="M21.94 33.69c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M22.19 18.41c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.3.89" />
              <path d="M22.19 18.18c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96 0 0 0 0 0 0" />
              <path d="M37.5 24.71c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <line x1={20.17} y1={37.3} x2={20.18} y2={37.31} />
              <path d="M37.5 35.09c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M.82 40.47c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={0.82} y1={44.07} x2={0.82} y2={40.47} />
              <path d="M21.94 44.07c0 .07 0 .14-.01.21" />
              <path d="M37.74 7.74c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.81-.17.93.06 1.88.23 2.77.54.86.3 1.76.77 2.4 1.45" />
              <path d="M37.74 4.24c.95-.55 2.07-.87 3.14-1.02 1.09-.16 2.22-.15 3.31.01 1.07.16 2.19.5 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M37.74 7.98c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.29 1.74.73 2.4 1.37" />
              <path d="M37.74 4.01c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.14 3.34.03 1.07.17 2.2.51 3.14 1.09.47.29.92.66 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M35.78 6.75c0-.61.25-1.16.62-1.62.37-.46.85-.83 1.35-1.12" />
              <path d="M35.2 2.24c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43c.68-.04 1.36-.04 2.04.01.33.02.66.05.99.09.64.08 1.26.19 1.86.33.38.09.75.19 1.11.31.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.31.27.58.55.83.85a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07.04.11.07.22.1.33a3.712 3.712 0 01.12.99" />
              <path d="M31.93 6.93c0-.04.01-.17.01-.21 0-.07.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93" />
              <line x1={53.05} y1={6.93} x2={53.05} y2={10.53} />
              <path d="M53.05 10.53c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M35.78 6.86c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07" />
              <path d="M36.72 8.73c.3-.3.65-.55 1.02-.76" />
              <path d="M36.61 8.62c.33-.35.72-.64 1.13-.87" />
              <path d="M4.67 24.71c0-.61.25-1.16.61-1.62.37-.46.85-.83 1.35-1.12" />
              <path d="M4.09 20.2c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3.32-.08.64-.15.97-.2a17.356 17.356 0 016.91.2c.37.09.73.19 1.08.3" />
              <path d="M.82 24.89c0-.04.01-.17.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93" />
              <line x1={0.82} y1={28.49} x2={0.82} y2={24.89} />
              <path d="M4.67 24.82c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07" />
              <path d="M5.62 26.69c.3-.3.65-.55 1.02-.76" />
              <path d="M5.5 26.58c.33-.35.72-.64 1.13-.87" />
              <path d="M6.4 27.31c.08-.05.16-.1.24-.14" />
              <path d="M6.59 27.42s0 0 0 0c.02-.01.03-.02.05-.03" />
              <path d="M21.86 29.29c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M20.31 21.41c-.01.01-.02.01-.03.02" />
              <path d="M.9 29.29a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <line x1={16.37} y1={19.19} x2={16.37} y2={15.91} />
              <path d="M37.42 35.89c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M21.86 34.48c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M.9 50.07a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67" />
              <path d="M37.5 9.34c.08-.05.16-.1.24-.14" />
              <path d="M52.97 11.33c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M37.7 9.46c.01-.01.03-.02.05-.03" />
              <path d="M.9 39.68a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67" />
              <path d="M37.42 46.27c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M37.42 25.5c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M21.86 44.87c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M35.86 12.43c-.01.01-.02.01-.03.02" />
              <path d="M37.42 46.27c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M.82 59.85c0-.07-.01-.13-.01-.2" />
              <path d="M21.95 18.33h0c.08-.05.16-.1.24-.14" />
              <path d="M37.42 20.31c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.14 18.44c.01-.01.03-.02.05-.03" />
              <line x1={31.93} y1={10.21} x2={31.93} y2={6.93} />
              <path d="M52.97 11.33c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M21.86 50.06c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M.9 34.49a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <path d="M37.42 41.08c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M.9 44.87a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <path d="M37.42 30.69c.01.05.02.09.02.14a3.712 3.712 0 01.05.66" />
              <path d="M21.86 39.67c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.19 16.96c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.23 2.77-.18.92.05 1.86.21 2.73.5.85.29 1.74.73 2.4 1.37" />
              <path d="M31.68 18.47c-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43 0 0 0 0 0 0v-.11" />
              <path d="M33.65 15.73v.12c-.03.68-.38 1.28-.83 1.75-.04.04-.08.08-.11.12" />
              <path d="M32.7 17.71c-.24.23-.5.43-.78.61-.06.04-.13.08-.19.12h0c-.02.01-.03.02-.05.03" />
              <path d="M31.93 10.21c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.08.22.17.32.26l.3.27c.19.18.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07.04.11.07.22.1.33.03.11.05.22.07.33.02.11.03.22.04.33.01.11.01.22.01.33" />
              <polyline points="37.5 15.91 37.5 16.23 37.5 19.51" />
              <path d="M37.5 19.51c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="37.5 47.07 37.5 47.39 37.5 50.67" />
              <polyline points="37.5 26.3 37.5 26.62 37.5 29.9" />
              <path d="M37.5 29.9c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="21.94 35.28 21.94 35.6 21.94 38.88" />
              <path d="M21.86 39.67c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.09 43.58a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <polyline points="37.5 36.68 37.5 37 37.5 40.29" />
              <path d="M37.5 40.29c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="21.94 45.66 21.94 45.99 21.94 49.27" />
              <path d="M21.94 49.27c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M21.86 50.06c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M52.97 16.52c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="21.94 50.86 21.94 51.18 21.94 54.46" />
              <path d="M21.86 55.25c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.09 59.15a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M.9 55.26a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <polyline points="37.5 41.88 37.5 42.2 37.5 45.48" />
              <polyline points="21.94 61.24 21.94 61.56 21.94 64.84" />
              <path d="M.82 56.05c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <polyline points="21.94 56.05 21.94 56.37 21.94 59.65" />
              <path d="M21.86 60.45c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <polyline points="21.94 30.09 21.94 30.41 21.94 33.69" />
              <path d="M21.86 34.48c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.09 38.38a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <polyline points="37.5 21.1 37.5 21.43 37.5 24.71" />
              <path d="M37.42 25.5c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="37.5 31.49 37.5 31.81 37.5 35.09" />
              <path d="M37.42 35.89c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="21.94 40.47 21.94 40.79 21.94 44.07" />
              <path d="M21.94 44.28c0 .04 0 .08-.01.12-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13 0 0 0 0 0 0-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.09 48.77a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M47.24 9.49c-.96.55-2.09.88-3.17 1.03-1.1.15-2.25.14-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.06-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43v-.01-.11" />
              <path d="M49.2 6.75v.11s0 0 0 0c-.03.56-.27 1.07-.61 1.5-.07.09-.14.17-.22.25-.04.04-.08.08-.11.12-.24.23-.5.43-.78.61-.06.04-.13.08-.19.12h0c-.02.01-.03.02-.05.03" />
              <path d="M6.63 25.7c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.86.3 1.75.77 2.4 1.45" />
              <path d="M6.63 22.2c.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M6.63 25.94c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.29 1.74.73 2.4 1.37" />
              <path d="M6.63 21.97c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.14 3.34.03 1.07.17 2.21.51 3.15 1.09.47.29.91.65 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M16.13 27.45c-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.32-.42-.53-.9-.56-1.43v-.01-.11" />
              <path d="M18.09 24.71v.11s0 0 0 0c-.03.56-.27 1.07-.61 1.5-.07.09-.14.17-.22.25-.04.04-.08.08-.11.12-.24.23-.51.43-.78.61-.06.04-.13.08-.19.12-.02.01-.03.02-.05.03" />
              <path d="M16.37 19.19c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26l.3.27a7.126 7.126 0 01.76.88 5.016 5.016 0 01.63 1.21c.01.02.02.05.02.07a3.88 3.88 0 01.21.99c.01.11.01.22.01.33" />
              <polyline points="21.94 24.89 21.94 25.21 21.94 28.49" />
              <path d="M21.94 28.49c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M20.22 15.73c0-.61.25-1.16.61-1.62.37-.46.85-.83 1.35-1.12" />
            </g>
          </g>
          <g name="XMLID_5_" visibility={props.level >= 4 ? 'visible' : 'hidden'}>
            <g fillRule="nonzero">
              <path
                d="M52.49 44.11v2.69c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33a3.88 3.88 0 01-.17.66c-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.92h.01z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M52.49 38.01v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29V47c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.1.01.2.01.32z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M52.49 32.81v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33a.585.585 0 01-.02-.13c.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.12.01.23.01.34z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M52.49 27.62v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29.32.08.64.15.97.2a17.356 17.356 0 005.05.13 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.12.01.23.01.34z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M52.49 22.43v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.21 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.12.01.23.01.34z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M52.49 17.23v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M52.49 12.04v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13a3.712 3.712 0 01.05.66z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M52.49 6.85v3.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.02-.11-.04-.22-.07-.33-.03-.11-.06-.22-.1-.33a.185.185 0 01-.02-.07c-.07-.2-.15-.39-.25-.58-.11-.22-.24-.42-.38-.63a7.126 7.126 0 00-.76-.88c-.09-.09-.19-.18-.3-.27-.1-.09-.21-.17-.32-.26-.11-.08-.22-.17-.34-.25-.33-.23-.7-.45-1.08-.65-.58-.3-1.2-.56-1.87-.78-.01 0-.02-.01-.03-.01V6.87c0-.04.01-.17.01-.21 0-.07.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43c.68-.04 1.36-.04 2.04.01.33.02.66.05.99.09.64.08 1.26.19 1.86.33.38.09.75.19 1.11.31.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.31.27.58.55.83.85a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07a4.143 4.143 0 01.17.66 3.712 3.712 0 01.05.64zm-3.85-.07v-.11c0-.58-.22-1.1-.56-1.55-.34-.45-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.14-1.09-1.1-.17-2.24-.18-3.34-.03-1.08.15-2.21.48-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.62 1.01-.62 1.62v.12c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.24.23.5.44.78.61.03.02.05.03.08.05.04.02.07.04.11.07.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.48 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.54-.38.78-.61.04-.04.08-.08.11-.12.08-.08.15-.16.22-.25.37-.44.6-.95.63-1.51z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M48.64 6.78c-.03.56-.27 1.07-.61 1.51-.07.09-.14.17-.22.25-.64-.68-1.54-1.14-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.81.17-.91.17-1.84.47-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43v-.01c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07.95-.55 2.07-.87 3.14-1.02 1.09-.16 2.22-.15 3.31.01 1.07.16 2.19.5 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M47.82 8.53c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.51-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.81-.17.93.06 1.88.23 2.77.54.84.31 1.74.77 2.39 1.45z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M47.7 8.65c-.24.23-.5.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.46-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.27 1.74.72 2.4 1.36z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M46.92 9.26c-.06.04-.13.08-.19.12-.71-.42-1.51-.7-2.3-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26s-1.6.46-2.31.87c-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.06-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.79.21 1.61.52 2.32.97z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M46.73 9.38c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.25.14-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.01-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.26 2.46-.26.82 0 1.66.09 2.46.28.79.17 1.6.46 2.31.88z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M36.94 52.5v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33V56.3c.33.11.68.21 1.03.29a16.869 16.869 0 002.99.43 17.356 17.356 0 003.03-.1 15.682 15.682 0 002.97-.64c.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h.01z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M36.94 47.31v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M36.94 42.11v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a16.638 16.638 0 01-2.99-.43c-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.07 1.26-.18 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M36.94 36.92v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a16.638 16.638 0 01-2.99-.43c-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.13.02-.11.03-.22.04-.33 0-.04.01-.08.01-.12 0-.07.01-.14.01-.21v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.3h-.01z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M36.94 31.73v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a16.638 16.638 0 01-2.99-.43c-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.14.01-.04.02-.09.02-.13.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29a16.869 16.869 0 002.99.43c.68.04 1.36.04 2.04-.01.33-.02.66-.05.99-.09.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M36.94 26.53v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a16.638 16.638 0 01-2.99-.43c-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M36.94 21.34v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09 16.638 16.638 0 01-2.99-.43c-.35-.09-.7-.18-1.03-.29V30c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.01-.05-.02-.09-.02-.13.01-.05.02-.09.02-.14.02-.11.03-.22.04-.33.01-.11.01-.22.01-.33v-3.28c.33.11.68.2 1.03.29.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.64-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.32z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M31.36 18.24c.28-.18.55-.38.78-.61.04-.04.08-.08.11-.12.45-.48.79-1.07.83-1.75v-.01-.11c0-.57-.22-1.1-.56-1.55-.34-.45-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.15-1.09-1.09-.17-2.24-.18-3.34-.03-1.08.15-2.21.47-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.61 1.01-.61 1.62v.11c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.24.23.5.44.78.61.03.02.05.03.08.05.04.02.07.04.11.07.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.47 3.17-1.03.02-.01.03-.02.05-.03.09-.03.15-.07.21-.11zm3.94-5.9c.19.18.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07.04.11.07.22.1.33.03.11.05.22.07.33.02.11.03.22.04.33.01.11.01.22.01.33V19.42c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29v-.32c0-.11 0-.22-.01-.33-.01-.11-.02-.22-.04-.33-.02-.11-.04-.22-.07-.33-.03-.11-.06-.22-.1-.33a.185.185 0 01-.02-.07c-.07-.2-.15-.39-.25-.58-.11-.22-.24-.42-.38-.63a7.126 7.126 0 00-.76-.88c-.09-.09-.19-.18-.3-.27-.1-.09-.21-.18-.32-.26-.11-.08-.22-.17-.34-.25-.33-.23-.7-.45-1.08-.65-.58-.3-1.2-.56-1.87-.78-.01 0-.02-.01-.03-.01v-3.28c0-.04.01-.16.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.79 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43 17.356 17.356 0 013.03.1 15.682 15.682 0 012.94.63c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.08.22.17.32.26.11.09.21.18.3.27z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M33.09 15.76c-.03.69-.38 1.28-.83 1.76-.64-.68-1.54-1.15-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.82.17-.91.17-1.84.48-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43.03-.58.29-1.11.65-1.54.36-.43.83-.8 1.31-1.08.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.36.43.6.94.63 1.51z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M32.26 17.51c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.5-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.84.3 1.74.76 2.38 1.44z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M32.15 17.63c-.24.23-.5.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.46-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.23 2.77-.18.92.05 1.86.21 2.73.5.85.29 1.73.73 2.4 1.37z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M31.36 18.24c-.06.04-.13.08-.19.12-.71-.42-1.51-.7-2.3-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26-.79.18-1.6.46-2.31.87-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.79.21 1.62.52 2.32.97z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M31.17 18.36c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.01-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.8.17 1.6.46 2.31.88z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M21.38 61.48v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24l-.06-.3c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.17.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.63-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M21.38 56.29v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.03-.16-.04-.31-.05-.47 0-.07-.01-.13-.01-.2v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.17.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2.66.12 1.34.19 2.02.23a16.542 16.542 0 003.03-.09c.63-.08 1.26-.19 1.86-.33.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.32h-.01z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M21.38 51.1v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09 16.638 16.638 0 01-2.99-.43c-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M21.38 45.9v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.85.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M21.38 40.71v3.28c0 .07 0 .14-.01.21 0 .04 0 .08-.01.12-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.19 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33v.31z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M21.38 35.52v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67v-3.6c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 006.91-.2c.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.09.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.33h-.01z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M21.38 30.32v3.28c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67V30c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39.01.06.02.11.04.17l.06.24c.02.06.04.13.06.19 0 .01.01.03.01.04.03.08.06.15.09.23.08.18.17.36.27.54.14.23.29.46.47.68.07.08.14.16.21.25.11.12.22.24.34.36.46.45 1.01.85 1.63 1.21.13.07.26.15.39.22.24.12.48.24.73.35.37.16.75.3 1.14.43.34.11.7.21 1.06.3.32.08.64.15.97.2a17.356 17.356 0 005.05.13c.63-.08 1.26-.18 1.86-.33.38-.09.75-.2 1.11-.31.67-.22 1.3-.48 1.87-.78.38-.2.74-.42 1.08-.65.11-.08.23-.16.34-.25.11-.08.22-.17.32-.26.31-.27.58-.55.83-.84.08-.1.16-.2.23-.3a5.016 5.016 0 00.63-1.21c.01-.02.02-.05.02-.07.04-.11.07-.22.1-.33.02-.07.03-.13.04-.2.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33v.31h-.01z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M19.75 21.32c.19.19.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07a4.143 4.143 0 01.17.66c.02.11.03.22.04.33.01.11.01.22.01.33V28.4c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67v-3.6c0-.04.01-.17.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3.32-.08.64-.15.97-.2a17.356 17.356 0 016.91.2c.37.09.73.19 1.08.3.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.08.22.17.32.26.09.1.19.19.28.28zM6.03 27.34c.92.54 2 .87 3.03 1.03 1.09.17 2.24.18 3.34.03 1.08-.15 2.21-.47 3.17-1.03.02-.01.03-.02.05-.03.06-.04.13-.08.19-.12.28-.18.55-.38.78-.61.04-.04.08-.08.11-.12.08-.08.15-.16.22-.25.34-.43.58-.94.61-1.5v-.11c0-.58-.22-1.1-.56-1.55-.34-.45-.78-.81-1.25-1.1-.94-.58-2.07-.92-3.15-1.09-1.09-.17-2.24-.18-3.34-.03-1.08.15-2.21.47-3.17 1.03-.5.29-.99.66-1.35 1.12-.37.46-.61 1.01-.61 1.62v.12c.03.53.24 1.01.56 1.43.08.11.17.22.27.32.04.04.08.08.11.12.24.23.5.44.78.61.03.02.05.03.08.05.05.01.09.04.13.06z"
                fill="#CC191B"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M17.53 24.74c-.03.56-.27 1.07-.61 1.51-.07.09-.14.17-.22.25-.64-.68-1.54-1.14-2.4-1.45-.88-.31-1.84-.48-2.77-.54-.93-.06-1.89 0-2.82.17-.91.17-1.84.47-2.65.94-.41.24-.8.53-1.13.87-.1-.1-.19-.21-.27-.32-.32-.42-.53-.9-.56-1.43v-.01c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.36.43.6.94.63 1.51z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M16.71 26.49c-.04.04-.08.08-.11.12-.66-.64-1.55-1.08-2.4-1.37-.88-.29-1.81-.46-2.73-.51-.92-.05-1.87.01-2.77.18-.9.17-1.81.47-2.61.93-.37.21-.71.46-1.02.76-.04-.04-.08-.08-.11-.12.33-.35.72-.64 1.13-.87.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.84.31 1.73.78 2.38 1.45z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M16.59 26.61c-.24.23-.51.43-.78.61-.71-.45-1.53-.76-2.33-.96-.82-.2-1.67-.3-2.51-.31-.84-.01-1.7.07-2.52.25-.81.18-1.64.47-2.37.88-.08.05-.16.09-.24.14-.28-.18-.54-.38-.78-.61.3-.3.65-.55 1.02-.76.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.28 1.74.72 2.4 1.36z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M15.81 27.22c-.06.04-.13.08-.19.12-.71-.42-1.51-.71-2.31-.89-.8-.18-1.64-.27-2.46-.28-.82 0-1.66.08-2.46.26-.79.18-1.6.46-2.31.87-.02.01-.03.02-.05.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05a8.068 8.068 0 012.61-1.02c.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.8.21 1.62.52 2.33.97z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
              <path
                d="M15.62 27.34c-.02.01-.03.02-.05.03-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03.02-.01.03-.02.05-.03.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.79.17 1.6.46 2.31.88z"
                fill="#750E10"
                transform="translate(.76 1.37) translate(75 54) translate(1)"
              />
            </g>
            <g
              stroke="#444"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.8725}
              transform="translate(.76 1.37) translate(75 54)"
            >
              <path d="M4.53 33.1a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M20.08 11.13c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43 17.356 17.356 0 013.03.1 15.682 15.682 0 012.94.63" />
              <path d="M16.82 15.83c0-.04.01-.16.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.79 1.42-1.43 2.27-1.93" />
              <path d="M20.67 15.76c.03-.58.29-1.11.65-1.54.36-.43.83-.8 1.31-1.08" />
              <path d="M21.61 17.63c.3-.3.65-.55 1.02-.76" />
              <path d="M21.5 17.51c.33-.35.72-.64 1.13-.87" />
              <line x1={53.49} y1={27.62} x2={53.49} y2={31.22} />
              <path d="M53.49 31.22c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <line x1={53.49} y1={22.43} x2={53.49} y2={26.03} />
              <path d="M53.49 26.03c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M53.41 26.82c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={36.17} y1={23.04} x2={36.18} y2={23.05} />
              <line x1={53.49} y1={43.2} x2={53.49} y2={44.11} />
              <path d="M53.49 46.8c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33a3.88 3.88 0 01-.17.66c-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <line x1={37.94} y1={52.18} x2={37.94} y2={52.5} />
              <path d="M37.94 55.78c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M37.94 50.59c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <line x1={53.49} y1={38.01} x2={53.49} y2={41.61} />
              <path d="M53.49 41.61c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M53.41 42.4c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M37.86 51.38c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={53.49} y1={44.11} x2={53.49} y2={46.8} />
              <line x1={37.94} y1={52.5} x2={37.94} y2={55.78} />
              <path d="M53.41 42.4c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <line x1={53.49} y1={17.23} x2={53.49} y2={20.84} />
              <path d="M53.49 20.84c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M1.26 35.19c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.26} y1={38.8} x2={1.26} y2={35.19} />
              <path d="M22.38 38.8c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <line x1={53.49} y1={32.81} x2={53.49} y2={36.41} />
              <path d="M53.49 36.41c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M1.26 45.58c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.26} y1={49.18} x2={1.26} y2={45.58} />
              <path d="M4.53 53.88a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M38.18 9.35c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.26 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.3.89" />
              <path d="M38.18 9.12c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96" />
              <line x1={53.49} y1={12.04} x2={53.49} y2={15.64} />
              <path d="M53.49 15.64c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M53.41 16.44c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M53.41 21.63c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <line x1={20.61} y1={42.41} x2={20.62} y2={42.42} />
              <path d="M53.41 37.21c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M53.41 37.21c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M53.41 32.02c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.63 16.64c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.86.3 1.75.77 2.4 1.45" />
              <path d="M22.63 13.14c.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M22.63 12.9c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.15 3.34.03 1.07.17 2.21.51 3.15 1.09.47.29.91.65 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M53.41 32.02c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M53.41 26.82c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.08-.7-.18-1.03-.29" />
              <path d="M37.86 51.38c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M53.41 21.63c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M1.26 50.77c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.26} y1={54.37} x2={1.26} y2={50.77} />
              <path d="M22.38 54.37c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M37.94 45.39c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M1.26 61.16c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.26} y1={64.76} x2={1.26} y2={61.16} />
              <path d="M22.38 64.76c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.02.11-.04.22-.07.33-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.53 69.46a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24l-.06-.3c-.04-.22-.06-.45-.06-.67" />
              <line x1={1.26} y1={59.57} x2={1.26} y2={55.97} />
              <path d="M22.38 59.57c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13" />
              <path d="M4.53 64.26a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M22.31 60.36c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.34 60.37a.585.585 0 01-.02-.13c-.03-.16-.04-.31-.05-.47" />
              <path d="M22.31 55.17c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M7.08 27.31c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.31.89h0" />
              <path d="M7.08 27.08c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96" />
              <path d="M1.26 30c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.26} y1={33.6} x2={1.26} y2={30} />
              <path d="M22.38 33.6c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M22.63 18.33c.71-.41 1.52-.69 2.31-.87.8-.18 1.64-.27 2.46-.26.82 0 1.66.09 2.46.28.79.18 1.6.47 2.3.89" />
              <path d="M22.63 18.1c.73-.42 1.55-.71 2.37-.88.82-.18 1.68-.26 2.52-.25.84.01 1.7.11 2.51.31.81.2 1.63.51 2.33.96 0 0 0 0 0 0" />
              <path d="M37.94 24.62c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <line x1={20.61} y1={37.21} x2={20.62} y2={37.22} />
              <path d="M37.94 35.01c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M1.26 40.39c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <line x1={1.26} y1={43.99} x2={1.26} y2={40.39} />
              <path d="M22.38 43.99c0 .07 0 .14-.01.21" />
              <path d="M38.18 7.66c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.81-.17.93.06 1.88.23 2.77.54.86.3 1.76.77 2.4 1.45" />
              <path d="M38.18 4.16c.95-.55 2.07-.87 3.14-1.02 1.09-.16 2.22-.15 3.31.01 1.07.16 2.19.5 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M38.18 7.89c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.29 1.74.73 2.4 1.37" />
              <path d="M38.18 3.92c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.14 3.34.03 1.07.17 2.2.51 3.14 1.09.47.29.92.66 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M36.22 6.66c0-.61.25-1.16.62-1.62.36-.46.85-.83 1.35-1.12" />
              <path d="M35.64 2.15c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3a16.869 16.869 0 012.99-.43c.68-.04 1.36-.04 2.04.01.33.02.66.05.99.09.64.08 1.26.19 1.86.33.38.09.75.19 1.11.31.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.09.22.17.32.26.31.27.58.55.83.85a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07a4.143 4.143 0 01.17.66c.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M32.37 6.85c0-.04.01-.17.01-.21 0-.07.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93" />
              <line x1={53.49} y1={6.85} x2={53.49} y2={10.45} />
              <path d="M53.49 10.45c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14" />
              <path d="M36.22 6.77c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07" />
              <path d="M37.17 8.65c.3-.3.65-.55 1.02-.76" />
              <path d="M37.05 8.53c.33-.35.72-.64 1.13-.87" />
              <path d="M5.11 24.63c0-.61.25-1.16.61-1.62.37-.46.85-.83 1.35-1.12" />
              <path d="M4.53 20.11c.13-.07.26-.15.39-.22.24-.12.48-.24.73-.35.37-.16.75-.3 1.14-.43.34-.11.7-.21 1.06-.3.32-.08.64-.15.97-.2a17.356 17.356 0 016.91.2c.37.09.73.19 1.08.3" />
              <path d="M1.26 24.81c0-.04.01-.17.01-.21 0-.06.01-.13.01-.19.01-.12.03-.23.05-.35.04-.25.11-.49.2-.72.17-.47.42-.91.73-1.3.61-.8 1.42-1.43 2.27-1.93" />
              <line x1={1.26} y1={28.41} x2={1.26} y2={24.81} />
              <path d="M5.11 24.73c.03-.58.29-1.1.65-1.54.36-.44.83-.79 1.31-1.07" />
              <path d="M6.06 26.61c.3-.3.65-.55 1.02-.76" />
              <path d="M5.94 26.49c.33-.35.72-.64 1.13-.87" />
              <path d="M6.84 27.22c.08-.05.16-.1.24-.14" />
              <path d="M7.03 27.34s0 0 0 0c.02-.01.03-.02.05-.03" />
              <path d="M22.31 29.21c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M20.75 21.32c-.01.01-.02.01-.03.02" />
              <path d="M1.34 29.21a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <line x1={16.82} y1={19.11} x2={16.82} y2={15.83} />
              <path d="M37.86 35.8c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.31 34.4c.01.04.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.34 49.98a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67" />
              <path d="M37.95 9.26c.08-.05.16-.1.24-.14" />
              <path d="M53.41 11.24c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M38.14 9.38c.01-.01.03-.02.05-.03" />
              <path d="M1.34 39.59a.585.585 0 01-.02-.13 3.71 3.71 0 01-.06-.67" />
              <path d="M37.86 46.19c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M37.86 25.42c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.31 44.79c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M36.3 12.34c-.01.01-.02.01-.03.02" />
              <path d="M37.86 46.19c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M1.27 59.76c0-.07-.01-.13-.01-.2" />
              <path d="M22.39 18.24h0c.08-.05.16-.1.24-.14" />
              <path d="M37.86 20.22c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.58 18.36c.01-.01.03-.02.05-.03" />
              <line x1={32.37} y1={10.13} x2={32.37} y2={6.85} />
              <path d="M53.41 11.24c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <path d="M22.31 49.98c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.34 34.4a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <path d="M37.86 41c.01.05.02.09.02.13.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M1.34 44.79a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <path d="M37.86 30.61c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.31 39.59c.01.05.02.09.02.14.02.11.03.22.04.33.01.11.01.22.01.33" />
              <path d="M22.63 16.87c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.23 2.77-.18.92.05 1.86.21 2.73.5.85.29 1.74.73 2.4 1.37" />
              <path d="M32.12 18.38c-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43 0 0 0 0 0 0v-.11" />
              <path d="M34.09 15.64v.12c-.03.68-.38 1.28-.83 1.75-.04.04-.08.08-.11.12" />
              <path d="M33.15 17.63c-.24.23-.5.43-.78.61-.06.04-.13.08-.19.12h0c-.02.01-.03.02-.05.03" />
              <path d="M32.37 10.13c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.08.22.17.32.26l.3.27c.19.18.37.38.53.58a5.016 5.016 0 01.86 1.51c.01.02.02.05.02.07.04.11.07.22.1.33a3.712 3.712 0 01.12.99" />
              <polyline points="37.94 15.83 37.94 16.15 37.94 19.43" />
              <path d="M37.94 19.43c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="37.94 46.99 37.94 47.31 37.94 50.59" />
              <polyline points="37.94 26.21 37.94 26.53 37.94 29.82" />
              <path d="M37.94 29.82c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a16.638 16.638 0 01-2.99-.43c-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.38 35.19 22.38 35.52 22.38 38.8" />
              <path d="M22.31 39.59c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.53 43.49a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <polyline points="37.94 36.6 37.94 36.92 37.94 40.2" />
              <path d="M37.94 40.2c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a16.638 16.638 0 01-2.99-.43c-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.38 45.58 22.38 45.9 22.38 49.18" />
              <path d="M22.38 49.18c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.13" />
              <path d="M22.31 49.98c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M53.41 16.44c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.38 50.77 22.38 51.1 22.38 54.37" />
              <path d="M22.31 55.17c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09 16.638 16.638 0 01-2.99-.43c-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.53 59.07a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36a6.325 6.325 0 01-.68-.93c-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M1.34 55.17a.585.585 0 01-.02-.13c-.04-.22-.06-.45-.06-.67" />
              <polyline points="37.94 41.79 37.94 42.11 37.94 45.39" />
              <polyline points="22.38 61.16 22.38 61.48 22.38 64.76" />
              <path d="M1.26 55.97c0-.04.01-.16.01-.2 0-.07.01-.13.02-.2.01-.13.03-.26.06-.39" />
              <polyline points="22.38 55.97 22.38 56.29 22.38 59.57" />
              <path d="M22.31 60.36c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <polyline points="22.38 30 22.38 30.32 22.38 33.6" />
              <path d="M22.31 34.4c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.53 38.3a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <polyline points="37.94 21.02 37.94 21.34 37.94 24.62" />
              <path d="M37.86 25.42c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33a16.542 16.542 0 01-3.03.09c-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="37.94 31.41 37.94 31.73 37.94 35.01" />
              <path d="M37.86 35.8c-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.21-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.17-.32.26-.11.09-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01-.69-.04-1.36-.11-2.02-.23-.33-.06-.65-.13-.97-.2-.35-.09-.7-.18-1.03-.29" />
              <polyline points="22.38 40.39 22.38 40.71 22.38 43.99" />
              <path d="M22.38 44.2c0 .04 0 .08-.01.12-.01.11-.02.22-.04.33-.01.04-.02.09-.02.13 0 0 0 0 0 0-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a6.634 6.634 0 01-1.06 1.15c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M4.53 48.68a8.22 8.22 0 01-1.63-1.21c-.12-.12-.24-.24-.34-.36-.07-.08-.14-.16-.21-.25-.17-.22-.33-.45-.47-.68-.1-.17-.19-.35-.27-.54-.03-.08-.06-.15-.09-.23-.01-.01-.01-.03-.01-.04-.02-.06-.04-.13-.06-.19-.02-.08-.05-.16-.06-.24-.01-.06-.03-.11-.04-.17" />
              <path d="M47.68 9.41c-.96.55-2.09.88-3.17 1.03-1.1.15-2.25.14-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.06-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.31-.42-.53-.9-.56-1.43v-.01-.11" />
              <path d="M49.65 6.66v.11s0 0 0 0c-.03.56-.27 1.07-.61 1.5-.07.09-.14.17-.22.25-.04.04-.08.08-.11.12-.24.23-.5.43-.78.61-.06.04-.13.08-.19.12h0c-.02.01-.03.02-.05.03" />
              <path d="M7.08 25.62c.81-.47 1.74-.77 2.65-.94.92-.17 1.88-.23 2.82-.17.93.06 1.88.23 2.77.54.86.3 1.75.77 2.4 1.45" />
              <path d="M7.08 22.12c.95-.55 2.07-.87 3.14-1.02 1.09-.15 2.22-.15 3.31.01 1.07.16 2.19.49 3.13 1.06.47.28.92.63 1.26 1.06.35.43.59.94.62 1.51" />
              <path d="M7.08 25.85c.8-.46 1.72-.76 2.61-.93.91-.18 1.85-.24 2.77-.18.92.05 1.86.21 2.73.51.85.29 1.74.73 2.4 1.37" />
              <path d="M7.08 21.88c.96-.55 2.09-.88 3.17-1.03 1.1-.15 2.24-.14 3.34.03 1.07.17 2.21.51 3.15 1.09.47.29.91.65 1.25 1.1.34.45.56.97.56 1.55" />
              <path d="M16.57 27.37c-.96.55-2.09.88-3.17 1.03-1.1.15-2.24.15-3.34-.03-1.03-.16-2.12-.49-3.03-1.03a.673.673 0 01-.11-.07c-.03-.02-.05-.03-.08-.05-.28-.18-.54-.38-.78-.61 0 0 0 0 0 0-.04-.04-.08-.08-.11-.12 0 0 0 0 0 0-.1-.1-.19-.21-.27-.32-.32-.42-.53-.9-.56-1.43v-.01-.11" />
              <path d="M18.54 24.63v.11s0 0 0 0c-.03.56-.27 1.07-.61 1.5-.07.09-.14.17-.22.25-.04.04-.08.08-.11.12-.24.23-.51.43-.78.61-.06.04-.13.08-.19.12-.02.01-.03.02-.05.03" />
              <path d="M16.82 19.11c.01 0 .02.01.03.01.67.22 1.3.48 1.87.78.38.2.74.42 1.08.65.11.08.23.16.34.25.11.08.22.17.32.26l.3.27a7.126 7.126 0 01.76.88 5.016 5.016 0 01.63 1.21c.01.02.02.05.02.07a3.88 3.88 0 01.21.99c.01.11.01.22.01.33" />
              <polyline points="22.38 24.81 22.38 25.13 22.38 28.41" />
              <path d="M22.38 28.41c0 .11 0 .22-.01.33-.01.11-.02.22-.04.33-.01.05-.02.09-.02.14-.01.07-.03.13-.04.2-.03.11-.06.22-.1.33-.01.02-.02.05-.02.07-.07.2-.15.39-.25.58-.11.22-.24.42-.38.63a5.98 5.98 0 01-1.06 1.14c-.1.09-.21.18-.32.26-.11.08-.22.17-.34.25-.33.23-.7.45-1.08.65a12.923 12.923 0 01-2.98 1.09c-.6.14-1.22.25-1.86.33-.33.04-.66.07-.99.09-.68.04-1.36.04-2.04.01a18.9 18.9 0 01-2.02-.23c-.33-.06-.65-.13-.97-.2-.36-.09-.72-.19-1.06-.3a11.734 11.734 0 01-1.87-.78c-.13-.07-.27-.14-.39-.22" />
              <path d="M20.66 15.64c0-.61.25-1.16.61-1.62.37-.46.85-.83 1.35-1.12" />
            </g>
          </g>
          <g transform="translate(.76 1.37) translate(93.41 58.04)">
            <path
              d="M48.74 0C34.45 8.25 20.17 16.5 5.88 24.74c-.75-.43-1.5-.87-2.25-1.3a.108.108 0 00-.11 0c-1.01.58-2.01 1.16-3.02 1.74V78.16c1.13.65 2.26 1.31 3.39 1.96v2.9h.01c.01 0 .02 0 .03-.01 0 0 .01 0 .01-.01 1.01-.58 2.01-1.16 3.02-1.74.04-.02.08-.05.12-.07v-1.42c.82-.48 1.65-.95 2.47-1.43.41.24.82.47 1.23.71.03-.02.06-.04.1-.06 11.88-6.86 23.76-13.72 35.65-20.58.05-.03.11-.06.16-.09V56.9c.87-.5 1.73-1 2.6-1.5.41.24.82.47 1.23.71.05-.03.11-.06.16-.09v-.12-1.48-2.35c0-.14-.1-.31-.22-.38-.08-.05-.16-.09-.24-.14V3.09v-.03c.04-.02.07-.04.11-.06.03-.02.04-.06.04-.09V.92c-.55-.3-1.09-.61-1.63-.92z"
              fill="#002C6F"
              fillRule="nonzero"
            />
            <line
              x1={17.88}
              y1={67.93}
              x2={18.46}
              y2={67.93}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={18.35}
              y1={71.99}
              x2={18.35}
              y2={71.99}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={18.24}
              y1={71.95}
              x2={18.24}
              y2={71.95}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={19.84}
              y1={20.61}
              x2={19.84}
              y2={20.62}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={18.39}
              y1={22.01}
              x2={18.39}
              y2={22.01}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.88 67.93s0 0 0 0c-.05 0-.1.01-.14.03l-.27.15c-.13.08-.24.26-.24.41v1.51c0 .05.02.12.07.15l.06.03c.05.03.12.01.17-.02.06-.04.17-.1.24-.14.01-.01.02-.01.03-.02.04-.02.11-.02.15 0 0 0 0 0 0 0s0 0 0 0c.2.12.4.23.6.35.18.1.43.13.62.1.1-.02.21-.05.3-.1s.17-.14.17-.25v-1.07s0 0 0 0v-.98c0-.08-.02-.15-.08-.2"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.58 24.48v43.53c0 .1-.08.17-.15.22-.08.05-.17.08-.26.09a.84.84 0 01-.54-.08c-.08-.05-.16-.12-.16-.23V39.4 24.5"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.48 70.66c.11-.14.17-.24.17-.32v-.11c-.04.1-.13.16-.21.21-.09.04-.19.07-.29.08-.19.02-.42 0-.59-.1-.06-.03-.11-.07-.17-.1v.03a.6.6 0 00.18.33v.02h0l-.42.24s0 0 0 0h-.01c-.08.05-.15.16-.15.26v.01s0 0 0 0v.43c0 .13.11.24.24.32.02.01.04.02.06.04 0 0 0 0 0 0s0 0 0 0h.03s0 0 0 0 0 0 0 0 0 0 0 0H18.51c.02 0 .05-.01.07-.01.01 0 .03 0 .04-.01.08-.02.2-.05.28-.08.01 0 .01 0 .02-.01.01 0 .02-.01.03-.01.01 0 .02-.01.03-.01.01 0 .02-.01.03-.01.01 0 .02-.01.03-.01l.06-.03.06-.03c.02-.01.04-.02.05-.04.01-.02.03-.02.05-.04.01-.01.02-.01.02-.02.01-.01.02-.01.02-.02.01-.01.02-.01.02-.02.01-.01.02-.01.02-.02l.01-.01c.05-.05.1-.11.14-.17.06-.09.14-.2.14-.3v-.09-.03c0-.01-.01-.02-.01-.03 0-.01-.01-.02-.01-.02 0-.01-.01-.02-.01-.02a.304.304 0 00-.05-.07l-.06-.06c-.02-.08-.01-.13-.01-.17z"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.65 22.16c.01.03.01.05.01.08v.92s0 0 0 0v1.14c0 .11-.09.2-.17.25-.09.06-.2.09-.3.1-.2.03-.44.01-.62-.1-.2-.12-.4-.23-.6-.35-.03-.02-.07-.02-.1-.02 0 0 0 0 0 0-.02 0-.04.01-.06.02-.14.08-.32.18-.46.15-.08-.02-.1-.1-.1-.17v-1.51c0-.15.11-.34.24-.41l.27-.15c.08-.04.2-.04.28 0 .13.08.27.16.4.24"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.62 20.39c.09.05.2.16.24.25.03.06.06.12.08.18.04.08.03.2-.01.28-.03.06-.05.12-.08.17-.04.09-.12.09-.22.04l-.06-.03c-.01.01-.03.02-.04.03l-.01.01-.01.01-.01.01-.01.01v.01c-.01.02-.02.03-.03.05-.01.02-.02.04-.02.05v.02s0 0 0 0v.01c.01.02.02.04.02.06.01.04.02.08.02.12.12.09.18.2.18.33v.23c0 .11-.09.2-.17.25-.09.05-.19.09-.29.1-.19.03-.43.01-.61-.09-.09-.05-.19-.14-.19-.26V22c0-.12.05-.22.16-.32v-.1c-.03.01-.05.02-.08.03-.01.01-.03.01-.04.01h-.04c-.01 0-.01 0-.02-.01-.14-.08-.27-.16-.41-.23-.02-.01-.03-.02-.03-.04-.08-.18-.05-.41 0-.58a.38.38 0 01.14-.19h-.01l.87-.51s.01 0 .01-.01c.03-.02.08-.03.11-.01.2.11.39.22.59.33"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={15.72}
              y1={71.35}
              x2={8.05}
              y2={75.74}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={26.54}
              y1={65.16}
              x2={19.67}
              y2={69.09}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={17.97}
              y1={70.06}
              x2={15.72}
              y2={71.35}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={23.53}
              y1={18.48}
              x2={19.84}
              y2={20.61}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={18.22}
              y1={21.55}
              x2={15.9}
              y2={22.89}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={48.88}
              y1={45.23}
              x2={48.88}
              y2={21.47}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={38.08}
              y1={56.26}
              x2={38.67}
              y2={56.26}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={38.55}
              y1={60.33}
              x2={38.55}
              y2={60.32}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={38.44}
              y1={60.28}
              x2={38.44}
              y2={60.28}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={40.05}
              y1={8.95}
              x2={40.05}
              y2={8.95}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={38.6}
              y1={10.35}
              x2={38.6}
              y2={10.34}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M38.09 56.26s-.01 0 0 0c-.05 0-.1.01-.14.03l-.27.15c-.13.08-.24.26-.24.41v1.51c0 .05.02.12.07.15l.06.03c.05.03.12.01.17-.02.06-.04.17-.1.24-.14.01-.01.02-.01.03-.02.04-.02.11-.02.15 0 0 0 0 0 0 0s0 0 0 0c.2.12.4.23.6.35.18.1.43.13.62.1.1-.02.21-.05.3-.1s.17-.14.17-.25v-1.07s0 0 0 0v-.98c0-.08-.02-.15-.08-.2"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M39.78 12.81v43.53c0 .1-.08.17-.15.22-.08.05-.17.08-.26.09a.84.84 0 01-.54-.08c-.08-.05-.16-.12-.16-.23V27.73v-14.9"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M39.69 58.99c.11-.14.17-.24.17-.32v-.11c-.04.1-.13.16-.21.21-.09.04-.19.07-.29.08-.19.02-.42 0-.59-.1-.06-.03-.11-.07-.17-.1v.03a.6.6 0 00.18.33v.02h0l-.42.24s0 0 0 0h-.01c-.08.05-.15.16-.15.26v.01s0 0 0 0v.43c0 .13.11.24.24.32.02.01.04.02.06.04 0 0 0 0 0 0s0 0 0 0h.03s0 0 0 0 0 0 0 0 0 0 0 0H38.68c.01 0 .02 0 .04-.01.02 0 .05-.01.07-.01.01 0 .03 0 .04-.01.08-.02.2-.05.28-.08.01 0 .01 0 .02-.01.01 0 .02-.01.03-.01.01 0 .02-.01.03-.01.01 0 .02-.01.03-.01.01 0 .02-.01.03-.01l.06-.03.06-.03c.02-.01.04-.02.05-.04.01-.02.03-.02.05-.04.01-.01.02-.01.02-.02s.02-.01.02-.02c.01-.01.02-.01.02-.02.01-.01.02-.01.02-.02l.01-.01c.05-.05.1-.11.14-.17.06-.09.14-.2.14-.3v-.09-.03c0-.01-.01-.02-.01-.03 0-.01-.01-.02-.01-.02 0-.01-.01-.02-.01-.02a.304.304 0 00-.05-.07l-.06-.06a.304.304 0 01-.01-.16z"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M39.85 10.5c.01.03.01.05.01.08v.92s0 0 0 0v1.14c0 .11-.09.2-.17.25-.09.06-.2.09-.3.1-.2.03-.44.01-.62-.1-.2-.12-.4-.23-.6-.35-.03-.02-.07-.02-.1-.02 0 0 0 0 0 0-.02 0-.04.01-.06.02-.14.08-.32.18-.46.15-.08-.02-.1-.1-.1-.17v-1.51c0-.15.11-.34.24-.41l.27-.15c.08-.04.2-.04.28 0 .13.08.27.16.4.24"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M39.82 8.72c.09.05.2.16.24.25.03.06.06.12.08.18.04.08.03.2-.01.28-.03.06-.05.12-.08.17-.04.09-.12.09-.22.04l-.06-.03c-.01.01-.03.02-.04.03l-.01.01-.01.01-.01.01-.01.01v.01c-.01.02-.02.03-.03.05-.01.02-.02.04-.02.05v.02s0 0 0 0v.01c.01.02.02.04.02.06.01.04.02.08.02.12.12.09.18.2.18.33v.23c0 .11-.09.2-.17.25-.09.05-.19.09-.29.1-.19.03-.43.01-.61-.09-.09-.05-.19-.14-.19-.26v-.23c0-.12.05-.22.16-.32v-.1c-.03.01-.05.02-.08.03-.01.01-.03.01-.04.01h-.04c-.01 0-.01 0-.02-.01-.14-.08-.27-.16-.41-.23-.02-.01-.03-.02-.03-.04-.08-.18-.05-.41 0-.58a.38.38 0 01.14-.19h-.01l.87-.51s.01 0 .01-.01c.03-.02.08-.03.11-.01.2.11.39.22.59.33"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={43.93}
              y1={6.71}
              x2={40.05}
              y2={8.95}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={38.43}
              y1={9.88}
              x2={23.53}
              y2={18.48}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={30.65}
              y1={62.79}
              x2={38.19}
              y2={58.4}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={39.87}
              y1={57.42}
              x2={48.88}
              y2={52.17}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={0.5}
              y1={25.19}
              x2={3.52}
              y2={23.44}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={3.63}
              y1={23.44}
              x2={6.97}
              y2={25.37}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={15.9}
              y1={22.89}
              x2={6.97}
              y2={28.04}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={50.36}
              y1={2.92}
              x2={50.36}
              y2={0.94}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={5.89}
              y1={24.74}
              x2={48.74}
              y2={-2.84217094e-14}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={3.89}
              y1={83.02}
              x2={3.89}
              y2={30.12}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={0.5}
              y1={78.16}
              x2={0.5}
              y2={25.19}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={3.73}
              y1={30.07}
              x2={0.64}
              y2={28.29}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.16 30.07c-.06.03-.13.05-.19.05"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.91 30.12c-.06 0-.13-.02-.19-.05"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M.5 28.01c0 .11.05.22.14.27"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={4.16}
              y1={30.07}
              x2={6.97}
              y2={28.45}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={6.97}
              y1={25.37}
              x2={6.97}
              y2={78.35}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={10.8}
              y1={77.64}
              x2={9.63}
              y2={78.31}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={10.8}
              y1={79.06}
              x2={10.8}
              y2={77.64}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={10.89}
              y1={79}
              x2={10.89}
              y2={77.45}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={10.89}
              y1={79}
              x2={10.8}
              y2={79.06}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={10.8}
              y1={79.06}
              x2={9.57}
              y2={78.35}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={6.97}
              y1={79.72}
              x2={10.89}
              y2={77.45}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={3.89}
              y1={80.12}
              x2={6.97}
              y2={78.35}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.09}
              y1={79.78}
              x2={7.09}
              y2={81.19}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={9.63}
              y1={78.31}
              x2={7.09}
              y2={79.78}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={6.97}
              y1={78.35}
              x2={6.97}
              y2={81.26}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={46.54}
              y1={58.42}
              x2={10.89}
              y2={79}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={46.7}
              y1={58.33}
              x2={46.7}
              y2={56.91}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={46.54}
              y1={58.42}
              x2={46.7}
              y2={58.33}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={46.54}
              y1={58.42}
              x2={46.54}
              y2={56.82}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={50.52}
              y1={56.12}
              x2={50.68}
              y2={56.03}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={50.68}
              y1={55.91}
              x2={50.68}
              y2={56.03}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={50.52}
              y1={54.7}
              x2={50.52}
              y2={56.12}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.35}
              y1={55.38}
              x2={50.52}
              y2={54.7}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={46.7}
              y1={56.91}
              x2={49.35}
              y2={55.38}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={46.54}
              y1={56.82}
              x2={50.68}
              y2={54.43}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={50.68}
              y1={54.43}
              x2={50.68}
              y2={55.91}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={50.52}
              y1={56.12}
              x2={49.29}
              y2={55.41}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M50.68 52.08c0-.14-.1-.31-.22-.38"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={50.68}
              y1={54.43}
              x2={50.68}
              y2={52.08}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M50.68 53.07c.05-.01.11-.02.16-.05"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={50.46}
              y1={51.71}
              x2={50.22}
              y2={51.57}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.52 23.44c.03-.02.08-.02.11 0"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={28.64}
              y1={63.96}
              x2={30.63}
              y2={62.8}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.96}
              y1={35.73}
              x2={7.96}
              y2={68.85}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={28.6}
              y1={63.99}
              x2={28.6}
              y2={15.56}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={28.48}
              y1={15.63}
              x2={28.48}
              y2={64.05}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.83 33.4a.43.43 0 00.1-.09"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.96 35.73c0 .08-.07.15-.13.18"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.81}
              y1={35.82}
              x2={7.81}
              y2={35.82}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.81 35.82c-.14.08-.34.09-.49.04"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.93 33.3c.02-.03.03-.06.03-.09"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.95 35.68c.01.02.01.03.01.05"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.96}
              y1={33.21}
              x2={7.96}
              y2={33.21}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.32 35.86c.15.05.35.04.49-.04"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.93 35.7c-.02.06-.07.1-.12.12"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.32}
              y1={33.69}
              x2={7.32}
              y2={36.21}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.96}
              y1={33.21}
              x2={8.05}
              y2={33.16}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.83 69.03a.43.43 0 00.1-.09"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={30.63}
              y1={62.8}
              x2={30.65}
              y2={62.79}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={48.88}
              y1={4.02}
              x2={48.88}
              y2={3.97}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={28.6}
              y1={63.99}
              x2={28.64}
              y2={63.96}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.02}
              y1={9.51}
              x2={48.88}
              y2={9.59}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.02 9.51c.12.02.25.01.36-.03"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.44 11.79c.06-.03.12-.09.12-.17"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.38 9.48c.02 0 .04-.01.06-.02"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.02}
              y1={45.15}
              x2={48.88}
              y2={45.23}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.02 45.15c.12.02.25.01.36-.03"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.44 47.42c.06-.03.12-.09.12-.17"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.38 45.11c.02 0 .04-.01.06-.02"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.44 9.4c-.16.09-.4.09-.56.02"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.44 45.03c-.16.09-.4.09-.56.02"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.44}
              y1={11.79}
              x2={49.44}
              y2={11.79}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.43 11.79c-.06.04-.14.06-.21.07"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.44}
              y1={47.42}
              x2={49.44}
              y2={47.42}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={48.88}
              y1={9.41}
              x2={48.88}
              y2={9.41}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.02}
              y1={45.55}
              x2={49.02}
              y2={45.55}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.22 11.85c.07-.01.15-.03.21-.07"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={48.72}
              y1={45.73}
              x2={48.72}
              y2={45.72}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.02}
              y1={9.91}
              x2={49.02}
              y2={9.92}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={48.72}
              y1={10.09}
              x2={48.72}
              y2={10.09}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.44}
              y1={11.79}
              x2={49.43}
              y2={11.79}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.44}
              y1={47.42}
              x2={49.43}
              y2={47.43}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.22 47.49c.07-.01.15-.03.21-.07"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.43 47.43c-.06.04-.14.06-.21.07"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={48.88}
              y1={44.67}
              x2={48.88}
              y2={44.67}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.45}
              y1={11.88}
              x2={49.97}
              y2={11.58}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.97}
              y1={9.07}
              x2={49.97}
              y2={11.58}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.45}
              y1={52.29}
              x2={50.09}
              y2={51.92}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.97}
              y1={44.7}
              x2={49.97}
              y2={47.22}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.45 45c-.16.09-.41.1-.58.03"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.45 9.37c-.16.09-.41.1-.58.03"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M50.09 51.92c.06-.04.13-.1.13-.18"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.97}
              y1={47.22}
              x2={49.56}
              y2={46.98}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.97}
              y1={11.58}
              x2={49.56}
              y2={11.34}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.83}
              y1={71.55}
              x2={7.32}
              y2={71.84}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.83}
              y1={76.32}
              x2={7.2}
              y2={76.69}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.83}
              y1={35.91}
              x2={7.32}
              y2={36.21}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.2 76.69c-.07.04-.15.06-.23.07"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.96 71.36c0 .08-.07.15-.13.18"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.96 76.14c0 .08-.07.15-.13.18"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.96 27.5v-.03"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.45}
              y1={47.51}
              x2={49.97}
              y2={47.22}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.52 9.33a.27.27 0 01-.08.07"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={48.82}
              y1={3.88}
              x2={48.82}
              y2={3.93}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M50.22 3.08v.03"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.06 11.95c.13.01.28 0 .4-.07"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.06 47.59c.13.01.28 0 .4-.07"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={50.22}
              y1={51.63}
              x2={50.22}
              y2={51.63}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M48.82 52.29c.17.1.46.1.63 0"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={26.49}
              y1={65.19}
              x2={28.48}
              y2={64.05}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={8.05}
              y1={75.74}
              x2={8.01}
              y2={75.76}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={28.48}
              y1={64.05}
              x2={28.48}
              y2={64.05}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.81}
              y1={71.46}
              x2={7.81}
              y2={71.45}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.81 71.45c-.14.08-.34.09-.49.04"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.93 68.94c.02-.03.03-.06.03-.09"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.95 71.31c.01.02.01.03.01.05"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.96}
              y1={68.85}
              x2={7.96}
              y2={68.85}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.32 71.5c.15.05.35.04.49-.04"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.93 71.33c-.02.06-.07.1-.12.12"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={48.88}
              y1={52.17}
              x2={48.88}
              y2={47.69}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={48.88}
              y1={9.59}
              x2={48.88}
              y2={4.02}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={48.88}
              y1={21.47}
              x2={48.88}
              y2={12.05}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={28.6}
              y1={63.99}
              x2={28.48}
              y2={63.92}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={48.88}
              y1={3.97}
              x2={48.78}
              y2={3.91}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={48.88}
              y1={9.04}
              x2={48.88}
              y2={9.04}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.56}
              y1={9.31}
              x2={49.56}
              y2={11.61}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.56}
              y1={44.94}
              x2={49.56}
              y2={47.25}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.52 44.96c-.02.03-.05.05-.08.07"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={50.32}
              y1={3.01}
              x2={43.93}
              y2={6.71}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M50.32 3.01c.03-.02.04-.06.04-.09"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.45}
              y1={9.37}
              x2={49.97}
              y2={9.07}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={49.45}
              y1={45}
              x2={49.97}
              y2={44.7}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M48.76 52.24c.02.02.04.04.07.05"
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={50.22}
              y1={3.11}
              x2={50.22}
              y2={51.74}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.83}
              y1={69.03}
              x2={7.32}
              y2={69.33}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.32}
              y1={69.33}
              x2={7.32}
              y2={71.84}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.96}
              y1={71.36}
              x2={7.96}
              y2={76.14}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.96}
              y1={27.5}
              x2={7.96}
              y2={33.21}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1={7.96}
              y1={68.85}
              x2={8.05}
              y2={68.8}
              stroke="#FFF"
              strokeWidth={0.7271}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}