import React, { Component } from 'react';
import './NavMenu.css';
import AuthorizedUser from './api-authorization/AuthorizedUser';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { UserData } from 'react-oidc';
import { OrganizationDetails } from '../models/dto/Organization';
import OrganizationMetaApi from '../apis/OrganizationMetaApi';
import sheet from '../tools/style'

interface NavMenuState {
  organization?: OrganizationDetails
}

export class NavMenu extends Component<{organizationId?: string}, NavMenuState> {
  static displayName = NavMenu.name;

  constructor(props: any) {
    super(props);

    this.state = {
      
    };
  }

  async componentDidMount() {
    if (this.props.organizationId) {
      const org = await OrganizationMetaApi.getInstance().getOrFetch(this.props.organizationId)
      if (org) {
        sheet(org!.cssOverrides!)
        this.setState({
          ...this.state, 
          organization: org
        })
      }
      else {
        sheet(`.bg-primary { 
          color: #fff;
        }
        .secondary-text {
          color: #fff !important; 
        }`)
      }
    }
  }

  render() {
    return (
      <header>
        <Navbar fixed='top' bg='primary' variant='dark'>
          <Navbar.Collapse>
            <Nav className='mr-auto'>
              <Nav.Link href='./' className="d-none d-sm-block"><AuditKitSvgComponent height={30} /></Nav.Link>
            </Nav>
          </Navbar.Collapse>
          {this.state.organization && 
            <span className="navbar-brand mx-auto">
              <img id="navIcon" alt={this.state.organization.name} src={`data:${this.state.organization.logoContentType};base64,${this.state.organization.logo}`} />
            </span>}
          <Navbar.Collapse className="d-sm-inline-flex flex-sm-row-reverse">
           <Nav className="ml-auto">
             <UserData.Consumer>
               {context => 
                <NavDropdown id='auth' title={`${context.user?.profile.name}`}>
                  {new AuthorizedUser(context.user).isAdmin && 
                    <><NavDropdown.Item href='./object-types'>Types d'objets</NavDropdown.Item>
                    <NavDropdown.Divider /></>}
                  <NavDropdown.Item href='https://auditkit.bluegriot.com/identity/Manage'>
                    Profil
                 </NavDropdown.Item>
                 <NavDropdown.Divider />
                 <NavDropdown.Item onClick={async () => await context.userManager?.signoutRedirect()}>
                     Déconnexion
                 </NavDropdown.Item>
                </NavDropdown>
             }
             </UserData.Consumer>
           </Nav>
         </Navbar.Collapse>
        </Navbar>
      </header>
    );
  }
}

function AuditKitSvgComponent(props: {height: number}) {
  return (
    <svg viewBox="0 0 776 196" {...props}>
      <g fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <path
          fill="#FFF"
          d="M148.31 152.72L140.76 150.45 173.37 41.74 209.28 41.74 242.6 151.75 214.15 151.75 207.44 127.39 174.19 127.39 167.07 152.67 159.48 150.53 168.22 119.5 213.44 119.5 220.16 143.86 231.97 143.86 203.43 49.63 179.24 49.63z"
        />
        <path
          d="M211.38 112.36h-41.3l15.49-55.15h10.66l15.15 55.15zm-30.89-7.89h20.55l-10.16-36.99-10.39 36.99zM381.08 151.75h-18.01v-7.89h18.01c28.94 0 43.62-14.18 43.62-42.16v-9.92c0-27.97-14.68-42.16-43.62-42.16h-28.22v102.12h-7.89v-110h36.1c33.21 0 51.5 17.77 51.5 50.04v9.92c.02 32.28-18.28 50.05-51.49 50.05zm-1.45-15.47h-16.55V57.2h16.55c23.17 0 34.43 11.31 34.43 34.58v10.07c0 23.17-11.26 34.43-34.43 34.43zm-8.67-7.88h8.67c18.61 0 26.55-7.94 26.55-26.55V91.78c0-18.71-7.94-26.69-26.55-26.69h-8.67v63.31zM285.99 151.75h-18.01v-7.89h18.01c28.94 0 43.62-14.18 43.62-42.16V49.62h-10.64v52.22c0 23.17-11.26 34.43-34.43 34.43h-16.55V49.61h-10.2v102.12h-7.89V41.74h25.98v86.66h8.67c18.61 0 26.55-7.94 26.55-26.55V41.74h26.41v59.96c-.02 32.28-18.31 50.05-51.52 50.05z"
          fill="#FFF"
        />
        <path
          fill="#FFF"
          d="M466.2 153.72L458.31 153.72 458.31 49.63 448.11 49.63 448.11 153.72 440.22 153.72 440.22 41.74 466.2 41.74z"
        />
        <path
          fill="#FFF"
          d="M527.9 153.72L520.01 153.72 520.01 57.35 547.72 57.35 547.72 49.63 482.1 49.63 482.1 57.35 509.95 57.35 509.95 153.72 502.07 153.72 502.07 65.24 474.21 65.24 474.21 41.74 555.61 41.74 555.61 65.24 527.9 65.24z"
        />
        <path
          fill="#27A9E1"
          d="M653.97 151.75L622.46 151.75 589.92 107.84 589.74 107.84 589.74 151.75 581.85 151.75 581.85 99.95 593.9 99.95 626.43 143.86 638.39 143.86 602.15 94.5 636.71 49.63 626.26 49.63 594.02 92.23 581.85 92.23 581.85 49.63 571.5 49.63 571.5 151.75 563.62 151.75 563.62 41.74 589.74 41.74 589.74 84.34 590.1 84.34 622.34 41.74 652.73 41.74 612.01 94.61z"
        />
        <path
          fill="#27A9E1"
          d="M686.59 149.78L678.7 149.78 678.7 49.63 668.5 49.63 668.5 149.78 660.61 149.78 660.61 41.74 686.59 41.74z"
        />
        <path
          fill="#27A9E1"
          d="M748.29 149.78L740.4 149.78 740.4 57.35 768.11 57.35 768.11 49.63 702.49 49.63 702.49 57.35 730.34 57.35 730.34 149.78 722.46 149.78 722.46 65.24 694.6 65.24 694.6 41.74 776 41.74 776 65.24 748.29 65.24z"
        />
        <path
          d="M52.05 7.98c3.93 0 7.85.44 11.68 1.33 9.7 2.25 18.7 7.35 25.61 14.51 1.6 1.66 3.09 3.42 4.46 5.3 1.34 1.83 1.93 3.63 1.93 5.89V160.45c0 2.27-.59 4.07-1.93 5.9-.14.19-.28.38-.43.57-6 7.94-14.34 14.07-23.7 17.46a52.093 52.093 0 01-17.7 3.09c-3.93 0-7.85-.44-11.68-1.33-9.7-2.25-18.7-7.35-25.61-14.51-1.6-1.66-3.09-3.42-4.46-5.3-1.34-1.83-1.93-3.63-1.93-5.89v-4.3-10.64-.19-4.51-5.24-9.94-55.8-10.01-5.28-4.4-.19V39.3 35c0-2.27.59-4.07 1.93-5.9l.42-.57c6-7.94 14.34-14.07 23.7-17.46a52.192 52.192 0 0117.71-3.09m0-7.89c-7.01 0-13.87 1.2-20.39 3.56-10.74 3.89-20.43 11.03-27.3 20.12-.16.22-.33.44-.49.66C1.54 27.6.41 31.06.41 35v125.45c0 3.93 1.13 7.38 3.46 10.55 1.58 2.15 3.31 4.21 5.15 6.11 7.92 8.2 18.39 14.14 29.51 16.71 4.38 1.02 8.91 1.53 13.46 1.53 7.01 0 13.87-1.2 20.39-3.56 10.74-3.89 20.44-11.03 27.3-20.12.16-.22.33-.43.49-.66 2.33-3.17 3.47-6.63 3.47-10.57V35c0-3.93-1.13-7.39-3.46-10.56-1.58-2.15-3.31-4.2-5.15-6.11-7.92-8.2-18.4-14.14-29.51-16.71A60.156 60.156 0 0052.05.09z"
          fill="#FFF"
        />
        <path
          d="M52.02 108.2c3.9 0 7.4-3.15 7.4-7.42 0-4.49 1.29-10.39-4.44-12.89a7.38 7.38 0 00-2.97-.63c-3.9 0-7.4 3.15-7.4 7.42 0 4.49-1.29 10.39 4.44 12.89.98.43 1.99.63 2.97.63"
          fill="#FFF"
        />
        <path
          d="M79.37 70.62V68.9c-.01-.94-.03-1.78-.05-2.58-.01-.41-.03-.81-.05-1.19-.02-.39-.03-.66-.06-1.13a51.281 51.281 0 00-.41-4.09c-.03-.22-.07-.43-.1-.64-.04-.22-.06-.36-.11-.63-.43-2.25-1.1-4.28-2.06-6.21-4.66-9.36-14.04-15.17-24.5-15.17s-19.84 5.81-24.5 15.17c-.49.97-.88 1.93-1.21 2.91-.17.52-.33 1.06-.48 1.63-.07.27-.14.55-.2.83-.06.28-.13.64-.17.85-.15.79-.28 1.67-.39 2.59-.05.44-.1.89-.14 1.36-.04.45-.08 1.03-.1 1.39-.04.71-.08 1.49-.11 2.32l-.03 1.24-.01.63V68.78c-.01.77-.01 2.42-.01 4.55v53.23c.01.94.03 1.79.05 2.59.01.41.03.81.05 1.19.02.39.03.66.06 1.13a51.281 51.281 0 00.41 4.09c.03.22.07.43.1.64.04.22.06.36.11.64.43 2.25 1.1 4.28 2.06 6.2 4.66 9.36 14.04 15.17 24.5 15.17s19.84-5.81 24.5-15.17a22.433 22.433 0 001.69-4.54c.07-.27.14-.55.2-.84.06-.27.13-.63.17-.84.15-.8.28-1.67.39-2.59a46 46 0 00.24-2.76c.04-.71.08-1.49.11-2.32l.03-1.24.01-.63v-.64c.01-.73.01-2.38.01-4.5V70.62zm-7.88 51.51c0 2.08 0 3.7-.01 4.42v.6c0 .2-.01.39-.01.59l-.03 1.15c-.02.75-.06 1.45-.1 2.09-.02.32-.06.82-.09 1.21s-.07.77-.11 1.14c-.08.73-.18 1.41-.3 2.02-.03.15-.08.41-.13.61-.04.2-.09.39-.14.58-.1.38-.2.74-.32 1.08-.23.69-.49 1.3-.79 1.9-3.58 7.2-10.51 10.8-17.44 10.8-6.93 0-13.85-3.6-17.44-10.8-.6-1.2-1.06-2.5-1.37-4.16-.04-.21-.06-.31-.08-.47-.03-.16-.05-.33-.08-.5-.05-.34-.1-.69-.14-1.06-.08-.74-.15-1.53-.2-2.36-.03-.41-.04-.65-.05-1-.02-.35-.03-.71-.04-1.08-.02-.75-.04-1.54-.05-2.41v-.24-.4-1.01-2.69-48.8c0-2.08 0-3.7.01-4.42v-.3-.3c0-.2.01-.4.01-.59l.03-1.15c.02-.75.06-1.45.1-2.09.02-.32.06-.82.09-1.21s.07-.77.11-1.14c.08-.73.18-1.41.3-2.02.03-.15.08-.41.13-.61.04-.2.09-.39.14-.58.1-.38.2-.74.32-1.08.23-.69.49-1.3.79-1.9 3.58-7.2 10.51-10.8 17.44-10.8 6.93 0 13.85 3.6 17.44 10.8.6 1.2 1.06 2.5 1.37 4.16.04.21.06.31.08.47.03.16.05.33.08.5.05.34.1.69.14 1.06.08.74.15 1.53.2 2.36.03.41.04.65.05 1 .02.35.03.71.04 1.08.02.75.04 1.54.05 2.41v53.14z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}
