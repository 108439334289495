import * as React from 'react';
import Chart from 'react-google-charts';
import TelemetryApi from '../../../apis/TelemetryApi';
import { ObjectDetails } from '../../../models/dto/Object';
import Query from '../../../models/dto/Query';
import Loader from '../../common/Loader';
import ObjectCardContent, { IObjectCardContentProps, IObjectCardContentState } from '../ObjectCardContent';

export interface ICuveHorizonalProps extends IObjectCardContentProps {

}

export interface ICuveHorizonalState extends IObjectCardContentState {
  telemetries?: any[]
  gradientFrom: string
  gradientTo: string
  percent: number,
  volume: number | undefined,
  okIsEmpty: boolean,
  showRemainingLiter: boolean, 
  showMissingLiter: boolean
}

export default class CuveHorizonal extends ObjectCardContent<ICuveHorizonalProps, ICuveHorizonalState> {
  static objectTypeId = 'd5831af5-8200-4a8a-947b-d0351e8d1632'

  async updateState(_: ObjectDetails): Promise<ICuveHorizonalState> {
    var rows = await TelemetryApi.getInstance().query(this.props.organization, new Query().withRange(this.props.range.toQueryRange()).withFilters([
      `r["object"] == "${this.props.object.objectId}"`,
      `r["_measurement"] == "level"`,
      `r["_field"] == "percent" or r["_field"] == "level" or r["_field"] == "volume"`
    ]).withKeepColumns(['_time', 'percent', 'level', 'volume']).withAggregateWindow('last', this.props.range.interval.duration, true).withPivot(['_field'], ['_time'], '_value'))

    rows = rows.map(row => {
      return {
        ...row,
        time: Date.parse(row._time),
        _time: new Date(row._time),
        //percent: row.percent === '' ? null : parseFloat(row.percent), 
        volume: row.volume === '' ? null : parseFloat(row.volume)
      }
    }).sort((a: any, b: any) => a.time - b.time)

    var data = rows.map(_ => [_._time, _.volume])
    return {
      ...this.state,
      telemetries: data
    }
  }

  getInitialState(): ICuveHorizonalState {
    const percent: number = this.props.object.shadow.Percent
    const volume: number = this.props.object.shadow.Volume
    const emptyIsOk: boolean = this.props.object.getSettingValue('emptyIsOk', this.props.objectType.settings)
    const showRemainingLiter: boolean = this.props.object.getSettingValue('showRemainingLiter', this.props.objectType.settings)
    const showMissingLiter: boolean = this.props.object.getSettingValue('showMissingLiter', this.props.objectType.settings)
    var gradientFrom: string = '#95C11F'
    var gradientTo: string = '#3AAA35'
    if (emptyIsOk) {
      if (percent < 65) {
        gradientFrom = '#2EBD3C'
        gradientTo = '#2EBD3C'
      }
      else if (percent < 75) {
        gradientFrom = '#FCEA10'
        gradientTo = '#DBBF03'
      }
      else if (percent < 85) {
        gradientFrom = '#F39200'
        gradientTo = '#E94E1B'
      }
      else {
        gradientFrom = '#E51E23'
        gradientTo = '#99191E'
      }
    }
    else {
      if (percent < 15) {
        gradientFrom = '#E51E23'
        gradientTo = '#99191E'
      }
      else if (percent < 25) {
        gradientFrom = '#F39200'
        gradientTo = '#E94E1B'
      }
      else if (percent < 35) {
        gradientFrom = '#FCEA10'
        gradientTo = '#DBBF03'
      }
      else {
        gradientFrom = '#2EBD3C'
        gradientTo = '#2EBD3C'
      }
    }
    return {
      loading: true,
      updating: true,
      gradientFrom: gradientFrom,
      gradientTo: gradientTo,
      percent: percent,
      okIsEmpty: emptyIsOk,
      volume: volume, 
      showRemainingLiter: showRemainingLiter, 
      showMissingLiter: showMissingLiter
    }
  }

  private renderState() {
    return <div className='row d-flex'>
      <div className='text-right w-50 pr-3'>
        <SvgContainerComponent percent={this.state.percent} height={100} color={this.state.gradientFrom} />
      </div>
      <div className='text-right align-self-center' style={{ display: 'inline-block' }}>
        <h5 className='mb-0 text-left'>{this.state.percent.toFixed(0)}%</h5>
        {(this.state.showMissingLiter && this.props.object.shadow?.MaxVolume && this.state.volume !== undefined) && <h3 className='mb-0'>{(this.props.object.shadow.MaxVolume - this.state.volume!).toFixed(0)}L <small>puisé&nbsp;&nbsp;&nbsp;</small></h3>}
        {(this.state.showRemainingLiter && this.state.volume !== undefined) && <h3 className='mb-0'>{this.state.volume!.toFixed(0)}L <small>restant</small></h3>}
      </div>
    </div>
  }

  private renderChart() {
    if (this.state.telemetries!.length === 0) {
      return <div className='d-flex h-100 align-items-center justify-content-center'>Aucune donnée pour cette période</div>
    }
    return <div className='mt-n3'>
      <Chart
        chartLanguage='fr'
        chartType="LineChart"
        height={150}
        width='100%'
        data={[
          [{ type: 'datetime' },
            'Remplissage'
        ],
          ...this.state.telemetries!
        ]}
        options={{
          colors: ['#8884d8', '#82ca9d'],
          legend: { position: 'none' },
          chartArea: { width: '76%', height: '75%' },
          series: {
            0: { axis: 'Remplissage', targetAxisIndex: 0 }
          },
          vAxes: [
            {
              minValue: 0,
              maxValue: this.props.object.shadow?.MaxVolume ?? 100
            }
          ],
          hAxis: {
            minValue: this.props.range.start.toDate(),
            maxValue: this.props.range.stop?.toDate() ?? new Date()
          }
        }}
        rootProps={{ object: this.props.object.objectId }}
      />
    </div>
  }

  render() {
    return super.render() ?? this.renderContent()
  }

  private renderContent() {
    if (this.state.volume === undefined || this.props.object.shadow.Percent === undefined) {
      return <div className='d-flex h-100 align-items-center justify-content-center'>Aucune donnée pour cette période</div>
    }
    return <>
      <div>
        {this.renderState()}
      </div>
      <hr />
      <div>
        {this.state.updating ? <Loader color="dark" /> : this.state.telemetries && this.renderChart()}
      </div>
    </>
  }
}

function SvgContainerComponent(props: { percent: number, height: number, color: string }) {
  return (
    <svg viewBox="0 0 140 100" height={props.height}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
          <stop stop-color="#797979" stop-opacity="0" offset="0%"/>
          <stop stop-color="#E8E8E8" stop-opacity=".33" offset="37.619%"/>
          <stop stop-color="#F4F4F4" stop-opacity=".39" offset="50.825%"/>
          <stop stop-color="#FFFFFF" stop-opacity=".25" offset="65.961%"/>
          <stop stop-color="#000000" stop-opacity="0" offset="100%"/>
        </linearGradient>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <rect fill={props.color} y={100-props.percent} width="140" height={props.percent}/>
        <rect stroke="#979797" x=".5" y=".5" width="139" height="99"/>
        <rect fill="url(#a)" width="140" height="100"/>
      </g>
    </svg>
  )
}