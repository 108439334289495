import React, { Component } from "react";
import {ObjectAlertSetting, ObjectDetails, ObjectSetting, ObjectSettings} from '../../models/dto/Object'
import PresenceCounter from "./cards/PresenceCounter";
import PassageCounter from "./cards/PassageCounter";
import { Range } from "../common/RangePicker";
import { Card, Button, Form, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import moment from "moment";
import { FaRegClock, FaCogs, FaBatteryHalf, FaBatteryFull, FaBatteryEmpty, FaBatteryThreeQuarters, FaBatteryQuarter, FaBell, FaBellSlash } from "react-icons/fa";
import { ObjectTypeDetails, ObjectTypeSetting, ObjectSettingValueTypes, ObjectTypeAlertSetting } from "../../models/dto/ObjectType";
import ObjectSettingEditor from "./ObjectSettingEditor";
import ObjectAlertSettingEditor from "./ObjectAlertSettingEditor";
import ObjectApi from "../../apis/ObjectApi";
import CapLevel from "./cards/CapLevel";
import CuveHorizonal from "./cards/CuveHorizonal";
import BinaryInputs from "./cards/BinaryInputs";
import Temperature from "./cards/Temperature";
import Voltage from "./cards/Voltage";
import Pneu from "./cards/Pneu";
import Epaper from "./cards/Epaper";
import { IObjectCardContent } from "./ObjectCardContent";

export interface ObjectCardProps {
    organization: string
    installation: string
    object: ObjectDetails
    objectType: ObjectTypeDetails
    range: Range
    onObjectUpdated?: (object: ObjectDetails) => void
}

export interface ObjectCardState {
    editing: boolean 
    loading: boolean
    editSettings?: [ObjectTypeSetting, ObjectSetting?][]
    editAlertSettings?: [ObjectTypeAlertSetting, ObjectAlertSetting?][]
    editError?: string, 
    object: ObjectDetails, 
    enabled: boolean,
    alertsEnabled: boolean
}

export default class ObjectCard extends Component<ObjectCardProps, ObjectCardState> {
    private editForm = React.createRef<HTMLFormElement>()
    private nameRef = React.createRef<HTMLInputElement>()
    private contentRef: IObjectCardContent | null = null
    
    constructor(props: ObjectCardProps) {
        super(props)

        this.state = {
            loading: false,
            editing: false, 
            editError: undefined, 
            object: props.object, 
            enabled: props.object.enabled,
            alertsEnabled: props.object.alertsEnabled
        }
    }

    async reload() {
        try {
            const obj = await ObjectApi.getInstance().get(this.props.organization, this.props.installation, this.props.object.objectId)
            this.setState({
                ...this.state, 
                object: obj
            })
        } catch (error) {
            
        }
        await this.contentRef?.reload(false, true)
    }

    getContent() {
        switch (this.props.object.objectTypeId) {
            case PresenceCounter.objectTypeId:
                return <PresenceCounter {...this.props} ref={(e) => this.contentRef = e} />
            case PassageCounter.objectTypeId:
                return <PassageCounter {...this.props} ref={(e) => this.contentRef = e} />
            case CapLevel.objectTypeId:
                return <CapLevel {...this.props} ref={(e) => this.contentRef = e} />
            case CuveHorizonal.objectTypeId:
                return <CuveHorizonal {...this.props} ref={(e) => this.contentRef = e} />
            case BinaryInputs.objectTypeId:
                return <BinaryInputs {...this.props} ref={(e) => this.contentRef = e} />
            case Temperature.objectTypeId:
                return <Temperature {...this.props} ref={(e) => this.contentRef = e} />
            case Pneu.objectTypeId:
                return <Pneu {...this.props} ref={(e) => this.contentRef = e} />
            case Epaper.objectTypeId:
                return <Epaper {...this.props} ref={(e) => this.contentRef = e} />
            case Voltage.objectTypeId:
                return <Voltage {...this.props} ref={(e) => this.contentRef = e} />
        
            default:
                return <p>Type d'objet inconnu</p>
        }
    }

    async submitSettings(event: React.FormEvent<HTMLElement>) {
        event.preventDefault()

        this.setState({
            ...this.state, 
            loading: true
        })
        try {
            const objectName = this.nameRef.current!.value
            const object = await ObjectApi.getInstance().editCustomerSettings(this.props.organization, this.props.installation, this.props.object, new ObjectSettings(
                this.state.enabled, 
                objectName,
                this.state.alertsEnabled,
                this.state.editSettings!.map(_ => _[1]!), 
                this.state.editAlertSettings!.map(_ => _[1]!)
            ));
            this.setState({
                ...this.state,
                loading: false,  
                editing: false, 
                object: object
            }, () => { this.reload() })
        } catch (error) {
            this.setState({
                ...this.state,
                loading: false,  
                editing: true, 
                editError: error.message
            })
        }
    }

    onEditSetingValueChange(index: number, value: any) {
        var settings = this.state.editSettings!
        settings[index][1]!.value = value
        this.setState({
            ...this.state, 
            editSettings: settings
        })
    }

    onEditAlertSetingValueChange(index: number, value: any) {
        var settings = this.state.editAlertSettings!
        settings[index][1]!.value = value
        this.setState({
            ...this.state, 
            editAlertSettings: settings
        })
    }

    async setEditing() {
        var settings = this.props.objectType.settings.filter(_ => !_.isAdministrationOnly).map<[ObjectTypeSetting, any]>(_ => [_, undefined])
        settings.forEach(setting => {
            var s = this.state.object.settings.find(_ => _.objectTypeSettingKey === setting[0].key)
            if (!s) {
                s = new ObjectSetting()
                s.objectTypeSettingKey = setting[0].key
            }
            setting[1] = s
        })

        var alertSettings = this.props.objectType.alertSettings.filter(_ => !_.isAdministrationOnly).map<[ObjectTypeAlertSetting, any]>(_ => [_, undefined])
        alertSettings.forEach(setting => {
            var s = this.state.object.alertSettings.find(_ => _.objectTypeSettingKey === setting[0].key)
            if (!s) {
                s = new ObjectAlertSetting()
                s.objectTypeSettingKey = setting[0].key
            }
            setting[1] = s
        })

        this.setState({
            ...this.state, 
            editing: true, 
            editSettings: settings, 
            editAlertSettings: alertSettings
        })
    }

    getEditContent() {
        return <div className='d-flex flex-column h-100'>
            <h4>Appareils liés</h4>
            <dl className="row">
            {this.props.objectType.settings.filter(_ => _.valueType === ObjectSettingValueTypes.deviceId).map(_ => {
                const setting = this.state.object.settings.find(s => s.objectTypeSettingKey === _.key)
                return <div key={_.key}>
                    <dt className="col-sm-3">{_.name}</dt>
                    <dd className="col-sm-9">{setting?.value}</dd>
                </div>
            })}
            </dl>

            
            <Form ref={this.editForm} onSubmit={(event) => this.submitSettings(event)}>
                <div className='overflow-auto'>
                    {this.state.editError && <p>{this.state.editError}</p>}
                    <h4>Paramètres</h4>
                    <Form.Group controlId="enabled">
                        <Form.Label>Activées</Form.Label>
                        <Form.Switch defaultChecked={this.state.enabled} onChange={(e) => {
                            this.setState({
                                ...this.state,
                                enabled: e.target.checked
                            });
                        }} />
                    </Form.Group>
                    {this.state.enabled && <>
                    {this.state.editSettings!.map((_, index) => <ObjectSettingEditor 
                        key={`setting-${index}`}
                        inline={false}
                        index={index} 
                        organization={this.props.organization} 
                        installation={this.props.installation} 
                        object={this.state.object} 
                        objectTypeSettings={this.props.objectType.settings} 
                        setting={_[1]!}
                        onChange={(index, value) => this.onEditSetingValueChange(index, value)}
                    />)}
                    <h4>
                        Paramètres des alertes
                    </h4>
                    <Form.Group controlId="alertsEnabled">
                        <Form.Label>Activées</Form.Label>
                        <Form.Switch defaultChecked={this.state.alertsEnabled} onChange={(e) => {
                            this.setState({
                                ...this.state,
                                alertsEnabled: e.target.checked
                            });
                        }} />
                    </Form.Group>
                    {this.state.alertsEnabled && this.state.editAlertSettings!.map((_, index) => <ObjectAlertSettingEditor 
                        key={`alert-setting-${index}`}
                        inline={false}
                        index={index} 
                        organization={this.props.organization} 
                        installation={this.props.installation} 
                        object={this.state.object} 
                        objectTypeAlertSettings={this.props.objectType.alertSettings} 
                        setting={_[1]!}
                        onChange={(index, value) => this.onEditAlertSetingValueChange(index, value)}
                    />)}
                    </>}
                    
                </div>

                <div className='d-flex flex-row mt-auto justify-content-end align-self-end'>
                    <Button variant='secondary' onClick={_ => this.setState({...this.state, editing: false, loading: false})}>Annuler</Button>
                    {((this.state.editSettings?.length || 0) + (this.state.editAlertSettings?.length || 0)) !== 0 && <Button className='ml-2' type='submit' >Valider</Button>}
                </div>
            </Form>
        </div>
    }

    render() {
        const deviceId = this.state.object.firstDeviceId(this.props.objectType)
        const deviceKey = (deviceId && deviceId.length >= 4) ? deviceId?.substr(deviceId.length - 4, 4) : undefined
        return <>
        <Card>
            <Card.Header className='d-flex bg-primary'>
                {deviceKey && <div className='btn btn-outline-light d-flex align-self-center flex-wrap py-2 px-1 ml-n2' style={{textTransform: 'uppercase'}}>
                    {deviceKey}
                </div>}
                <div style={{width: 20}}>{' '}</div>
                <div className='w-75'>
                    <Card.Title className='text-truncate object-name' title={this.state.object.name}>
                        {this.state.object.name}
                    </Card.Title>
                    <Card.Subtitle className='text-light text-truncate' title={this.state.object.objectTypeName}>
                        {this.state.object.objectTypeName}
                    </Card.Subtitle>
                </div>
                <div style={{width: 60}}>{' '}</div>
                <div className='d-flex align-content-center flex-wrap mr-n2'>
                    <Button onClick={_ => this.setEditing()} disabled={this.state.editing} variant='outline-light'><FaCogs /></Button>
                </div>
            </Card.Header>
            <Card.Body style={{minHeight: 300}} className='pb-0 text-primary'>
                {this.state.editing ? <div className="d-flex h-100 align-items-center justify-content-center"><FaCogs size={70} /></div> : this.getContent()}
            </Card.Body>
            <Card.Footer className='py-2 d-flex justify-content-between'>
                <ObjectCardFooter object={this.state.object} />
            </Card.Footer>
        </Card>
        {this.state.editing && <Modal show={this.state.editing} size='lg'>
            <Modal.Header>
            <Modal.Title className="w-100">
                <Form.Control ref={this.nameRef} size='lg' plaintext readOnly={!this.state.editing} style={{backgroundColor: this.state.editing ? 'white' : 'transparent', width: '100%', fontSize: 18, fontWeight: 'bolder'}} defaultValue={this.state.object.name} />
            </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minHeight: 300}} className='p-3'>
                {this.getEditContent()}
            </Modal.Body>
        </Modal>}
        </>
    }
}

export class ObjectCardFooter extends Component<{object: ObjectDetails}, {fromNow: string}> {
    timer?: NodeJS.Timeout

    constructor(props: any) {
        super(props)
        this.state = {
            fromNow: moment(this.props.object.lastSignalDate).format('LLLL')
        }
    }
    componentDidMount() {
        this.timer = setInterval(() => {
            this.setState({
                fromNow: moment(this.props.object.lastSignalDate).format('LLLL')
            })
        }, 1000)
    }

    componentWillUnmount() {
        //this.timer?.unref()
    }

    render() {
        const battery: number = this.props.object.shadow?.Battery ?? 0
        var batteryComp: JSX.Element | undefined
        if (battery > 90) {
            batteryComp = <><FaBatteryFull className='mr-1 text-muted' /><span>{battery}%</span></>
        }
        else if (battery > 70) {
            batteryComp = <><FaBatteryThreeQuarters className='mr-1 text-muted' /><span>{battery}%</span></>
        }
        else if (battery > 45) {
            batteryComp = <><FaBatteryHalf className='mr-1 text-muted' /><span>{battery}%</span></>
        }
        else if (battery > 20) {
            batteryComp = <><FaBatteryQuarter className='mr-1 text-muted' /><span>{battery}%</span></>
        }
        else {
            batteryComp = <><FaBatteryEmpty className='mr-1 text-danger' /><span className='text-danger'>{battery}%</span></>
        }

        var alertComp: JSX.Element | undefined
        if (this.props.object.alertsEnabled) {
            const alerts: any[] | undefined = this.props.object.shadow?.alerts;
            if (alerts && alerts.length > 0) {
                alertComp = <OverlayTrigger placement='bottom' overlay={<Tooltip id={`card-${this.props.object.objectId}-alert-tooltip`}>{alerts.map(_ => _.Message).join("\n")}</Tooltip>}>
                    <FaBell className='mr-3 text-danger' color="red" />
                </OverlayTrigger>
            }    
        }
        else {
            alertComp = <OverlayTrigger placement='bottom' overlay={<Tooltip id={`card-${this.props.object.objectId}-disabled-alert-tooltip`}>Alertes désactivées</Tooltip>}>
                <FaBellSlash className='mr-3 text-secondary' />
            </OverlayTrigger>
        }
        
        return <>
            {
                this.props.object.lastSignalDate ? 
                <div>
                    {batteryComp}
                </div> :
                <div>&nbsp;</div>
            }
            <div>
                {alertComp ?? <></>}
                <FaRegClock className='mr-1 text-muted' />
                {this.props.object.lastSignalDate ? 
                    <OverlayTrigger placement='bottom' overlay={<Tooltip id={`card-${this.props.object.objectId}-lastSignalDate-tooltip`}>Dernier message reçu : {this.state.fromNow}</Tooltip>}>
                        <small>{moment(this.props.object.lastSignalDate).fromNow()}</small>
                    </OverlayTrigger> :
                    <small>Aucune connexion</small>
                }
            </div>
        </>
    }
}