import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

import Layout from '../layouts/Layout'


export default class DefaultLayoutRoute extends Route<RouteProps & {organizationId?: string}> {
    render() {
        return <Layout organizationId={this.props.organizationId}>
            {super.render()}
        </Layout>
    }
}