import { JsonObject, JsonProperty, Any, JsonConvert } from "json2typescript"
import { MapPoint } from './Map'
import DateConverter from "../converters/DateConverter"
import { ObjectSettingValueTypes, ObjectTypeDetails, ObjectTypeSetting } from "./ObjectType"

@JsonObject("ObjectSetting")
export class ObjectSetting {
    @JsonProperty("objectTypeSettingKey", String)
    objectTypeSettingKey: string = ''

    @JsonProperty("value", Any)
    value: any = undefined

    public getSettingType(settingTypes: ObjectTypeSetting[]): ObjectTypeSetting | undefined {
        return settingTypes.find(_ => _.key === this.objectTypeSettingKey)
    }
}

@JsonObject("ObjectAlertSetting")
export class ObjectAlertSetting {
    @JsonProperty("objectTypeSettingKey", String)
    objectTypeSettingKey: string = ''

    @JsonProperty("value", Any)
    value: any = undefined

    public getSettingType(settingTypes: ObjectTypeSetting[]): ObjectTypeSetting | undefined {
        return settingTypes.find(_ => _.key === this.objectTypeSettingKey)
    }
}

@JsonObject("ObjectSettings")
export class ObjectSettings {
    @JsonProperty("enabled", Boolean)
    enabled: boolean = false

    @JsonProperty("name", String)
    name: string = ""

    @JsonProperty("alertsEnabled", Boolean)
    alertsEnabled: boolean = false

    @JsonProperty("settings", [ObjectSetting])
    settings: ObjectSetting[] = []

    @JsonProperty("alertSettings", [ObjectAlertSetting])
    alertSettings: ObjectAlertSetting[] = []

    constructor(
        enabled: boolean = false,
        name: string = "",
        alertsEnabled: boolean = false,
        settings: ObjectSetting[] = [], 
        alertSettings: ObjectAlertSetting[] = []) 
    {
        this.enabled = enabled
        this.name = name
        this.alertsEnabled = alertsEnabled
        this.settings = settings
        this.alertSettings = alertSettings
    }
}

@JsonObject("ObjectBase")
export abstract class ObjectBase {
    @JsonProperty("objectTypeId", String)
    objectTypeId: string = ''

    @JsonProperty("name", String)
    name: string = ''

    @JsonProperty("installationId", String)
    installationId: string = ''

    @JsonProperty("enabled", Boolean)
    enabled: boolean = false

    @JsonProperty("alertsEnabled", Boolean)
    alertsEnabled: boolean = false

    @JsonProperty("settings", [ObjectSetting])
    settings: ObjectSetting[] = []

    @JsonProperty("alertSettings", [ObjectAlertSetting])
    alertSettings: ObjectAlertSetting[] = []

    @JsonProperty("shadow", Any, true)
    shadow?: any = undefined

    @JsonProperty("lastSignalDate", DateConverter, true)
    lastSignalDate?: Date

    @JsonProperty("areaId", String, true)
    areaId?: string = undefined

    @JsonProperty("mapCoordinates", MapPoint, true)
    mapCoordinates: MapPoint | undefined = undefined

    getSettingValue(varableName: string, typeSettings: ObjectTypeSetting[]): any {
        const typeSetting = typeSettings.find(_ => _.variableName === varableName)
        if (typeSetting) {
            const setting = this.settings.find(_ => _.objectTypeSettingKey === typeSetting.key)
            if (setting) {
                return setting.value
            }
        }
        return undefined
    }

    firstDeviceId(objectType: ObjectTypeDetails): string | undefined {
        const typeSetting = objectType.settings.find(_ => _.valueType === ObjectSettingValueTypes.deviceId);
        if (typeSetting) {
            const setting = this.settings.find(s => s.objectTypeSettingKey === typeSetting.key)
            return setting?.value
        }
    }
}

@JsonObject("ObjectDetails")
export class ObjectDetails extends ObjectBase {
    @JsonProperty("objectId", String)
    objectId: string = ''

    @JsonProperty("objectTypeName", String)
    objectTypeName: string = ''

    @JsonProperty("areaName", String, true)
    areaName?: string = undefined

    copy(): ObjectDetails {
        const converter = new JsonConvert()
        const serialized = converter.serializeObject(this, ObjectDetails)
        return converter.deserializeObject(serialized, ObjectDetails)
    }
}

@JsonObject("ObjectNew")
export class ObjectNew extends ObjectBase {
    
}

@JsonObject("ObjectEdit")
export class ObjectEdit extends ObjectBase {
    
}


