import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

import LayoutFluid from '../layouts/LayoutFluid'

export default class FluidLayoutRoute extends Route<RouteProps & {organizationId?: string}> {
    render() {
        return <LayoutFluid organizationId={this.props.organizationId}>
            {super.render()}
        </LayoutFluid>
    }
}