import { ObjectTypeDetails, ObjectTypeNew } from '../models/dto/ObjectType'
import ApiBase from './ApiBase';
import { DeviceSummary } from '../models/dto/Device'

export default class ObjectTypeApi extends ApiBase {
    private static instance: ObjectTypeApi;

    private constructor() {
        super()
    }
  
    static getInstance(): ObjectTypeApi {
      if (!ObjectTypeApi.instance) {
        ObjectTypeApi.instance = new ObjectTypeApi()
      }
  
      return ObjectTypeApi.instance
    }

    public async fetch(): Promise<ObjectTypeDetails[]> {
        return await this.getItems(`/api/objecttypes`, ObjectTypeDetails)
    }

    public async create(objectType: ObjectTypeNew): Promise<ObjectTypeDetails> {
        let item = await this.postItem(`/api/objecttypes`, objectType, ObjectTypeNew, ObjectTypeDetails)
        return item!
    }

    public async edit(objectType: ObjectTypeDetails): Promise<ObjectTypeDetails> {
      let item = await this.putItem(`/api/objecttypes/${objectType.id}`, objectType, ObjectTypeDetails, ObjectTypeDetails)
      return item!
    }

    public async delete(id: string): Promise<void> {
      await this.deleteItem(`/api/objecttypes/${id}`)
    }

    public async getDevices(id: string, settingKey?: string): Promise<DeviceSummary[]> {
      var url = `/api/objecttypes/${id}/devices`
      if (settingKey) {
        url += `?settingKey=${settingKey}`
      }
      return await this.getItems(url, DeviceSummary)
    }
}