import { JsonConvert } from 'json2typescript';
import moment from 'moment';
import { OrganizationDetails } from '../models/dto/Organization'
import ApiBase from './ApiBase';

export default class OrganizationMetaApi extends ApiBase {
    private static instance: OrganizationMetaApi;

    private constructor() {
        super()
    }
  
    static getInstance(): OrganizationMetaApi {
      if (!OrganizationMetaApi.instance) {
        OrganizationMetaApi.instance = new OrganizationMetaApi()
      }
  
      return OrganizationMetaApi.instance
    }

    public async fetch(id: string): Promise<OrganizationDetails> {
        return await this.getItem(`/api/organizations/${id}`, OrganizationDetails)
    }

    public async getOrFetch(id: string): Promise<OrganizationDetails | undefined> {
      try {
        var jsonString = localStorage.getItem(`organization-${id}`);
        var item!: OrganizationDetails
        if (!jsonString) {
          item = await this.fetch(id)
          item.date = new Date()
          jsonString = JSON.stringify(new JsonConvert().serializeObject(item));
          localStorage.setItem(`organization-${id}`, jsonString);
          return item  
        }
        item = new JsonConvert().deserializeObject(JSON.parse(jsonString), OrganizationDetails)
        if (!item.date || moment(item.date).diff(moment(), "hour") > 1) {
          item = await this.fetch(id)
          item.date = new Date()
          jsonString = JSON.stringify(new JsonConvert().serializeObject(item));
          localStorage.setItem(`organization-${id}`, jsonString);
        }
        return item
      } catch (error) {
        
      }
    }
}