import * as React from 'react';
import Chart from 'react-google-charts';
import TelemetryApi from '../../../apis/TelemetryApi';
import { ObjectDetails } from '../../../models/dto/Object';
import Query from '../../../models/dto/Query';
import Loader from '../../common/Loader';
import ObjectCardContent, { IObjectCardContentProps, IObjectCardContentState } from '../ObjectCardContent';

export interface BinaryInputsProps extends IObjectCardContentProps {

}

export interface BinaryInputsState extends IObjectCardContentState {
    telemetries?: any[]
    binaryState: boolean
}

export default class BinaryInputs extends ObjectCardContent<BinaryInputsProps, BinaryInputsState> {
    static objectTypeId = '39fe88f0-06fe-44b5-a581-bf560a9f12f0'

    async updateState(object: ObjectDetails): Promise<BinaryInputsState> {
        var rows = await TelemetryApi.getInstance().query(this.props.organization, new Query().withRange(this.props.range.toQueryRange()).withFilters([
            `r["object"] == "${this.props.object.objectId}"`,
            `r["_measurement"] == "binary_state"`,
            `r["_field"] == "value"`, 
            `r["_value"] >= 0`
        ]).withKeepColumns(['_time', '_value']))
        
        rows = rows.map(row => {
            return {
                ...row,
                time: Date.parse(row._time), 
                _time: new Date(row._time), 
                state: row._value === '' ? null : parseInt(row._value), 
            }
        }).sort((a: any, b: any) => a.time.compare - b.time)

        var data: any[] = []
        if (rows.length > 0) {
            var previous = rows[0].state
            rows.forEach(row => {
                if (previous !== row.state) {
                    data.push([row._time, previous])
                    previous = row.state
                }
                data.push([row._time, row.state])
            });
        }

        return {
            ...this.state, 
            telemetries: data, 
            binaryState: object!.shadow!.BinaryState
        }
    }

    getInitialState(): BinaryInputsState {
        return {
            loading: true, 
            updating: true,
            binaryState: this.props.object.shadow!.BinaryState
        }
    }

    private renderState() {
        return <div className='text-center w-100' style={{ display: 'inline-block' }}>
            <h1 className='mb-0'><small>OFF</small> <SvgComponent on={this.state.binaryState} height={50} /> <small>ON</small></h1>
        </div>
    }

    private renderChart() {
        if (this.state.telemetries!.length === 0) {
            return <div className='d-flex h-100 align-items-center justify-content-center'>Aucune donnée pour cette période</div>
        }
        return <div className='mt-n3'>
            <Chart 
              chartLanguage='fr'
              chartType="AreaChart"
              height={190}
              width='100%'
              data={[
                [{ type: 'datetime' },
                'État'], 
                ...this.state.telemetries!
              ]}
              options={{
                colors:['#8884d8', '#82ca9d'],
                legend: { position: 'none'}, 
                chartArea: { width: '80%', height: '75%'},
                hAxis: {
                    minValue: this.props.range.start.toDate(), 
                    maxValue: this.props.range.stop?.toDate() ?? new Date()
                }, 
                vAxis: {
                    maxValue: 1,
                    minValue: 0
                }
              }}
              rootProps={{object: this.props.object.objectId}}
            />
        </div>
    }

    render() {
        return super.render() ?? this.renderContent()
    }

    private renderContent() {
        if (this.props.object.shadow.BinaryState === undefined) {
            return <div className='d-flex h-100 align-items-center justify-content-center'>Aucune donnée pour cette période</div> 
        }
        return <>
            <div>
                {this.renderState()}
            </div>
            <hr />
            <div>
                {this.state.updating ? <Loader color="dark" /> : this.state.telemetries && this.renderChart()}
            </div>
        </>
    }
}

function SvgComponent(props: {on: boolean, height: number}) {
    return (
      <svg viewBox="0 0 83 47" height={props.height}>
        <g fill="none" fillRule="evenodd">
          <g fillRule="nonzero" stroke="#032761" strokeWidth={2.2536}>
            <g>
              <g>
                <g transform="translate(-905 -1374) translate(529 1238) translate(325) translate(53.5 138.62)">
                  <path
                    d="M56.79 4.01H17.37C7.78 4.01 0 11.79 0 21.38s7.78 17.37 17.37 17.37h39.42c9.59 0 17.37-7.78 17.37-17.37S66.38 4.01 56.79 4.01z"
                    fill={props.on ? "#2FB542" : "#C1272D"}
                  />
                  <circle fill="#FFF" cx={props.on ? 57 : 22} cy={21.38} r={21.38} />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }