//import './scss/custom.scss'
import React, { Component } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import OrganizationList from './components/organizations/OrganizationList'
import InstallationRoutes from './components/routes/InstallationRoutes'
import ObjectTypeList from './components/objectTypes/ObjectTypeList'
import { UserData } from 'react-oidc'
import AuthorizedUser from './components/api-authorization/AuthorizedUser'
import DefaultLayoutRoute from './components/routes/DefaultLayoutRoute'


interface AppProps { }

interface AppState {
  
}

export default class App extends Component<AppProps, AppState> {

  state: AppState = {
    
  }

  async componentDidMount() {
    
  }

  render() {
    return (
      <Switch>
        <Route exact path='/' render={(props) => {
          return <UserData.Consumer>
            {context => new AuthorizedUser(context.user).isAdmin ? <Redirect to='/organizations' /> : <Redirect to={`/${new AuthorizedUser(context.user).organizationId}/installations`} />}
          </UserData.Consumer>
        }} />
        <DefaultLayoutRoute exact path='/organizations' component={OrganizationList} />
        <DefaultLayoutRoute exact path='/object-types' component={ObjectTypeList} />
        <Route path='/:organization/installations' render={(routeProps) => <InstallationRoutes {...routeProps} />} />
        <Redirect to='./' />
      </Switch>
    )
  }
}
