import React from "react";
import ObjectApi from "../../apis/ObjectApi";
import { ObjectDetails } from "../../models/dto/Object";
import Loader from "../common/Loader";
import {ObjectCardProps} from './ObjectCard'

export interface IObjectCardContentProps extends ObjectCardProps {

}

export interface IObjectCardContentState {
    error?: string
    loading: boolean
    updating: boolean
    telemetries?: any
    object?: ObjectDetails
}

export interface IObjectCardContent {
    reload(showLoading: boolean, reloadObject: boolean): Promise<void>
}

export default abstract class ObjectCardContent<TProps extends IObjectCardContentProps, TState extends IObjectCardContentState> extends React.Component<TProps, TState> implements IObjectCardContent {
    private shouldUpdate: boolean = true
    protected updating: boolean = true

    constructor(props: TProps) {
        super(props)
        this.state = {
            ...this.getInitialState(), 
            updating: this.updating,
            object: props.object
        }
    }

    async reload(showLoading: boolean, reloadObject: boolean = false) {
        this.shouldUpdate = true
        this.updating = true

        this.setState({
            ...this.state, 
            updating: this.updating,
            loading: showLoading, 
            error: undefined
        })

        try {
            var object = this.state.object ?? this.props.object
            if (reloadObject) {
                object = await ObjectApi.getInstance().get(this.props.organization, this.props.installation, this.props.object.objectId)
            }
            var newState = await this.updateState(object)
            

            this.setState({
                ...this.state, 
                ...newState, 
                object: object,
                updating: false,
                loading: false
            }, () => this.updating = false)  
        } catch (error) {
            this.setState({
                ...this.state, 
                loading: false, 
                updating: false,
                error: error.message
            }, () => this.updating = false) 
        }
    }

    abstract getInitialState(): TState
    abstract updateState(object: ObjectDetails): Promise<TState>

    async componentDidMount() {
        this.reload(true, false)
    }

    componentDidUpdate(___: Readonly<IObjectCardContentProps>, __: Readonly<IObjectCardContentState>, _?: any) {
        if (!this.updating) {
            this.shouldUpdate = false
        }
    }

    shouldComponentUpdate(___: Readonly<IObjectCardContentProps>, __: Readonly<IObjectCardContentState>, _: any): boolean {
        return this.shouldUpdate
    }

    render() {
        if (this.state.error) {
            return <p>{this.state.error}</p>
        }
        if (this.state.loading) {
            return <Loader color="dark" />
        }
        return undefined
    }
}