import { JsonConverter, JsonCustomConvert } from "json2typescript";
import { ObjectSettingValueTypes, ObjectAlertSettingValueTypes } from "../dto/ObjectType";

@JsonConverter
export class ObjectSettingValueTypesConverter implements JsonCustomConvert<ObjectSettingValueTypes> {
    serialize(valueType: ObjectSettingValueTypes): any {
        return valueType;
    }
    deserialize(valueType: number): ObjectSettingValueTypes {
        return valueType
    }
}

@JsonConverter
export class ObjectAlertSettingValueTypesConverter implements JsonCustomConvert<ObjectAlertSettingValueTypes> {
    serialize(valueType: ObjectAlertSettingValueTypes): any {
        return valueType;
    }
    deserialize(valueType: number): ObjectAlertSettingValueTypes {
        return valueType
    }
}