import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("QueryRange")
export class QueryRange {
    @JsonProperty("start", String)
    start: string = ''

    @JsonProperty("stop", String, true)
    stop?: string = undefined

    constructor({
        start = '',
        stop = ''
    } = {}) {
        this.start = start
        if (stop) {
            this.stop = stop
        }
    }
}

@JsonObject("QueryAggregateWindow")
export class QueryAggregateWindow {
    @JsonProperty("createEmpty", Boolean)
    createEmpty: boolean = false

    @JsonProperty("every", String)
    every: string = '1d'

    @JsonProperty("function", String)
    function: string = 'mean'

    @JsonProperty("column", String, true)
    column?: string = undefined
}

@JsonObject("QueryPivot")
export class QueryPivot {
    @JsonProperty("columnKey", [String])
    columnKey: string[] = ['_field']

    @JsonProperty("rowKey", [String])
    rowKey: string[] = ['_time']

    @JsonProperty("valueColumn", String)
    valueColumn: string = '_value'
}

@JsonObject("QueryStateDuration")
export class QueryStateDuration {
    @JsonProperty("unit", String)
    unit: string = ''

    @JsonProperty("column", String)
    column: string = ''

    @JsonProperty("function", String)
    function: string = ''
}

@JsonObject("Query")
export default class Query {
    @JsonProperty("bucket", String, true)
    bucket?: string = undefined

    @JsonProperty("range", QueryRange)
    range: QueryRange = new QueryRange()

    @JsonProperty("filters", [String])
    filters: string[] = []

    @JsonProperty("aggregateWindow", QueryAggregateWindow, true)
    aggregateWindow?: QueryAggregateWindow = undefined

    @JsonProperty("keepColumns", [String])
    keepColumns: string[] = []

    @JsonProperty("stateDurations", [QueryStateDuration])
    stateDurations: QueryStateDuration[] = []

    @JsonProperty("pivot", QueryPivot, true)
    pivot?: QueryPivot = undefined

    @JsonProperty("yieldName", String, true)
    yieldName?: string = undefined

    constructor({
        start = '',
        stop = undefined
    } = {}) {
        this.range.start = start
        if (stop) {
            this.range.stop = stop
        }
    }
    
    withRange(range: QueryRange): Query {
        this.range = range
        return this
    }

    withFilters(filters: string[]): Query {
        this.filters.push(...filters)
        return this
    }
    withFilter(filter: string): Query {
        this.withFilters([filter])
        return this
    }

    withStateDurations(stateDuations: QueryStateDuration[]): Query {
        this.stateDurations.push(...stateDuations)
        return this
    }
    withStateDuration(column: string, fn: string, unit: string): Query {
        var item = new QueryStateDuration()
        item.column = column
        item.function = fn
        item.unit = unit
        this.withStateDurations([item])
        return this
    }

    withAggregateWindow(fn: string, every: string, createEmpty: boolean | undefined = undefined, column: string | undefined = undefined): Query {
        if (!this.aggregateWindow) {
            this.aggregateWindow = new QueryAggregateWindow()
        }
        this.aggregateWindow.function = fn
        this.aggregateWindow.every = every
        this.aggregateWindow.column = column
        if (createEmpty)
            this.aggregateWindow.createEmpty = createEmpty
        return this
    }

    withKeepColumns(keepColumns: string[]): Query {
        this.keepColumns.push(...keepColumns)
        return this
    }

    withYieldName(yieldName: string): Query {
        this.yieldName = yieldName
        return this
    }

    withPivot(columnKey: string[], rowKey: string[], valueColumn: string): Query {
        var pivot = new QueryPivot()
        pivot.columnKey = columnKey
        pivot.rowKey = rowKey
        pivot.valueColumn = valueColumn
        this.pivot = pivot
        return this
    }
}