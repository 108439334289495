import React, { Component } from 'react';
import { Redirect } from 'react-router';
import InstallationList from '../installations/InstallationList';
import InstallationDetails from '../installations/InstallationDetails';
import InstallationAdmin from '../installations/InstallationAdmin';
import { Switch, RouteComponentProps } from 'react-router-dom';
import DefaultLayoutRoute from './DefaultLayoutRoute';
import FluidLayoutRoute from './LayoutFluidRoute';

export default class InstallationRoutes extends Component<RouteComponentProps<{organization: string}>> {
    
    render() {
        return (
            <Switch>
                <DefaultLayoutRoute exact path={`${this.props.match.path}`} organizationId={this.props.match.params.organization}> 
                    <InstallationList {...this.props} />
                </DefaultLayoutRoute>
                <FluidLayoutRoute exact path={`${this.props.match.path}/:installation([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})`} render={(routeProps) => <InstallationDetails {...routeProps} />} organizationId={this.props.match.params.organization} />
                <DefaultLayoutRoute exact path={`${this.props.match.path}/:installation([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/administration`} render={(routeProps) => <InstallationAdmin {...routeProps} />} organizationId={this.props.match.params.organization} />
                <Redirect to={`${this.props.match.path}`} />
            </Switch>);
    }
}