import { ObjectDetails, ObjectNew, ObjectSettings } from '../models/dto/Object'
import ApiBase from './ApiBase';

export default class ObjectApi extends ApiBase {
    private static instance: ObjectApi;

    private constructor() {
        super()
    }
  
    static getInstance(): ObjectApi {
      if (!ObjectApi.instance) {
        ObjectApi.instance = new ObjectApi()
      }
  
      return ObjectApi.instance
    }

    public async fetch(organizationId: string, installationId: string): Promise<ObjectDetails[]> {
        return await this.getItems(`/api/customer/${organizationId}/installations/${installationId}/objects`, ObjectDetails)
    }

    public async get(organizationId: string, installationId: string, objectId: string): Promise<ObjectDetails> {
        return await this.getItem(`/api/customer/${organizationId}/installations/${installationId}/objects/${objectId}`, ObjectDetails)
    }

    public async create(organizationId: string, installationId: string, object: ObjectNew): Promise<ObjectDetails> {
        let item = await this.postItem(`/api/customer/${organizationId}/installations/${installationId}/objects`, object, ObjectNew, ObjectDetails)
        return item!
    }

    public async edit(organizationId: string, installationId: string, object: ObjectDetails): Promise<ObjectDetails> {
        let item = await this.putItem(`/api/customer/${organizationId}/installations/${installationId}/objects/${object.objectId}`, object, ObjectNew, ObjectDetails)
        return item!
    }

    public async editCustomerSettings(organizationId: string, installationId: string, object: ObjectDetails, settings: ObjectSettings): Promise<ObjectDetails> {
        let item = await this.putItem(`/api/customer/${organizationId}/installations/${installationId}/objects/${object.objectId}/cutomerSettings`, settings, ObjectSettings, ObjectDetails)
        return item!
    }

    public async delete(organizationId: string, installationId: string, objectId: string): Promise<void> {
        await this.deleteItem(`/api/customer/${organizationId}/installations/${installationId}/objects/${objectId}`)
    }
}