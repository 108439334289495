import * as React from 'react';
import TelemetryApi from '../../../apis/TelemetryApi';
import Query from '../../../models/dto/Query';
import ObjectCardContent, { IObjectCardContentProps, IObjectCardContentState } from '../ObjectCardContent';
import Chart from 'react-google-charts';
import Loader from '../../common/Loader';
import { ObjectDetails } from '../../../models/dto/Object';

export interface ITemperatureProps extends IObjectCardContentProps {

}

export interface ITemperatureState extends IObjectCardContentState {
    telemetries?: any[]
}

export default class Temperature extends ObjectCardContent<ITemperatureProps, ITemperatureState> {
    static objectTypeId = '927e6ad6-c5f1-4966-8cd6-76c3b1757406'

    getInitialState(): ITemperatureState {
        return {
            loading: true, 
            updating: true
        }
    }

    async updateState(_: ObjectDetails): Promise<ITemperatureState> {
        var rows = await TelemetryApi.getInstance().query(this.props.organization, new Query().withRange(this.props.range.toQueryRange()).withFilters([
            `r["object"] == "${this.props.object.objectId}"`,
            `r["_measurement"] == "ambient"`,
            `r["_field"] == "moisture" or r["_field"] == "temperature"`
        ]).withKeepColumns(['_time', 'moisture', 'temperature']).withAggregateWindow('last', this.props.range.interval.duration, true).withPivot(['_field'], ['_time'], '_value'))
        rows = rows.map(row => {
            return {
                ...row,
                time: Date.parse(row._time), 
                _time: new Date(row._time), 
                moisture: row.moisture === '' ? null : parseFloat(row.moisture) / 100.0, 
                temperature: row.temperature === '' ? null : parseFloat(row.temperature)
            }
        }).sort((a: any, b: any) => a.time - b.time)

        var data = rows.map(_ => [_._time, _.temperature, _.moisture])

        return {
            ...this.state, 
            telemetries: data
        }
    }

    private renderState() {
        return <div className='row row-cols-2 pl-2'>
            <div className='text-center'>
                <h5 style={{color:'#8884d8'}} className='mb-n1'>Température</h5>
                <div className='text-right' style={{ display: 'inline-block' }}>
                    <h1 className='mb-0'>{this.props.object.shadow.Temperature}<small>°C</small></h1>
                </div>
            </div>
            <div className='text-center'>
                <h5 style={{color:'#82ca9d'}} className='mb-n1'>Humidité</h5>
                <div className='text-right' style={{ display: 'inline-block' }}>
                    <h1 className='mb-0'>{this.props.object.shadow.Moisture}<small>%</small></h1>
                </div>
            </div>
        </div>
    }

    private renderChart() {
        if (this.state.telemetries!.length === 0) {
            return <div className='d-flex h-100 align-items-center justify-content-center'>Aucune donnée pour cette période</div>
        }
        return <div className='mt-n3'>
            <Chart 
              chartLanguage='fr'
              chartType="LineChart"
              height={170}
              width='100%'
              data={[
                [{ type: 'datetime' },
                'Température',
                'Humidité'
                ], 
                ...this.state.telemetries!
              ]}
              options={{
                colors:['#8884d8', '#82ca9d'],
                legend: { position: 'none'}, 
                chartArea: { width: '80%', height: '75%'},
                hAxis: {
                  minValue: this.props.range.start.toDate(), 
                  maxValue: this.props.range.stop?.toDate() ?? new Date()
                },
                series: {
                    0: {targetAxisIndex: 0}, 
                    1: {targetAxisIndex: 1}
                },
                vAxes: [
                    {maxValue: 50, format: '#°C'}, 
                    {minValue: 0, maxValue: 1, format: '#%'}
                ]
              }}
              rootProps={{object: this.props.object.objectId}}
            />
        </div>
    }

    render() {
        return super.render() ?? this.renderContent()
    }

    private renderContent() {
        if (this.props.object.shadow.Temperature === undefined && this.props.object.shadow.Moisture === undefined) {
            return <div className='d-flex h-100 align-items-center justify-content-center'>Aucune donnée pour cette période</div> 
        }
        return <>
            <div>
                {this.renderState()}
            </div>
            <hr />
            <div>
                {this.state.updating ? <Loader color="dark" /> : this.state.telemetries && this.renderChart()}
            </div>
        </>
    }
}
