//import 'bootstrap/dist/css/bootstrap.css'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.css';
import './bootstrap.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as moment from 'moment'
import 'moment/locale/fr'
import 'moment-timezone'
import { Callback, makeAuthenticator, makeUserManager } from 'react-oidc';
import Loader from './components/common/Loader';
import AuthorizedUser from './components/api-authorization/AuthorizedUser';
import authService from './components/api-authorization/AuthorizeService';
import { ClearCacheProvider } from 'react-clear-cache';

const thisSite = document.getElementsByTagName("base")[0].baseURI;

const config = {
    authority: 'https://auditkit.bluegriot.com/identity',
    client_id: "auditkit-portal",
    redirect_uri: thisSite + 'auth/login-callback',
    response_type: "id_token token",
    scope: "openid profile admin_api auditkit.manage",
    post_logout_redirect_uri: thisSite + 'auth/logout-callback',
    automaticSilentRenew: true, 
}
// Oidc.Log.level = Oidc.Log.DEBUG
// Oidc.Log.logger = console

const userManager = makeUserManager({...config, revokeAccessTokenOnSignout: true})
authService.userManager = userManager
const AppWithAuth = makeAuthenticator({
  userManager: userManager,
  placeholderComponent: <div className='h-100'>
     <Loader />
  </div>
})(App)

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')?.toString();
const rootElement = document.getElementById('root');

moment.tz.setDefault()
moment.locale('fr')

ReactDOM.render(
  <ClearCacheProvider duration={5000} auto={true} basePath={thisSite}>
    <BrowserRouter basename={baseUrl}>
      <Switch>
        <Route path="/auth" render={routeProps => (
            <Callback
              onSuccess={_ => {
                // `user.state` will reflect the state that was passed in via signinArgs.
                const user = new AuthorizedUser(_)
                if (user.isAdmin) {
                  routeProps.history.push('/organizations')
                }
                else {
                  routeProps.history.push(`/${user.organizationId}/installations`)
                }
              }}
              userManager={userManager}
            />
          )}
        />
        <AppWithAuth />
      </Switch>
    </BrowserRouter>
  </ClearCacheProvider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
