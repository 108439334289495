import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("DeviceSummary")
export class DeviceSummary {
    @JsonProperty("deviceId", String)
    deviceId: string = ''
    
    @JsonProperty("reference", String)
    reference: string = ''
    
    @JsonProperty("createdAtUtc", String)
    createdAtUtc: Date = new Date()
    
    @JsonProperty("updatedAtUtc", String)
    updatedAtUtc: Date = new Date()
    
    @JsonProperty("deviceGroupId", String)
    deviceGroupId?: string = undefined
    
    @JsonProperty("typeName", String)
    typeName: string = ''
   
}