import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { InstallationSummary } from '../../models/dto/Installation'
import InstallationApi from '../../apis/InstallationApi'
import Loader from '../common/Loader'
import Message from '../common/Message'
import authService from '../api-authorization/AuthorizeService'
import { FaPlus } from 'react-icons/fa'
import { Breadcrumb, Button, Modal, Form, Row, Card, Col } from 'react-bootstrap'
import { UserRoles } from '../api-authorization/AuthorizedUser'


interface InstallationListState {
    loaded: boolean
    isAdmin: boolean
    error?: string
    installations: InstallationSummary[]
    showAddModal: boolean
    organizationId: string | null
}

export default class InstallationList extends Component<any, InstallationListState> {
    constructor(props: any) {
        super(props)
        this.state = {
            loaded: false,
            isAdmin: false,
            installations: [],
            showAddModal: false, 
            organizationId: null
        }
    }

    async componentDidMount() {
        document.title = 'AuditKit - Installations'
        try {
            var isAdmin = await authService.hasRole(UserRoles.Administrator)
            var installations = await InstallationApi.getInstance().fetch(this.props.match.params.organization);
            if (installations.length === 1 && !(await authService.getAuthorizedUser())?.isAdmin) {
                window.location.assign(`./${this.props.match.params.organization}/installations/${installations[0].id}`)
                return
            }
            this.setState({
                isAdmin: isAdmin,
                loaded: true,
                installations: installations, 
                organizationId: this.props.match.params.organization
            })
        } catch (error) {
            this.setState({
                loaded: true,
                error: error.message
            })
        }
    }
    private setModalVisible(visible: boolean) {
        this.setState(prevState => ({ ...prevState, showAddModal: visible }))
    }

    private async submit(event: React.FormEvent<HTMLElement>) {
        event.preventDefault()

        var newInstallation = new InstallationSummary()
        const target: any = event.target
        newInstallation.siteName = target.willStartDate.siteName
        newInstallation.willStartDate = new Date(target.willStartDate.value)
        newInstallation.willEndDate = new Date(target.willEndDate.value)

        try {
            await InstallationApi.getInstance().create(this.state.organizationId!, newInstallation)
            window.location.reload(false)
        } catch (error) {
            console.debug(error)
        }
    }

    render() {
        if (this.state.loaded) {
            if (this.state.error !== undefined) {
                return <>
                    <Breadcrumb>
                        <Breadcrumb.Item><b>Installations</b></Breadcrumb.Item>
                    </Breadcrumb>
                    <div>{this.state.error}</div>
                </>
            }
            return (
                <>
                    <div className='d-flex'>
                        <Breadcrumb className='flex-grow-1 ml-n2'>
                            <Breadcrumb.Item><b>Installations</b></Breadcrumb.Item>
                        </Breadcrumb>
                        {this.state.isAdmin &&
                            <div>
                                <Button size="lg" className='ml-3' onClick={() => this.setModalVisible(true)}><FaPlus /></Button><br /><br />
                                <Modal show={this.state.showAddModal} size="lg">
                                    <Form onSubmit={e => this.submit(e)}>
                                        <Modal.Header>
                                            <Modal.Title>
                                                Nouvelle installation
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="p-2">
                                                <Form.Group as={Row} controlId='siteName'>
                                                    <Form.Label sm={3} column>Site</Form.Label>
                                                    <Col sm={6}>
                                                        <Form.Control type="text" name="siteName"/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId='willStartDate'>
                                                    <Form.Label sm={3} column>Début plannifié</Form.Label>
                                                    <Col sm={6}>
                                                        <Form.Control type="date" name="willStartDate"/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId='willEndDate'>
                                                    <Form.Label sm={3} column>Fin plannifié</Form.Label>
                                                    <Col sm={6}>
                                                        <Form.Control type='date' name="willEndDate"/>
                                                    </Col>
                                                </Form.Group>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => this.setState({...this.state, showAddModal: false})}>Annuler</Button>
                                            <Button variant="primary" type="submit">Valider</Button>{' '}
                                        </Modal.Footer>
                                    </Form>
                                </Modal>
                            </div>}
                        </div>
                    {
                        this.state.installations.length === 0 ?
                            <Message title="Aucune installation" message="Contactez le fournisseur pour qu'il configure une installation" />
                            :
                            <div className='row row-cols-1 row-cols-md-2 row-cols-xl-3'>
                                {this.state.installations.map((i) => <InstallationTile key={i.id} installation={i} />)}
                            </div>
                    }
                </>
            )
        }
        return <Loader />
    }
}

interface OrganizationTileProps {
    installation: InstallationSummary
}

interface OrganizationTileState { }

class InstallationTile extends Component<OrganizationTileProps, OrganizationTileState> {
    render() {
        const installation = this.props.installation;
        return (
            <div className="col p-2">
                <Card body>
                    {installation.siteName === undefined ?
                    <Card.Title>{`du ${installation.willStartDate.toLocaleDateString()} au ${installation.willEndDate.toLocaleDateString()}`}</Card.Title> : 
                    <Card.Title>
                        {installation.siteName}
                        <br />
                        <small>{`du ${installation.willStartDate.toLocaleDateString()} au ${installation.willEndDate.toLocaleDateString()}`}</small>
                    </Card.Title>
                    }
                    <Link to={`/${installation.customerId}/installations/${installation.id}`} className="d-flex flex-row-reverse">Détails</Link>
                </Card>
            </div>
        )
    }
}