import { User } from "oidc-client";

export default class AuthorizedUser {
    private user: User | null | undefined

    constructor(user: User | null | undefined) {
        this.user = user
    }

    hasRole(role: any) {
        if (this.user) {
            if (this.user.profile.role instanceof Array) // if it has many roles role is an array
                return this.user.profile.role.includes(role);
            else // otherwise it is a string
                return this.user.profile.role === role;
        }
        else
            return false;
    }

    
    public get isAdmin() : boolean {
        return this.hasRole(UserRoles.Administrator)
    }

    public get isCustomer() : boolean {
        return this.hasRole(UserRoles.Customer)
    }

    public get isCustomerConfig() : boolean {
        return this.hasRole(UserRoles.CustomerConfig)
    }

    public get organizationId() : string | undefined {
        return this.user?.profile.org
    }
    
}

export const UserRoles = {
	Administrator: 'Admin',
	Customer: 'Customer', 
	CustomerConfig: 'CustomerConfig'
}