import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("MapPoint")
export class MapPoint {
    @JsonProperty("x", Number)
    x: number = 0

    @JsonProperty("y", Number)
    y: number = 0
}

@JsonObject("MapPolygon")
export class MapPolygon {
    @JsonProperty("points", [MapPoint])
    points: MapPoint[] = []
}
