import { JsonObject, JsonProperty, JsonConvert } from "json2typescript"
import { AreaDetails } from './Area'

@JsonObject("FloorDetails")
export class FloorDetails {
    @JsonProperty("floorId", String)
    id: string = ''

    @JsonProperty("name", String)
    name: string = ''

    @JsonProperty("installationId", String)
    installationId: string = ''

    @JsonProperty("areas", [AreaDetails])
    areas: AreaDetails[] = []

    copy(): FloorDetails {
        const converter = new JsonConvert()
        const serialized = converter.serializeObject(this, FloorDetails)
        return converter.deserializeObject(serialized, FloorDetails)
    }
}

@JsonObject("FloorNew")
export class FloorNew {
    @JsonProperty("name", String)
    name: string = ''

    @JsonProperty("installationId", String)
    installationId: string = ''

    @JsonProperty("areas", [AreaDetails])
    areas: AreaDetails[] = []
}