const sheet = function(initial = '') {
	// Create the <style> tag
	var style = document.createElement("style");

	// Add a media (and/or media query) here if you'd like!
	// style.setAttribute("media", "screen")
	// style.setAttribute("media", "only screen and (max-width : 1024px)")

	// WebKit hack :(
	style.appendChild(document.createTextNode(initial));

	// Add the <style> element to the page
	document.head.appendChild(style);

	return style.sheet;
};

export default sheet