import { InstallationSummary, Installation, InstallationNew } from '../models/dto/Installation'
import ApiBase from './ApiBase'

export default class InstallationApi extends ApiBase {
    private static instance: InstallationApi;
    
    private constructor() {
        super()
    }

    static getInstance(): InstallationApi {
      if (!InstallationApi.instance) {
        InstallationApi.instance = new InstallationApi()
      }
      return InstallationApi.instance
    }

    public async fetch(organizationId: string): Promise<InstallationSummary[]> {
        return await this.getItems(`/api/customer/${organizationId}/installations`, InstallationSummary)
    }

    public async get(organizationId: string, installationId: string): Promise<Installation> {
        return await this.getItem(`/api/customer/${organizationId}/installations/${installationId}`, Installation)
    }

    public async create(organizationId: string, installation: InstallationNew): Promise<Installation> {
        let result = await this.postItem(`/api/customer/${organizationId}/installations`, installation, InstallationNew, Installation)
        return result!
    }

    public async edit(organizationId: string, installation: Installation): Promise<Installation> {
        let result = await this.putItem(`/api/customer/${organizationId}/installations/${installation.id}`, installation, InstallationNew, Installation)
        return result!
    }

    public async delete(organizationId: string, installationId: string): Promise<void> {
        await this.deleteItem(`/api/customer/${organizationId}/installations/${installationId}`)
    }
}