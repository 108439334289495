import { JsonConverter, JsonCustomConvert } from "json2typescript";
import moment from "moment";

@JsonConverter
export default class MomentConverter implements JsonCustomConvert<moment.Moment> {
    serialize(moment: moment.Moment): any {
        return moment.toISOString();
    }
    deserialize(value: any): moment.Moment {
        return moment(value)
    }
}