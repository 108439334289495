import { JsonObject, JsonProperty } from "json2typescript"
import DateConverter from '../converters/DateConverter'

@JsonObject("InstallationBase")
export abstract class InstallationBase {
    @JsonProperty("willStartDate", DateConverter)
    willStartDate: Date = new Date()

    @JsonProperty("willEndDate", DateConverter)
    willEndDate: Date = new Date()

    @JsonProperty("startedDate", DateConverter, true)
    startedDate?: Date = undefined

    @JsonProperty("endedDate", DateConverter, true)
    endedDate?: Date = undefined

    @JsonProperty("siteName", String, true)
    siteName?: string = undefined

    @JsonProperty("timezone", Number)
    timezone: number = 1

    @JsonProperty("alertsEnabled", Boolean)
    alertsEnabled: boolean = false

    @JsonProperty("workingScheduler", [[Boolean]], true)
    workingScheduler?: boolean[][] = undefined
}

@JsonObject("InstallationSummary")
export class InstallationSummary extends InstallationBase {
    @JsonProperty("installationId", String)
    id: string = ''

    @JsonProperty("customerId", String)
    customerId: string = ''

    @JsonProperty("creationDate", DateConverter)
    creationDate: Date = new Date()

    @JsonProperty("updateDate", DateConverter)
    updateDate: Date = new Date()
}

@JsonObject("Installation")
export class Installation extends InstallationBase {
    @JsonProperty("installationId", String)
    id: string = ''

    @JsonProperty("customerId", String)
    customerId: string = ''

    @JsonProperty("creationDate", DateConverter)
    creationDate: Date = new Date()

    @JsonProperty("updateDate", DateConverter)
    updateDate: Date = new Date()
}

@JsonObject("InstallationNew")
export class InstallationNew extends InstallationBase {
    
}