import { UserManager, User } from 'oidc-client';
import AuthorizedUser from './AuthorizedUser'

export class AuthorizeService {
    private _user?: User | null = null

    userManager!: UserManager

    async isAuthenticated() {
        const user = await this.getUser();
        return !!user;
    }

    get authorizedUser(): AuthorizedUser | undefined {
        if (this._user)
            return new AuthorizedUser(this._user)
        return undefined
    }

    async getAuthorizedUser(): Promise<AuthorizedUser | undefined> {
        await this.getUser()
        if (this._user)
            return new AuthorizedUser(this._user)
        return undefined
    }

    async getUser() {
        if (this._user && this._user.profile) {
            return this._user.profile;
        }

        const user = await this.userManager.getUser();
        this._user = user;
        return user && user.profile;
    }

    async getAccessToken() {
        const user = await this.userManager.getUser();
        return user && user.access_token;
    }

    async hasRole(role: any) {
        const user = await this.getUser();

        if (user) {
            if (user.role instanceof Array) // if it has many roles role is an array
                return user.role.includes(role);
            else // otherwise it is a string
                return user.role === role;
        }
        else
            return false;
    }


    static get instance() { return authService }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};