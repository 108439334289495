import { JsonObject, JsonProperty } from "json2typescript";
import DateConverter from "../converters/DateConverter";

@JsonObject("Organization")
export class Organization {
    @JsonProperty("id", String)
    id: string;

    @JsonProperty("name", String)
    name: string;   
    
    constructor() {
        this.id = '';
        this.name = '';
    }
}

@JsonObject("OrganizationPage")
export class OrganizationPage {
    @JsonProperty("organizations", [Organization])
    organizations: Organization[] = []

    @JsonProperty("pageSize", Number)
    pageSize: number = 0

    @JsonProperty("totalCount", Number)
    totalCount: number = 0
}

@JsonObject("OrganizationDetails")
export class OrganizationDetails {
    @JsonProperty("organizationId", String)
    organizationId: string = ''

    @JsonProperty("name", String)
    name: string = ''

    @JsonProperty("logo", String)
    logo: string = ''

    @JsonProperty("logoContentType", String)
    logoContentType: string = ''

    @JsonProperty("cssOverrides", String, true)
    cssOverrides?: string = ''

    @JsonProperty("date", DateConverter, true)
    date?: Date = undefined
}