import { JsonObject, JsonProperty, JsonConvert } from "json2typescript"
import { MapPolygon } from './Map'

@JsonObject("AreaBase")
export class AreaBase {
    @JsonProperty("name", String)
    name: string = ''

    @JsonProperty("floorId", String)
    floorId: string = ''

    @JsonProperty("polygon", MapPolygon, true)
    polygon?: MapPolygon = undefined
}

@JsonObject("AreaNew")
export class AreaNew extends AreaBase {

}

@JsonObject("AreaDetails")
export class AreaDetails extends AreaBase {
    @JsonProperty("areaId", String)
    id: string = ''

    copy(): AreaDetails {
        const converter = new JsonConvert()
        const serialized = converter.serializeObject(this, AreaDetails)
        return converter.deserializeObject(serialized, AreaDetails)
    }
}