import React, { Component } from "react"
import { Spinner } from "react-bootstrap"

export default class Loader extends Component<{color?: string}> {
    render() {
        return (
        <div className="d-flex h-100 align-items-center justify-content-center">
            <Spinner animation="grow" variant={this.props.color ?? 'dark'} />
        </div>
        )
    }
}