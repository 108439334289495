import React, { Component, RefObject } from "react"
import { RouteComponentProps } from "react-router-dom"
import { Nav, Dropdown, Modal, Form, ModalBody, ModalFooter, Button, Card, Alert, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import InstallationApi from "../../apis/InstallationApi"
import { Installation } from '../../models/dto/Installation'
import { ObjectTypeDetails } from '../../models/dto/ObjectType'
import ObjectTypeApi from "../../apis/ObjectTypeApi"
import { FloorDetails } from '../../models/dto/Floor'
import FloorApi from "../../apis/FloorApi"
import { FaCogs, FaPlus, FaEdit, FaMinus, FaBars, FaSyncAlt, FaBellSlash } from "react-icons/fa"
import { ObjectDetails } from "../../models/dto/Object"
import ObjectApi from "../../apis/ObjectApi"
import Loader from "../common/Loader"
import ModalHeader from "react-bootstrap/esm/ModalHeader"
import { AreaDetails } from "../../models/dto/Area"
import AreaApi from "../../apis/AreaApi"
import ObjectCard from '../objects/ObjectCard'
import RangePicker, { Range } from '../common/RangePicker'
import * as signalR from "@microsoft/signalr";
import AuthorizedUser from "../api-authorization/AuthorizedUser"
import { UserData } from "react-oidc"

interface InstallationDetailsState {
    error?: String
    loading: boolean
    activeTab: string
    data: {
        installation?: Installation
        objectTypes: ObjectTypeDetails[]
        floors: FloorDetails[]
        objects: ObjectDetails[]
    }
    selectedFloor?: FloorDetails
    floorModal: {
        editing?: FloorDetails
        show: boolean
        loading: boolean
        error?: string
    }
    areaModal: {
        editing?: AreaDetails
        show: boolean
        loading: boolean
        error?: string
    }, 
    loadingAreaId?: string, 
    range: Range
}

interface InstallationDetailsMachParams {
    organization: string
    installation: string
}

export default class InstallationDetails extends Component<RouteComponentProps<InstallationDetailsMachParams> | any, InstallationDetailsState> {

    objectCardRefs: Map<string, RefObject<ObjectCard>> = new Map<string, RefObject<ObjectCard>>()
    connection: signalR.HubConnection | undefined


    constructor(props: any) {
        super(props)
        this.state = {
            loading: true,
            activeTab: 'objects', 
            data: {
                installation: undefined,
                objectTypes: [], 
                floors: [], 
                objects: [],
            },
            floorModal: {
                editing: undefined,
                show: false,
                loading: false,
                error: undefined
            },
            areaModal: {
                editing: undefined,
                show: false,
                loading: false,
                error: undefined
            }, 
            loadingAreaId: undefined, 
            range: Range.last || Range.default
        }
    }

    async componentDidMount() {
        document.title = 'AuditKit - Installation ...'
        this.connection = new signalR.HubConnectionBuilder().withUrl("./hubs/live").withAutomaticReconnect().build()
        //this.connection = new signalR.HubConnectionBuilder().withUrl("https://auditkit.bluegriot.com/portal/hubs/live").withAutomaticReconnect().build()
        this.connection.on('onObjectUpdated', (objectId) => {
            console.log("onObjectUpdated " + objectId)
            this.state.range.reload()
            this.objectCardRefs.get(objectId)?.current?.reload()
        })

        const connection = this.connection
        this.connection.onreconnected(async _ => await connection.send('joinInstallation', this.props.match.params.installation))

        try {
            await this.connection.start()
            await this.connection.send('joinInstallation', this.props.match.params.installation)
        } catch (error) {
            console.log(JSON.stringify(error))
        }

        await this.reload()
    }

    async componentWillUnmount() {
        await this.connection?.send('leaveInstallation', this.props.match.params.installation)
        await this.connection?.stop()
    }

    private async reload() {
        try {
            var installation = await InstallationApi.getInstance().get(this.props.match.params.organization, this.props.match.params.installation)
            document.title = `AuditKit - Installation du ${installation.willStartDate.toLocaleDateString()} au ${installation.willEndDate.toLocaleDateString()}`
            var objectTypes = await ObjectTypeApi.getInstance().fetch()
            var floors = await FloorApi.getInstance().fetch(this.props.match.params.organization, this.props.match.params.installation)
            var objects = await ObjectApi.getInstance().fetch(this.props.match.params.organization, this.props.match.params.installation)
            objects = objects.filter((_) => _.enabled);
            this.objectCardRefs = new Map<string, RefObject<ObjectCard>>(objects.map(_ => [_.objectId, React.createRef()]))

            this.state.range.reload()
            
            this.setState({
                ...this.state,
                loading: false,
                data: {
                    ...this.state.data, 
                    installation: installation, 
                    objectTypes: objectTypes, 
                    floors: floors, 
                    objects: objects
                },
                selectedFloor: floors.length > 0 ? floors[0] : undefined
            }, () => {
                this.objectCardRefs.forEach((_) => _.current?.reload())
            })
        } catch (error) {
            this.setState({
                ...this.state,
                loading: false,
                error: error.message, 
            })
        }
    }

    private async editFloor(floor: FloorDetails | undefined) {
        var item = floor?.copy() ?? new FloorDetails()
        this.setState({
            ...this.state, 
            floorModal: {
                ...this.state.floorModal, 
                show: true, 
                editing: item
            }
        })
    }

    private async deleteFloor() {
        if (window.confirm("Supprimer ce niveau ?")) {
            await FloorApi.getInstance().delete(this.props.match.params.organization, this.props.match.params.installation, this.state.selectedFloor!.id)
            window.location.reload(false)
        }
    }

    private async editArea(area: AreaDetails | undefined) {
        var item = area?.copy() ?? new AreaDetails()
        this.setState({
            ...this.state, 
            areaModal: {
                ...this.state.areaModal, 
                show: true, 
                editing: item
            }
        })
    }

    private async deleteArea() {
        if (window.confirm("Supprimer cette zone ?")) {
            await AreaApi.getInstance().delete(this.props.match.params.organization, this.props.match.params.installation, this.state.areaModal.editing!.id)
            window.location.reload(false)
        }
    }

    private async submitArea(e: React.FormEvent<HTMLElement>) {
        e.preventDefault()

        const { editing } = this.state.areaModal
        const target: any = e.target
        editing!.name = target.name.value
        try {
            if (editing!.id === '') {
                editing!.floorId = this.state.selectedFloor!.id
                await AreaApi.getInstance().create(this.props.match.params.organization, this.props.match.params.installation, editing!)
            }
            else {
                await AreaApi.getInstance().edit(this.props.match.params.organization, this.props.match.params.installation, editing!)
            }
            window.location.reload(false)
        } catch (error) {
            
        }
    }

    private async selectFloor(floorId: string | null) {
        if (floorId === null) {
            return
        }
        if (floorId === 'installation') {
            window.location.replace(`./${this.props.match.params.organization}/installations`)
            return
        }
        const floor = this.state.data.floors.find(_ => _.id === floorId)
        this.setState({
            ...this.state, 
            selectedFloor: floor
        })
    }

    private async submitFloor(e: React.FormEvent<HTMLElement>) {
        e.preventDefault()

        const { editing } = this.state.floorModal
        const target: any = e.target
        editing!.name = target.name.value
        try {
            if (editing?.id === '') {
                await FloorApi.getInstance().create(this.props.match.params.organization, this.props.match.params.installation, editing!)
            }
            else {
                await FloorApi.getInstance().edit(this.props.match.params.organization, this.props.match.params.installation, editing!)
            }
            window.location.reload(false)
        } catch (error) {
            
        }
    }

    private async onAreaDrop(e: React.DragEvent<HTMLDivElement>) {
        let id = e.dataTransfer.getData("text")
        let objects = this.state.data.objects
        let object = objects.find(_ => _.objectId === id)
        if (object) {
            let oldAreaId = object.areaId
            let areaId = e.currentTarget.dataset.id
            object.areaId = areaId

            this.setState({
                ...this.state, 
                loadingAreaId: areaId
            })

            try {
                await ObjectApi.getInstance().edit(this.props.match.params.organization, this.props.match.params.installation, object)
            } catch (error) {
                object.areaId = oldAreaId
            }

            this.setState({
                ...this.state, 
                data: {
                    ...this.state.data, 
                    objects: objects
                }, 
                loadingAreaId: undefined
            })
        }
    }

    private async onAreaDragOver(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault()
    }

    private async onObjectDragStart(e: React.DragEvent<HTMLDivElement>, object: ObjectDetails) {
        e.dataTransfer.setData("text/plain", object.objectId)
    }

    private onRangeChanged(range: Range) {
        this.setState({
            ...this.state, 
            range: range
        }, () => this.reload())
    }

    render() {
        const { installation, objects, floors,  } = this.state.data
        const availableObjects = objects.filter(_ => !_.areaId)
        return (
        <div className="row row-cols-12 pt-0">
            <div className="col-1 d-none d-xl-block"></div>
            <div className="col-12 col-xl-9" style={{paddingLeft: '6vw'}}>
                <div className='w-100 d-flex p-3 pt-4 bg-light rounded-bottom mt-n3 border border-primary rounded-lg mb-3'>
                    <h4 className='text-primary mt-1'><span className='text-muted'>INSTALLATIONS: </span> { installation ? 
                    installation.siteName === undefined ? `${installation.siteName} du ${installation.willStartDate.toLocaleDateString()} au ${installation.willEndDate.toLocaleDateString()}` : `du ${installation.willStartDate.toLocaleDateString()} au ${installation.willEndDate.toLocaleDateString()}` : 
                    '...'}</h4>
                    <Nav variant="pills" className="flex-grow-1 mx-3" activeKey={this.state.selectedFloor?.id} onSelect={(k) => this.selectFloor(k)}>
                        {floors.length > 1 &&
                            floors.map(_ => <Nav.Item key={_.id}><Nav.Link eventKey={_.id} style={{color: 'white'}}>{_.name}</Nav.Link></Nav.Item>)
                        }
                    </Nav>
                    <div>{ !(installation?.alertsEnabled ?? true) && <OverlayTrigger placement='bottom' overlay={<Tooltip id={`installation-alert-tooltip`}>Alertes désactivées</Tooltip>}>
                        <FaBellSlash className='mr-3 text-secondary' size="40px" />
                    </OverlayTrigger> }</div>
                    <RangePicker onChanged={range => this.onRangeChanged(range)} range={this.state.range} />
                    <UserData.Consumer>
                        {(context) => {
                            const user = new AuthorizedUser(context.user)
                            if (user.isAdmin || user.isCustomerConfig) {
                                return <Dropdown className='ml-2'>
                                    <Dropdown.Toggle>
                                        <FaBars />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Header>Niveaux</Dropdown.Header>
                                        <Dropdown.Item onClick={() => {this.editFloor(undefined)}}>
                                            <FaPlus /> Ajouter
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => {this.editFloor(this.state.selectedFloor)}}>
                                            <FaEdit /> Modifier
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.deleteFloor()}>
                                            <FaMinus /> Supprimer
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Header>Zones</Dropdown.Header>
                                        <Dropdown.Item onClick={() => this.editArea(undefined)}>
                                            <FaPlus /> Ajouter
                                        </Dropdown.Item>
                                        {user.isAdmin && 
                                        <>
                                        <Dropdown.Divider />
                                        <Dropdown.Item href={`./${this.props.match.params.organization}/installations/${this.props.match.params.installation}/administration`}>
                                            <FaCogs /> Administration
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={() => this.reload()}>
                                            <FaSyncAlt /> Recharger
                                        </Dropdown.Item>
                                        </>}
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                            return <></>
                        }}
                    </UserData.Consumer>
                </div>
                
                {this.state.floorModal.editing &&  
                <Modal show={this.state.floorModal.show}>
                    <ModalHeader>
                        {this.state.floorModal.editing!.id === '' ? 'Nouveau niveau' : `Modification du niveau ${this.state.floorModal.editing!.name}`}
                    </ModalHeader>
                    <Form onSubmit={(e) => this.submitFloor(e)}>
                        <ModalBody>
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>Nom</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="text" id="name" name="name" defaultValue={this.state.floorModal.editing!.name} />
                                </Col>
                            </Form.Group>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={() => this.setState({...this.state, floorModal:{...this.state.floorModal, show: false}})}>Annuler</Button>{' '}
                            <Button variant="primary" type="submit">Valider</Button>
                        </ModalFooter>
                    </Form>
                </Modal>}
                {this.state.loading && <div className="mt-5"><Loader color="dark" /></div>}
                {this.state.error && <div>{this.state.error}</div>}
                {(!this.state.error && installation) && this.renderContent()}
            </div>
            <div className="col-2 d-none d-xl-block position-absolute" style={{top: 58, right: 0, width: '100%', height: '100%', minHeight: '120vh'}} onDrop={(e: React.DragEvent<HTMLDivElement>) => this.onAreaDrop(e)} onDragOver={(e: React.DragEvent<HTMLDivElement>) => this.onAreaDragOver(e)}>
                <div className='position-sticky' style={{top: 58}}>
                    {availableObjects.length > 0 && 
                    <div className='h-100'>
                        <div className='w-100 d-flex p-3 pt-4 bg-light rounded-bottom mt-n3 border border-primary rounded-lg mb-3'>
                            <h4 className='text-primary mt-1'>OBJETS DISPONIBLES</h4>
                        </div>
                        <Card body className='h-100'>
                            {availableObjects.map(_ => <div key={_.objectId} className='mb-2'>
                                <Card draggable onDragStart={(e: React.DragEvent<HTMLDivElement>) => this.onObjectDragStart(e, _)}>
                                    <Card.Body>
                                        {_.name}
                                    </Card.Body>
                                </Card>
                            </div>)}
                        </Card>
                    </div>
                    }
                </div>
            </div>
            {this.state.areaModal.editing &&  
                <Modal show={this.state.areaModal.show}>
                    <ModalHeader>
                        {this.state.areaModal.editing!.id === '' ? 'Nouvelle zone' : `Modification de la zone ${this.state.areaModal.editing!.name}`}
                    </ModalHeader>
                    <Form onSubmit={(e) => this.submitArea(e)}>
                        <ModalBody>
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>Nom</Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="text" id="name" name="name" defaultValue={this.state.areaModal.editing!.name} />
                                </Col>
                            </Form.Group>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={() => this.setState({...this.state, areaModal:{...this.state.areaModal, show: false}})}>Annuler</Button>{' '}
                            {this.state.areaModal.editing!.id !== '' && <Button variant="danger" onClick={() => this.deleteArea()}>Supprimer</Button>}
                            <Button variant="primary" type="submit">Valider</Button>
                        </ModalFooter>
                    </Form>
                </Modal>}
        </div>
        )
    }
    

    private renderContent() {
        const {selectedFloor, data, loadingAreaId} = this.state
        if (selectedFloor?.areas.length === 0) {
            return <Alert variant='info' className="mt-3">
                Ajouter une zone pour y déposer un objet
            </Alert>
        }
        return <>
            {selectedFloor!.areas.map(_ => <Card key={_.id} className="mb-4">
                <Card.Header className='bg-primary secondary-text text-center area-header'>
                    <div className='d-flex'>
                        <div className='flex-grow-1'><h3 className='mt-2'>{_.name}</h3></div>
                        <Button className='my-2' variant='outline-light' onClick={__ => this.editArea(_)}><FaCogs /></Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    {loadingAreaId && _.id === loadingAreaId ? <Loader /> : 
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3" data-id={_.id} style={{minHeight: '10vh'}} onDrop={(e: React.DragEvent<HTMLDivElement>) => this.onAreaDrop(e)} onDragOver={(e: React.DragEvent<HTMLDivElement>) => this.onAreaDragOver(e)}>
                        {data.objects.filter(o => o.areaId === _.id).sort((a, b) => a.name.localeCompare(b.name)).map(o => 
                        <div className="col p-2" key={o.objectId} draggable onDragStart={(e: React.DragEvent<HTMLDivElement>) => this.onObjectDragStart(e, o)}>
                            {
                            <ObjectCard 
                                ref={this.objectCardRefs.get(o.objectId)!}
                                organization={this.props.match.params.organization} 
                                installation={this.props.match.params.installation} 
                                object={o} 
                                objectType={this.state.data.objectTypes.find(_ => _.id === o.objectTypeId)!} 
                                range={this.state.range}
                                onObjectUpdated={(_) => this.reload()} />
                            }
                        </div>)}
                    </div>
                    }
                </Card.Body> 
            </Card>)}
        </>
    }
    
}