import React, { Component } from 'react';
import { Organization } from '../../models/dto/Organization';
import OrganizationApi from '../../apis/OrganizationApi'
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';

interface OrganizationListProps {}

interface OrganizationListState {
    loaded: boolean
    error?: string
    organizations: Organization[]
}

export default class OrganizationList extends Component<OrganizationListProps, OrganizationListState> {
    constructor(props: OrganizationListProps) {
        super(props)
        this.state = {
            loaded: false,
            organizations: []
        }
    }

    async componentDidMount() {
        document.title = 'AuditKit - Organisations'
        try {
            var page = await OrganizationApi.getInstance().fetch();
            this.setState({
                loaded: true,
                organizations: page.organizations
            })
        } catch (error) {
            this.setState(({
                ...this.state,
                loaded: true,
                error: error.message
            }))
        }
    }

    render() {
        if (this.state.loaded) {
            if (this.state.error !== undefined) {
                return <div><p>{this.state.error}</p></div>
            }
            return (
                <div className="row row-cols-1 row-cols-md-3 row-cols-xl-4">
                    {this.state.organizations.reverse().map((org) => <OrganizationTile key={org.id} organization={org} />)}
                </div>
            );
        }
        return (<div>Loading...</div>)
    }
}


interface OrganizationTileProps {
    organization: Organization
}

interface OrganizationTileState {}

class OrganizationTile extends Component<OrganizationTileProps, OrganizationTileState> {
    render() {
        const organization = this.props.organization;
        return (
            <div className="col p-2">
                <Card body>
                    <Card.Title>{ organization.name }</Card.Title>
                    <Link to={`./${organization.id}/installations`} className="d-flex flex-row-reverse">Voir</Link>
                </Card>
            </div>
        )
    }
}