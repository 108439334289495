import { JsonConverter, JsonCustomConvert } from "json2typescript";
import moment from "moment";

@JsonConverter
export default class DateConverter implements JsonCustomConvert<Date | undefined> {
    serialize(date: Date): any {
        return date.toUTCString();
    }
    deserialize(date: any): Date | undefined {
        if (!date) {
            return undefined
        }
        return moment.utc(date).toDate()
    }
}