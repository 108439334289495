import React, { Component } from "react";
import { Alert } from "react-bootstrap";

class MessageProps {
    title?: string
    message!: string
    variant?: string
}

export default class Message extends Component<MessageProps> {
    render() {
        return (
        <Alert variant={this.props.variant ?? "info"}>
            { this.props.title !== null ? <Alert.Heading>{this.props.title}</Alert.Heading> : null }
            <p>{this.props.message}</p>
        </Alert>
        )
    }
}