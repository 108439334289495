import * as React from 'react';
import TelemetryApi from '../../../apis/TelemetryApi';
import Query from '../../../models/dto/Query';
import { FaLongArrowAltRight, FaLongArrowAltLeft } from 'react-icons/fa';
import ObjectCardContent, { IObjectCardContentProps, IObjectCardContentState } from '../ObjectCardContent';
import Chart from 'react-google-charts';
import Loader from '../../common/Loader';
import { ObjectDetails } from '../../../models/dto/Object';

export interface IPassageCounterProps extends IObjectCardContentProps {

}

export interface IPassageCounterState extends IObjectCardContentState {
    telemetries?: any[]
    aliases: {
        toLeft: string
        toRight: string
    }
}

export default class PassageCounter extends ObjectCardContent<IPassageCounterProps, IPassageCounterState> {
    static objectTypeId = 'a2a80b26-0093-4b71-8364-d23e232a2fc1'

    getInitialState(): IPassageCounterState {
        return {
            loading: true, 
            updating: true,
            aliases: {
                toLeft: '', 
                toRight: ''
            }
        }
    }

    async updateState(_: ObjectDetails): Promise<IPassageCounterState> {
        const toLeftAlias = this.props.object.getSettingValue('toLeftAlias', this.props.objectType.settings)
        const toRightAlias = this.props.object.getSettingValue('toRightAlias', this.props.objectType.settings)

        var rows = await TelemetryApi.getInstance().query(this.props.organization, new Query().withRange(this.props.range.toQueryRange()).withFilters([
            `r["object"] == "${this.props.object.objectId}"`,
            `r["_measurement"] == "counters"`,
            `r["_field"] == "toleft" or r["_field"] == "toRight"`
        ]).withKeepColumns(['_time', 'toleft', 'toRight']).withAggregateWindow('last', this.props.range.interval.duration, true).withPivot(['_field'], ['_time'], '_value'))
        rows = rows.map(row => {
            return {
                ...row,
                time: Date.parse(row._time), 
                _time: new Date(row._time), 
                toleft: row.toleft === '' ? null : parseFloat(row.toleft), 
                toRight: row.toRight === '' ? null : parseFloat(row.toRight)
            }
        }).sort((a: any, b: any) => a.time - b.time)

        var data = rows.map(_ => [_._time, _.toleft, _.toRight])

        return {
            ...this.state, 
            aliases: {
                toLeft: toLeftAlias ?? 'Gauche',
                toRight: toRightAlias ?? 'Droite'
            }, 
            telemetries: data
        }
    }

    private renderState() {
        return <div className='row row-cols-2'>
            <div className='text-center'>
                <h3>{this.state.aliases.toLeft} <FaLongArrowAltLeft /></h3>
                <div className='text-right' style={{ display: 'inline-block' }}>
                    <h1 className='mb-0'>{this.props.object.shadow.ToLeft}</h1>
                    <div className='mt-n2'><small style={{color:'#8884d8'}}>passages</small></div>
                </div>
            </div>
            <div className='text-center'>
                <h3>{this.state.aliases.toRight} <FaLongArrowAltRight /></h3>
                <div className='text-right' style={{ display: 'inline-block' }}>
                    <h1 className='mb-0'>{this.props.object.shadow.ToRight}</h1>
                    <div className='mt-n2'><small style={{color:'#82ca9d'}}>passages</small></div>
                </div>
            </div>
        </div>
    }

    private renderChart() {
        if (this.state.telemetries!.length === 0) {
            return <div className='d-flex h-100 align-items-center justify-content-center'>Aucune donnée pour cette période</div>
        }
        return <div className='mt-n3'>
            <Chart 
              chartLanguage='fr'
              chartType="LineChart"
              height={140}
              width='100%'
              data={[
                [{ type: 'datetime' },
                this.state.aliases.toLeft,
                this.state.aliases.toRight], 
                ...this.state.telemetries!
              ]}
              options={{
                colors:['#8884d8', '#82ca9d'],
                legend: { position: 'none'}, 
                chartArea: { width: '85%', height: '75%'},
                hAxis: {
                  minValue: this.props.range.start.toDate(), 
                  maxValue: this.props.range.stop?.toDate() ?? new Date()
                }
              }}
              rootProps={{object: this.props.object.objectId}}
            />
        </div>
    }

    render() {
        return super.render() ?? this.renderContent()
    }

    private renderContent() {
        if (this.props.object.shadow.ToLeft === undefined && this.props.object.shadow.ToRight === undefined) {
            return <div className='d-flex h-100 align-items-center justify-content-center'>Aucune donnée pour cette période</div> 
        }
        return <>
            <div>
                {this.renderState()}
            </div>
            <hr />
            <div>
                {this.state.updating ? <Loader color="dark" /> : this.state.telemetries && this.renderChart()}
            </div>
        </>
    }
}
