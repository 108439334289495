import React, { Component } from "react";
import { ObjectTypeSetting, ObjectSettingValueTypes, ObjectSettingValueTypesLabel } from'../../models/dto/ObjectType'
import { FaMinus } from 'react-icons/fa'
import { Form, Button } from "react-bootstrap";


interface ObjectTypeSettingEditorProps {
    setting: ObjectTypeSetting
    index: number
    onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void)
    onRemove: ((index: number) => void)
}
export default class ObjectTypeSettingEditor extends Component<ObjectTypeSettingEditorProps> {
    render() {
        let nameId = `settingName-${this.props.index}`, valueTypeId = `settingValueType-${this.props.index}`
        return <tr>
            <td>
                <Form.Control type="text" name={nameId} data-prop="name" data-id={this.props.index} value={this.props.setting.name} onChange={this.props.onChange} />
            </td>
            <td>
                <Form.Control type="text" name={nameId} data-prop="variableName" data-id={this.props.index} value={this.props.setting.variableName} onChange={this.props.onChange} />
            </td>
            <td>
                <Form.Control as="select" name={valueTypeId} data-prop="valueType" data-id={this.props.index} value={this.props.setting.valueType} onChange={this.props.onChange}>
                    { Object.values(ObjectSettingValueTypes).filter(key => !isNaN(Number(key))).map((key) => <option key={key} value={ key }>{ ObjectSettingValueTypesLabel(key as ObjectSettingValueTypes) }</option>) }
                </Form.Control>
            </td>
            <td>
                <Form.Check type="checkbox" data-prop="isAdministrationOnly" data-id={this.props.index} checked={this.props.setting.isAdministrationOnly} onChange={this.props.onChange} />
            </td>
            <td>
                <Form.Check type="checkbox" data-prop="isSharedWithDevice" data-id={this.props.index} checked={this.props.setting.isSharedWithDevice} onChange={this.props.onChange} />
            </td>
            <td>
                <Form.Check type="checkbox" data-prop="isMandatory" data-id={this.props.index} checked={this.props.setting.isMandatory} onChange={this.props.onChange} />
            </td>
            <td>
                <Button type="button" className='mr-n1' variant="danger" onClick={() => this.props.onRemove(this.props.index)}><FaMinus /></Button>
            </td>
        </tr>
    }
}