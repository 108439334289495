import * as React from 'react';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap'
import { QueryRange } from '../../models/dto/Query';
import { JsonObject, JsonProperty, JsonConvert } from 'json2typescript';
import MomentConverter from '../../models/converters/MomentConverter'

export class Interval {
    duration: string = '1h'
    formatFn!: (date: moment.Moment) => string
    d3Format: string = '%d/%m'

    constructor(duration: string, d3Format: string, formatFn: (date: moment.Moment) => string) {
        this.duration = duration
        this.d3Format = d3Format
        this.formatFn = formatFn
    }
}

@JsonObject("Range")
export class Range {
    @JsonProperty("key", String, true)
    key: string = 'OTHER'

    @JsonProperty("defaultName", String, true)
    defaultName?: string = undefined
    
    @JsonProperty("start", MomentConverter)
    start: moment.Moment = moment()

    @JsonProperty("stop", MomentConverter, true)
    stop?: moment.Moment = undefined

    public get interval(): Interval {
        var i = moment.duration((this.stop || moment()).diff(this.start)).asDays()
        if (i <= 1) {
            i = moment.duration((this.stop || moment()).diff(this.start)).asHours()
            if (i <= 2) {
                return new Interval('5m', '%H:%M', (m) => `${m.format('HH:mm')}`)
            }
            return new Interval('1h', '%Hh', (m) => `${m.format('HH[h]')}`)
        }
        if (i <= 7) {
            return new Interval('12h', '%d/%m %Hh', (m) => `${m.format('DD/MM')} ${m.format('HH[h]')}`)
        }
        if (i <= 30) {
            return new Interval('1d', '%d/%m', (m) => `${m.format('DD/MM')}`)
        }
        return new Interval('1d', '%d/%m', (m) => `${m.format('DD/MM')}`)
    }

    static create({key = 'OTHER', defaultName = '', start = moment(), stop = moment() }): Range {
        var range = new Range()
        range.key = key
        range.defaultName = defaultName
        range.start = start
        range.stop = stop

        range.reload()

        return range
    }

    public get name(): string {
        if (this.defaultName) {
            return this.defaultName
        }
        if (this.start) {
            var str = `De ${this.start.calendar()}`
            if (this.stop) {
                str += ` à ${this.stop.calendar()}`
            }
            return str
        }
        return '?'
    }

    public reload() {
        switch (this.key) {
            case 'LAST_HOUR':
                this.start = moment().subtract(1, 'hours')
                this.stop = moment()
                break;
            case 'TODAY':
                this.start = moment().startOf('day')
                this.stop = moment().endOf('day')
                break;
            case 'THIS_WEEK':
                this.start = moment().startOf('week')
                this.stop = moment().endOf('week')
                break;
            case 'THIS_MONTH':
                this.start = moment().startOf('month')
                this.stop = moment().endOf('month')
                break;
            case 'LAST_7_DAYS':
                this.start = moment().subtract(7, 'days').startOf('day')
                this.stop = moment().endOf('day')
                break;
            case 'YESTERDAY':
                this.start = moment().subtract(1, 'days').startOf('day')
                this.stop = moment().subtract(1, 'days').endOf('day')
                break;
            case 'LAST_WEEK':
                this.start = moment().subtract(1, 'weeks').startOf('week')
                this.stop = moment().subtract(1, 'weeks').endOf('week')
                break;
            case 'LAST_MONTH':
                this.start = moment().subtract(1, 'months').startOf('month')
                this.stop = moment().subtract(1, 'months').endOf('month')
                break;
            default:
                break;
        }
    }

    private static jsonConverter = new JsonConvert()

    static get default(): Range {
        return RangePicker.defaultRanges[1]
    }

    static get last(): Range | undefined {
        var last = localStorage.getItem('time-Range')
        if (last) {
            return Range.jsonConverter.deserializeObject(JSON.parse(last), Range)
        }
        return undefined
    }

    static set last(value: Range | undefined) {
        if (value) {
            localStorage.setItem('time-Range', JSON.stringify(Range.jsonConverter.serializeObject(value!)))
        }
        else {
            localStorage.removeItem('time-Range')
        }
    }

    toQueryRange(): QueryRange {
        return new QueryRange({
            start: this.start?.toISOString(), 
            stop: this.stop?.toISOString()
        })
    }
}

export interface IRangePickerProps {
    range: Range
    onChanged: (range: Range) => void
}

export interface IRangePickerState {
    
}

export default class RangePicker extends React.Component<IRangePickerProps, IRangePickerState> {
    static defaultRanges: Range[] = [
        Range.create({ key: 'LAST_HOUR', defaultName: "Dernière heure", start: moment().subtract(1, 'hours') }), 
        Range.create({ key: 'TODAY', defaultName: "Aujourd'hui", start: moment().startOf('day'), stop:  moment().startOf('day').add(1, 'days')}), 
        Range.create({ key: 'THIS_WEEK', defaultName: "Cette semaine", start: moment().startOf('week') }), 
        Range.create({ key: 'THIS_MONTH', defaultName: "Ce mois-ci", start: moment().startOf('month') }), 
        Range.create({ key: 'LAST_7_DAYS', defaultName: "7 derniers jours", start: moment().subtract(7, 'days').startOf('day') }), 
        Range.create({ key: 'YESTERDAY', defaultName: "Hier", start: moment().subtract(1, 'days').startOf('day'), stop: moment().startOf('day') }), 
        Range.create({ key: 'LAST_WEEK', defaultName: "Semaine dernière", start: moment().subtract(1, 'weeks').startOf('week'), stop: moment().startOf('week') }),
        Range.create({ key: 'LAST_MONTH', defaultName: "Mois dernier", start: moment().subtract(1, 'months').startOf('month'), stop: moment().startOf('month') })
    ]

    constructor(props: IRangePickerProps) {
        super(props);

        this.state = {}
    }

    private didSelectDefaultRange(defaultRange: Range) {
        Range.last = defaultRange
        this.props.onChanged(defaultRange)
    }

    public render() {
        return (
            <Dropdown>
                <Dropdown.Toggle>
                    {this.props.range.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {RangePicker.defaultRanges.map((_, index) => <Dropdown.Item key={index} onClick={__ =>this.didSelectDefaultRange(_)}>
                        {_.name}
                    </Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
