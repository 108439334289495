import ApiBase from './ApiBase';
import Query from '../models/dto/Query'
import { parse } from '@fast-csv/parse'

export default class TelemetryApi extends ApiBase {
    private static instance: TelemetryApi;

    private constructor() {
        super("https://auditkit.bluegriot.com/telemetry")
    }
  
    static getInstance(): TelemetryApi {
      if (!TelemetryApi.instance) {
        TelemetryApi.instance = new TelemetryApi()
      }
  
      return TelemetryApi.instance
    }

    public async query(organization: string, query: Query): Promise<any[]> {
        var result = await this.postQuery(`/telemetries/${organization}/influx/query`, query, Query)

        return new Promise<any[]>((resolve, reject) => {
          var rows: any[] = []
          const stream = parse({comment: '#', headers: true, delimiter:",", ignoreEmpty: true })
          stream.on('data', row => {
            rows.push(row)
          })
          stream.on('end', (_: number) => {
            resolve(rows)
          })
          stream.on('error', error => {
            reject(error)
          })
          stream.on('data-invalid', row => {
            reject(`Invalid [row=${JSON.stringify(row)}]`)
          })
          stream.write(result)
          stream.end()
        })
    }

    private async postQuery<TBody>(url: string, item: TBody, classReference: { new (): TBody }, allowAnonymous: boolean = false): Promise<string | undefined> {
      const body = this.jsonConverter.serializeObject(item, classReference)
      const result = await this.client.post(url, body, {
          headers: await this.getHeader(allowAnonymous), 
          transformResponse: (data) => data
      })
      await this.handleResponse(result)
      const data: string = result.data
      return data
  }
}