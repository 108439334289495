import React, { Component } from "react";
import { ObjectTypeAlertSetting, ObjectTypeDetails, ObjectTypeSetting } from '../../models/dto/ObjectType'
import ObjectTypeApi from '../../apis/ObjectTypeApi'
import { FaPlus, FaEdit } from 'react-icons/fa'
import Loader from "../common/Loader";
import Message from "../common/Message";
import ObjectTypeSettingEditor from './ObjectTypeSettingEditor'
import ObjectTypeAlertSettingEditor from './ObjectTypeAlertSettingEditor'
import { Guid } from "guid-typescript";
import { Breadcrumb, Button, Modal, Form, Card, Table } from "react-bootstrap";

//import "ace-builds";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-language_tools"
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/webpack-resolver";

interface ObjectTypeListState {
    error?: String
    isLoading: boolean, 
    objectTypes: ObjectTypeDetails[] | undefined
    isModalOpen: boolean
    editingObjectType: ObjectTypeDetails | undefined 
    editingLoading: boolean
    editingError?: string
}
export default class ObjectTypeList extends Component<any, ObjectTypeListState> {
    private evaluationScriptEditor: React.RefObject<AceEditor> = React.createRef<AceEditor>()
    private evaluationAlertScriptEditor: React.RefObject<AceEditor> = React.createRef<AceEditor>()

    constructor(props: any) {
        super(props)
        this.state = {
            isLoading: true, 
            objectTypes: undefined, 
            isModalOpen: false, 
            editingObjectType: undefined,
            editingLoading: false
        }
    }
    async componentDidMount() {
        document.title = 'AuditKit - Types d\'objet'
        const objectType = await ObjectTypeApi.getInstance().fetch()
        this.setState({
            isLoading: false, 
            objectTypes: objectType
        })
    }

    private async submit(event: React.FormEvent<HTMLElement>) {
        event.preventDefault()

        var objectType = this.state.editingObjectType!
        const target: any = event.target
        objectType.name = target.name.value
        objectType.description = target.description.value
        objectType.evaluationScript = this.evaluationScriptEditor.current!.editor.getValue()!
        objectType.alertEvaluationScript = this.evaluationAlertScriptEditor.current!.editor.getValue()!

        objectType.settings.forEach(element => {
            if (element.key === '') {
                element.key = Guid.create().toString()
            }
        });

        objectType.alertSettings.forEach(element => {
            if (element.key === '') {
                element.key = Guid.create().toString()
            }
        });

        try {
            this.setState({...this.state, editingLoading: true})
            if (objectType.id === '') {
                await ObjectTypeApi.getInstance().create(objectType)
            }
            else {
                await ObjectTypeApi.getInstance().edit(objectType)
            }
            window.location.reload(false)
        } catch (error) {
            this.setState({...this.state, editingLoading: false, editingError: error.message})
        }
    }


    private async handleSettingChange(event: React.ChangeEvent<HTMLInputElement>) {
        let objectType = this.state.editingObjectType!
        if (event.target.dataset.prop! === "name") {
            objectType.settings[Number(event.target.dataset.id!)].name = event.target.value
        }
        else if (event.target.dataset.prop! === "valueType") {
            objectType.settings[Number(event.target.dataset.id!)].valueType = Number(event.target.value)
        }
        else if (event.target.dataset.prop! === "variableName") {
            objectType.settings[Number(event.target.dataset.id!)].variableName = event.target.value
        }
        else if (event.target.dataset.prop! === "isAdministrationOnly") {
            objectType.settings[Number(event.target.dataset.id!)].isAdministrationOnly = event.target.checked
        }
        else if (event.target.dataset.prop! === "isSharedWithDevice") {
            objectType.settings[Number(event.target.dataset.id!)].isSharedWithDevice = event.target.checked
        }
        else if (event.target.dataset.prop! === "isMandatory") {
            objectType.settings[Number(event.target.dataset.id!)].isMandatory = event.target.checked
        }
        this.setState({...this.state, editingObjectType: objectType})
    }

    private async handleSettingRemove(index: number) {
        let objectType = this.state.editingObjectType!
        objectType.settings.splice(index, 1)
        this.setState({...this.state, editingObjectType: objectType})
    }

    private async handleSettingAdd() {
        let objectType = this.state.editingObjectType!
        objectType.settings.push(new ObjectTypeSetting())
        this.setState({...this.state, editingObjectType: objectType})
    }

    private async handleAlertSettingChange(event: React.ChangeEvent<HTMLInputElement>) {
        let objectType = this.state.editingObjectType!
        if (event.target.dataset.prop! === "name") {
            objectType.alertSettings[Number(event.target.dataset.id!)].name = event.target.value
        }
        else if (event.target.dataset.prop! === "valueType") {
            objectType.alertSettings[Number(event.target.dataset.id!)].valueType = Number(event.target.value)
        }
        else if (event.target.dataset.prop! === "variableName") {
            objectType.alertSettings[Number(event.target.dataset.id!)].variableName = event.target.value
        }
        else if (event.target.dataset.prop! === "isAdministrationOnly") {
            objectType.alertSettings[Number(event.target.dataset.id!)].isAdministrationOnly = event.target.checked
        }
        else if (event.target.dataset.prop! === "isMandatory") {
            objectType.alertSettings[Number(event.target.dataset.id!)].isMandatory = event.target.checked
        }
        this.setState({...this.state, editingObjectType: objectType})
    }

    private async handleAlertSettingRemove(index: number) {
        let objectType = this.state.editingObjectType!
        objectType.alertSettings.splice(index, 1)
        this.setState({...this.state, editingObjectType: objectType})
    }

    private async handleAlertSettingAdd() {
        let objectType = this.state.editingObjectType!
        objectType.alertSettings.push(new ObjectTypeAlertSetting())
        this.setState({...this.state, editingObjectType: objectType})
    }

    private async handleDelete() {
        let objectType = this.state.editingObjectType!
        try {
            this.setState({...this.state, editingLoading: true})
            await ObjectTypeApi.getInstance().delete(objectType.id)
            window.location.reload(false)
        } catch (error) {
            this.setState({...this.state, editingLoading: false, editingError: error.message})
        }
    }

    private edit(objectType: ObjectTypeDetails | undefined) {
        var editingItem = objectType ? objectType.copy() : new ObjectTypeDetails()
        if (editingItem.evaluationScript === '') {
            editingItem.evaluationScript = `return {
  "Telemetries":[
    {
      "Measurement": "zzzz",
      "Fields": {
        "value": attributes.sensor.yyy
      }
    }
  ], 
  "Shadow": {
    "xxx": attributes.shadow.xxx + attributes.sensor.yyy
  }
}`
        }
        this.setState({
            ...this.state, 
            editingObjectType: editingItem, 
            isModalOpen: true
        })
    }

    render() {
        return <>
            <div className="d-flex">
                <Breadcrumb className='flex-grow-1'>
                    <Breadcrumb.Item>
                        <b>Types d'objet</b>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Button className="ml-2 align-self-start" size="lg" onClick={ ()=> this.edit(undefined) }><FaPlus /></Button>
            </div>
                <Modal show={this.state.isModalOpen} size="xl">
                    <Modal.Header>
                        <Modal.Title>{ this.state.editingObjectType ? `Type d'objet: ${ this.state.editingObjectType.name }` : "Nouveau type d'objet" }</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={e => this.submit(e)}>
                        <Modal.Body>
                        <div className="p-2">
                            {this.state.editingLoading ? <Loader /> : 
                            <>
                            <Form.Group controlId="name">
                                <Form.Label sm={1}>Nom</Form.Label>
                                <Form.Control type="text" name="name" defaultValue={this.state.editingObjectType?.name} />
                            </Form.Group>
                            <Form.Group controlId="description">
                                <Form.Label sm={1}>Description</Form.Label>
                                <Form.Control as="textarea" rows={3} name="description" defaultValue={this.state.editingObjectType?.description} />
                            </Form.Group>
                            <Card>
                                <Card.Header>
                                    Script de calcul des télémétries
                                </Card.Header>
                                <Card.Body className='p-0'>
                                    <p className='mx-4 mt-2'>function(attributes) {'{'}</p>
                                    <AceEditor
                                        ref={this.evaluationScriptEditor}
                                        width='100%'
                                        mode="javascript"
                                        theme="github"
                                        defaultValue={this.state.editingObjectType?.evaluationScript}
                                        name="evaluationScriptEditor"
                                        editorProps={{ $blockScrolling: true }}
                                        highlightActiveLine={true}
                                        setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                            showLineNumbers: true,
                                            tabSize: 2
                                        }}
                                    />
                                    <p className='mx-4'>{'}'}</p>
                                </Card.Body>
                            </Card>
                            <br />
                            <Card>
                                <Card.Header>
                                    Paramètres
                                </Card.Header>
                                <Card.Body>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Nom du paramètre</th>
                                                <th>Nom de la variable</th>
                                                <th>Type de valeur</th>
                                                <th>Seulement admin</th>
                                                <th>Partagé avec les appareils</th>
                                                <th>Requis</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.state.editingObjectType?.settings.map((value, index) => 
                                            <ObjectTypeSettingEditor 
                                                key={index} 
                                                setting={value} 
                                                index={index} 
                                                onChange={e => this.handleSettingChange(e)} 
                                                onRemove={index => this.handleSettingRemove(index)}
                                            />
                                            ) }
                                        </tbody>
                                    </Table>
                                    
                                    <div className="row d-flex justify-content-end">
                                        <div className="pr-4">
                                            <Button type="button" color="primary" onClick={() => this.handleSettingAdd()}><FaPlus /></Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <br />
                            <Card>
                                <Card.Header>
                                    Script de calcul des alertes
                                </Card.Header>
                                <Card.Body className='p-0'>
                                    <p className='mx-4 mt-2'>function(attributes) {'{'} //attributes.oldShadow, attributes.newShadow, attributes.settings, attributes.alertSettings</p>
                                    <AceEditor
                                        ref={this.evaluationAlertScriptEditor}
                                        width='100%'
                                        mode="javascript"
                                        theme="github"
                                        defaultValue={this.state.editingObjectType?.alertEvaluationScript}
                                        name="evaluationAlertScriptEditor"
                                        editorProps={{ $blockScrolling: true }}
                                        highlightActiveLine={true}
                                        setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                            showLineNumbers: true,
                                            tabSize: 2
                                        }}
                                    />
                                    <p className='mx-4'>{'}'}</p>
                                </Card.Body>
                            </Card>
                            <br />
                            <Card>
                                <Card.Header>
                                    Paramètres des alertes
                                </Card.Header>
                                <Card.Body>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Nom du paramètre</th>
                                                <th>Nom de la variable</th>
                                                <th>Type de valeur</th>
                                                <th>Seulement admin</th>
                                                <th>Requis</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.state.editingObjectType?.alertSettings.map((value, index) => 
                                            <ObjectTypeAlertSettingEditor 
                                                key={index} 
                                                setting={value} 
                                                index={index} 
                                                onChange={e => this.handleAlertSettingChange(e)} 
                                                onRemove={index => this.handleAlertSettingRemove(index)}
                                            />
                                            ) }
                                        </tbody>
                                    </Table>
                                    
                                    <div className="row d-flex justify-content-end">
                                        <div className="pr-4">
                                            <Button type="button" color="primary" onClick={() => this.handleAlertSettingAdd()}><FaPlus /></Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card> 
                                {this.state.editingError && <div className="alert alert-danger mt-2" role="alert">
                                    {this.state.editingError}
                                </div>}
                            </>}
                        </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setState({...this.state, isModalOpen: false})}>Annuler</Button>                        
                                    {this.state.editingObjectType?.id !== '' && <><Button variant="danger" type="button" onClick={() => this.handleDelete()}>Supprimer</Button>{' '}</>}
                            <Button variant="primary" type="submit">Valider</Button>{' '}
                        </Modal.Footer>
                    </Form>
                </Modal>
            { this.renderContent() }
        </>
    }

    renderContent() {
        if (this.state.error) {
            return <p>{this.state.error}</p>
        }
        if (this.state.isLoading) {
            return <Loader />
        }
        if (this.state.objectTypes && this.state.objectTypes.length > 0) {
            return <div className="row row-cols-3">
                {
                    this.state.objectTypes.map(_ => 
                        <div className="col p-2" key={_.id}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>
                                        { _.name }
                                        <Button size='sm' variant='outline-secondary' className="float-right" onClick={ () => this.edit(_) }><FaEdit /></Button>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body style={{minHeight: 130}}>
                                    {_.description}
                                </Card.Body>
                            </Card>
                        </div>
                    )
                }
            </div>
        }
        else {
            return <Message title="Aucun élément" message="Ajouter des éléments avec le bouton plus" />
        }
    }
}