import * as React from 'react';
import TelemetryApi from '../../../apis/TelemetryApi';
import Query from '../../../models/dto/Query';
import ObjectCardContent, { IObjectCardContentProps, IObjectCardContentState } from '../ObjectCardContent'
import Chart from 'react-google-charts';
import Loader from '../../common/Loader';
import { ObjectDetails } from '../../../models/dto/Object';

export interface IPresenceCounterProps extends IObjectCardContentProps {

}

export interface IPresenceCounterState extends IObjectCardContentState {
    telemetries?: {
        data: any[]
        sessionCount: number
    }
}

export default class PresenceCounter extends ObjectCardContent<IPresenceCounterProps, IPresenceCounterState> {
    static objectTypeId = 'b1a0923f-7cda-4fd8-a3d7-d74dba649bcb'

    getInitialState(): IPresenceCounterState {
        return {
            loading: true, 
            updating: true
        }
    }

    async updateState(_: ObjectDetails): Promise<IPresenceCounterState> {
        var rows = await TelemetryApi.getInstance().query(this.props.organization, new Query().withRange(this.props.range.toQueryRange()).withFilters([
            `r["object"] == "${this.props.object.objectId}"`, 
            `r["_measurement"] == "duration"`, 
            `r["_field"] == "value"`
        ]).withKeepColumns(['_time', '_value']).withYieldName('duration').withAggregateWindow('sum', this.props.range.interval.duration, true))
        // const data = rows.map(row => {
        //     return {
        //         time: this.props.range.interval.formatFn(moment(Date.parse(row._time))), 
        //         value: Number.parseInt(row._value === '' ? 0 : row._value) / 60
        //     }
        // }).sort((a: any, b: any) => a.time - b.time)

        const data = rows.map(row => {
            return [
                new Date(row._time), 
                Number.parseInt(row._value === '' ? 0 : row._value) / 60
            ]
        }).sort((a: any, b: any) => a.time - b.time)

        rows = await TelemetryApi.getInstance().query(this.props.organization, new Query().withRange(this.props.range.toQueryRange()).withFilters([
            `r["object"] == "${this.props.object.objectId}"`, 
            `r["_measurement"] == "duration"`, 
            `r["_field"] == "value"`
        ]).withKeepColumns(['_time', '_value']).withYieldName('duration').withAggregateWindow('count', this.props.range.interval.duration, false))
        const sessionCount = rows.reduce((a, v) => a + Number.parseInt(v._value), 0)

        return {
            ...this.state,
            telemetries: {
                data: data,
                sessionCount: sessionCount
            }
        }
    }

    private renderState() {
        const totalDuration = this.state.telemetries!.data.reduce((a, v) => a + (isNaN(v[1]) ? 0.0 : v[1]), 0.0)
        const minPerSess = totalDuration / this.state.telemetries!.sessionCount
        return <div className='row row-cols-2'>
            <div className='text-center'>
                <div className='text-right' style={{ display: 'inline-block' }}>
                    <h1 className='mb-0'>{this.state.telemetries!.sessionCount}</h1>
                    <div className='mt-n2'><small>sessions</small></div>
                </div>
            </div>
            <div className='text-center'>
                <div className='text-right' style={{ display: 'inline-block' }}>
                    <h1 className='mb-0'>{minPerSess.toFixed(2)}</h1>
                    <div className='mt-n2'><small>min/session</small></div>
                </div>
            </div>
        </div>
    }

    private renderChart() {
        if (this.state.telemetries!.data.length === 0) {
            return <div className='d-flex h-100 align-items-center justify-content-center'>Aucune donnée pour cette période</div>
        }
        return <div className='mt-n3'>
            <Chart 
              chartLanguage='fr'
              chartType="ColumnChart"
              height={190}
              width='100%'
              data={[
                [{ type: 'datetime' },
                'Temps'], 
                ...this.state.telemetries!.data
              ]}
              options={{
                colors:['#8884d8', '#82ca9d'],
                legend: { position: 'none'}, 
                chartArea: { width: '85%', height: '75%'},
                hAxis: {
                  minValue: this.props.range.start.toDate(), 
                  maxValue: this.props.range.stop?.toDate() ?? new Date()
                }
              }}
              rootProps={{object: this.props.object.objectId}}
            />
        </div>
    }

    render() {
        return super.render() ?? this.renderContent()
    }

    renderContent() {
        if ((this.state.telemetries?.data.length || 0) === 0) {
            return <div className='d-flex h-100 align-items-center justify-content-center'>Aucune donnée pour cette période</div>
        }
        return <>
            <div style={{height: '40%'}}>
                {this.state.telemetries && this.renderState()}
            </div>
            <hr />
            <div>
                {this.state.updating ? <Loader color="dark" /> : this.state.telemetries && this.renderChart()}
            </div>
        </>
    }
}
