import { FloorDetails, FloorNew } from '../models/dto/Floor'
import ApiBase from './ApiBase';

export default class FloorApi extends ApiBase {
    private static instance: FloorApi;

    private constructor() {
        super()
    }
  
    static getInstance(): FloorApi {
      if (!FloorApi.instance) {
        FloorApi.instance = new FloorApi()
      }
  
      return FloorApi.instance
    }

    public async fetch(organizationId: string, installationId: string): Promise<FloorDetails[]> {
        return await this.getItems(`/api/customer/${organizationId}/installations/${installationId}/floors`, FloorDetails)
    }

    public async get(organizationId: string, installationId: string, floorId: number): Promise<FloorDetails> {
        return await this.getItem(`/api/customer/${organizationId}/installations/${installationId}/floors/${floorId}`, FloorDetails)
    }

    public async create(organizationId: string, installationId: string, floor: FloorNew): Promise<FloorDetails> {
        let item = await this.postItem(`/api/customer/${organizationId}/installations/${installationId}/floors`, floor, FloorNew, FloorDetails)
        return item!
    }

    public async edit(organizationId: string, installationId: string, floor: FloorDetails): Promise<FloorDetails> {
        let item = await this.putItem(`/api/customer/${organizationId}/installations/${installationId}/floors/${floor.id}`, floor, FloorNew, FloorDetails)
        return item!
    }

    public async delete(organizationId: string, installationId: string, floorId: string): Promise<void> {
        await this.deleteItem(`/api/customer/${organizationId}/installations/${installationId}/floors/${floorId}`)
    }
}